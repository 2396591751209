/* 
    Converts a value to a bool 
    0, false, 'F', 'false' return false
    1, True, 'F', 'True' return true
    Everything else return undefined (or null if null passed in)
    Conversion is not case sensitive, and will trim any string
*/
export function parseBool(val: any): boolean | undefined | null {
	if (val === null || val === undefined) {
    	return val;
    }
    
    var valStr = val.toString().trim().toLowerCase();
    if (valStr === '0' || valStr === 'false' || valStr === 'f') {
    	return false;
    } else if (valStr === '1' || valStr === 'true' || valStr === 't') {
    	return true;    
    }
    
    return undefined
};