import { HeaderButton } from "../HeaderButton/headerButton";
import { ReactComponent as List } from '../../../assets/icons/list.svg';
import { forwardRef } from "react";

interface Props {
    tab?: boolean;
    onClick: () => void;
}
export const ActivityLog = forwardRef((props: Props, ref: any) => {
    return <div ref={ref} className="activity-log">
        <HeaderButton text="Activity Log" handler={props.onClick} icon={<List />} active={false} tab={props.tab} />
    </div>
});
