import clsx from 'clsx';
import { ReactComponent as Spinner } from '../../assets/icons/spinner.svg';

const FullPageLoadingSpinner = ({ forLogin = false, loading = false }: { forLogin?: boolean; loading?: boolean }) => {
   return (
      <>
         {loading && (
            <>
               <div className={clsx('full-page-loader', { blue: forLogin }, { white: !forLogin })} />
               <div className={clsx('spinner-section', { blue: forLogin }, { white: !forLogin })}>
                  <div className={clsx('spinner-content', { blue: forLogin }, { white: !forLogin })}>
                     <span className="blue">
                        <Spinner className="spin" width={48} height={49} />
                     </span>
                     {forLogin && (
                        <>
                           <span>We're logging you in...</span>
                           <span style={{ fontSize: '15px', fontWeight: 400 }}>Please wait</span>
                        </>
                     )}
                  </div>
               </div>
            </>
         )}
      </>
   );
};

export default FullPageLoadingSpinner;
