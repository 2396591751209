import { makeObservable, observable, action } from "mobx"
import moment from "moment";
import { parseBool } from "../utils/boolUtils";
import { complexDateUtil } from "../utils/complexDateUtil";
import { ConvertTimeSpan } from "../utils/convertTimeSpan";

export class SettingStore {
    settings: { [name: string]: string } = {}    

    constructor() {
        makeObservable(this, {
            settings: observable,
            updateFromServer: action,
        })
    }

    getSetting(name: string) : string | null {
        var setting = this.settings[name];
        if (!setting) {
            console.error("Invalid setting " + name);
        }

        return setting;        
    }

    getSettingAsMinsFromTimeStamp(name: string) : number | null {
        var setting = this.getSetting(name);
        if (!setting) {
            return null;
        }

        return ConvertTimeSpan.toMinutes(setting) || null;        
    }

    getSettingAsDate(name: string) : moment.Moment | null {
        var setting = this.getSetting(name);
        if (!setting) {
            return null;
        }

        return complexDateUtil.convertStringToDate(setting);
    }

    getSettingAsBoolean(name: string) : boolean | null {
        var setting = this.getSetting(name);
        if (!setting) {
            return null;
        }

        return parseBool(setting) || null;
    }

    updateFromServer(settings: { [name: string]: string }) {
        this.settings = settings;
    }
}