import { action, computed, makeObservable, observable } from "mobx";
import { StoreLoadingStateTypes } from "../enums/StoreLoadingStateTypes";
import { ChangeField } from "../types/ChangeField";
import { UpdatedFieldsHelper } from "../utils/updatedFieldsHelper";
import { ReferralStatus } from "../enums/ReferralStatus";
import { fieldNames } from "../constants/fieldNameConstants";
import moment, { Moment } from "moment";

export class ReferralStore {
    referralId: string | undefined;
    currentTxpChangeListId: string | undefined;
    firstViewedTxpChangeListId: string | undefined;
    latestChangeListId: string | undefined;
    state: StoreLoadingStateTypes = StoreLoadingStateTypes.Empty;
    fields: { [name: string]: ChangeField } = {}
    changeListIds: string[] = []    // List of changelists in descending time order

    constructor() {
        makeObservable(this, {
            referralId: observable,
            state: observable,
            fields: observable,
            latestChangeListId: observable,
            loading: action,
            clear: action,
            updateFromServer: action,
            updatedFieldCount: computed,
            updatedFieldNames: computed
        });        
    }

    public loading(referralId: string) {
        this.referralId                 = referralId;
        this.currentTxpChangeListId     = undefined;
        this.firstViewedTxpChangeListId = undefined;
        this.state                      = StoreLoadingStateTypes.Loading;
        this.fields                     = {};
    }

    public clear() {
        this.referralId                 = undefined;
        this.currentTxpChangeListId     = undefined;
        this.firstViewedTxpChangeListId = undefined;
        this.state                      = StoreLoadingStateTypes.Empty;
        this.fields                     = {};
    }

    public getField(fieldName: string, createIfEmpty: boolean = false) : ChangeField | undefined {
        return this.fields[fieldName] || (createIfEmpty ? {} : undefined)
    }

    getReferralStatus() : ReferralStatus | undefined {
        var referralStatus = this.getField(fieldNames.REFERRALSTATUS_STATUS)?.toValue;
        return referralStatus ? referralStatus as ReferralStatus : undefined;
     }
 
    public getFieldCollection(partFieldName: string ) : ChangeField[] {
        if (!partFieldName.endsWith('|')) {
            partFieldName += '|';
        }

        return Object.values(this.fields).filter(f => f.fieldName.startsWith(partFieldName));
    }

    public get updatedFieldCount(): number {
        return UpdatedFieldsHelper.count(Object.values(this.fields));
    }

    public get updatedFieldNames(): string[] {
        return Object.values(this.fields).filter(f => f.updated).map(f => f.fieldName);
    }

    public clearUpdatedFields(newCurrentTxpChangeListId: string): void {
        this.state = StoreLoadingStateTypes.Loading;
        this.currentTxpChangeListId = newCurrentTxpChangeListId;
        Object.values(this.fields).forEach(f => f.updated = false);
        this.state = StoreLoadingStateTypes.Ready;
    }

    public getChangeListIdFromFirstView(includeFirstViewed: boolean): string[] {
        var index = this.changeListIds.findIndex(c => c === this.firstViewedTxpChangeListId);
        if (includeFirstViewed) {
            index++;
        }
        return index === -1 ? this.changeListIds : this.changeListIds.slice(0, index);
    }

    public getChangeListIdFromCurrentTxpChangeListId(): string[] {
        var index = this.changeListIds.findIndex(c => c === this.currentTxpChangeListId);
        return index === -1 ? this.changeListIds : this.changeListIds.slice(0, index);
    }

    public createdDateSameOrAfter(date: Moment): boolean {
        var createdDate = referralStore.getField(fieldNames.REFERRAL_CREATEDDATE);
        return !!createdDate && !!createdDate.toValue && moment(createdDate.toValue) >= date;
    }

    public updateFromServer(referralId: string, lastViewedTxpChangeListId: string, firstViewedTxpChangeListId: string, fields: ChangeField[], changeListsIds: string[] | undefined): void {        
        this.referralId = referralId;

        // Only update if not already set
        this.currentTxpChangeListId     ||= lastViewedTxpChangeListId;        
        this.firstViewedTxpChangeListId ||= firstViewedTxpChangeListId;
        this.changeListIds              = changeListsIds || [];
        this.latestChangeListId         = this.changeListIds.length > 0 ? this.changeListIds[0] : undefined;
        
        UpdatedFieldsHelper.updateFlag(fields, this.currentTxpChangeListId, changeListsIds || []);
        fields.forEach(f => this.fields[f.fieldName] = f);

        this.state = StoreLoadingStateTypes.Ready;
    }
}

const referralStore = new ReferralStore();
export default referralStore;
