import moment, { Moment } from "moment";
import { ControlTypes } from "../enums/ControlTypes";
import referenceDataStore from "../stores/ReferenceDataStore";
import { parseBool } from "../utils/boolUtils";
import { complexDateUtil } from "../utils/complexDateUtil";
import { ActivityLogAction } from "../enums/ActivityLogAction";

export interface ChangeFieldHistory {
    action: ActivityLogAction;
    timestamp?: string;
    changeListId: string;
}

export interface ChangeField {    
    fieldName: string;
    toValue: string | null;
    timestamp?: string;
    ifChanged: boolean;
    history: ChangeFieldHistory[];
    disabled?: boolean;
    updated: boolean;
    changeListId: string;
}

export class ChangeFieldHelper {
    private static controlTypeToClientValue: { [controlType: string]: { (value: string | null): number | moment.Moment | boolean | null } } =  
    {
        [ControlTypes.CheckBox]        : (value: string | null) => parseBool(value) || false,
        [ControlTypes.Date]            : (value: string | null) => complexDateUtil.convertStringToDate(value),
        [ControlTypes.DateTime]        : (value: string | null) => complexDateUtil.convertStringToDate(value),
        [ControlTypes.PartDateTime]    : (value: string | null) => complexDateUtil.convertStringToDate(value),
        [ControlTypes.SignedNumeric]   : (value: string | null) => (value ? parseFloat(value) : null),
        [ControlTypes.UnsignedNumeric] : (value: string | null) => (value ? parseFloat(value) : null),
    };

    public static getDisplayValue(fieldName: string, toValue: string | null): string | null {
        var controlType = referenceDataStore.getFieldInfo(fieldName)?.controlType;
        switch (controlType) {
            case ControlTypes.CheckBox: return ChangeFieldHelper.getClientValue(fieldName, toValue) ? "Yes" : "No";
            case ControlTypes.Date:
                var val = this.getClientValue(fieldName, toValue) as Moment;
                if (val === null) {
                    return null;
                }
                
                return val.format("DD/MM/YYYY");
            case ControlTypes.PartDateTime:
            case ControlTypes.DateTime:
                var val = this.getClientValue(fieldName, toValue) as Moment;
                if (val === null) {
                    return null;
                }
                
                return val.format("DD/MM/YYYY HH:mm");    
            case ControlTypes.DropDown:
                return referenceDataStore.getCodeSetText(fieldName, toValue) || toValue;
            case ControlTypes.Switch:
                return referenceDataStore.getCodeSetText(fieldName, toValue) || toValue;
        }

        return toValue;
    }
    
    public static getClientValue(fieldName: string, toValue: string | null): string | number | moment.Moment | boolean | null {
        var controlType = referenceDataStore.fields.getFieldInfo(fieldName)?.controlType;
        if (!controlType) {
            return null;
        }
        const handler = ChangeFieldHelper.controlTypeToClientValue[controlType];
        return handler ? handler(toValue) : toValue;
    }
}