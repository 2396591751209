import { observer } from 'mobx-react-lite';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Login } from './components/Login/Login';
import { Referral } from './pages/Referral/Referral';
import { AttachmentProvider } from './context/AttachmentContext';
import CookiePrivacy from './pages/CookiePrivacy/CookiePrivacy';
import { SearchPage } from './pages/Search/SearchPage';
import { useEffect } from 'react';
import userStore from './stores/UserStore';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import cookieStore from './stores/CookieStore';
import { AccessibilityStatement } from './pages/AccessibilityStatement/AccessibilityStatement';
import { TermsConditions } from './pages/TermsConditions/TermsConditions';

var appInsights: ApplicationInsights | undefined = undefined;
var reactPlugin: ReactPlugin | undefined = undefined;

export const Router = observer(() => {
   useEffect(() => {
      cookieStore.onCookieStoreChange.subscribe(updateAppInsights);
      return () => {
         cookieStore.onCookieStoreChange.unsubscribe(updateAppInsights);
      }
   }, []);

   useEffect(() => {
      updateAppInsights();
   }, [userStore.isLoggedIn]);

   const updateAppInsights = () => {
      // Setup applicationInsights logging
      if (userStore.isLoggedIn && (cookieStore.allowNoneEssentialCookies ?? false)) {
         reactPlugin = new ReactPlugin();
         appInsights = new ApplicationInsights({
            config: {
                  connectionString: process.env.REACT_APP_APP_INSIGHTS_CONNECTIONSTRING,
                  enableAutoRouteTracking: true,
                  disableCookiesUsage: false,
                  disableTelemetry: false,
                  extensions: [reactPlugin],
            }
         });
         appInsights.loadAppInsights();
         appInsights.setAuthenticatedUserContext(userStore.userData.id!.toString(), undefined, true);
      } else if (appInsights) {
         appInsights.config.enableAutoRouteTracking = false;
         appInsights.config.disableCookiesUsage = true;
         appInsights.config.disableTelemetry = true;
         if (reactPlugin) {
            reactPlugin?.teardown();
            reactPlugin = undefined;
         }
         appInsights.clearAuthenticatedUserContext();
         appInsights.stopTrackPage();
         appInsights.unload(false);
         appInsights = undefined;
      }
   }

   return (
      <>
         <AttachmentProvider>
            <BrowserRouter>
               <Routes>
                  <Route path="/" element={<Login />}>
                     <Route path="login" element={<Login />} />
                  </Route>
                  <Route path="/privacy-cookie" element={<CookiePrivacy />} />
                  <Route path="/accessibility" element={<AccessibilityStatement />} />
                  <Route path="/terms-conditions" element={<TermsConditions />} />
                  <Route path="/search" element={<SearchPage />} />
                  <Route path="/Referral/:referralId" element={<Referral />} />
               </Routes>
            </BrowserRouter>
         </AttachmentProvider>
      </>
   );
});
