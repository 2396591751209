import { baseApi } from "./baseApi";
import { UserModel } from "./Models/UserModel";

class LoginApi extends baseApi {
    public login(allowNoneEssentialCookies: boolean): Promise<UserModel> {
        var url = `${process.env.REACT_APP_TRANSPLANTPATH_API_BASEURL}/api/Account/Login?AllowNoneEssentialCookies=${allowNoneEssentialCookies}`;
        return super.get<UserModel>(url);
    }
}

const loginApi = new LoginApi();
export default loginApi;
