import clsx from "clsx";
import { AttachmentLoadState } from "../../enums/AttachmentLoadState";
import { Attachment, AttachmentFileDetails, AttachmentHelper } from "../../types/Attachment";
import { Pill, PillType } from "./Pill";
import { useEffect, useState } from "react";
import { complexDateUtil } from "../../utils/complexDateUtil";
import Spinner from '../../assets/icons/spinner-white.png';
import { ReactComponent as FileDownloadIcon } from '../../assets/icons/fileDownload.svg';
import referenceDataStore from "../../stores/ReferenceDataStore";
import { fieldNames } from "../../constants/fieldNameConstants";
import attachmentFileStore from "../../stores/AttachmentFileStore";
import attachmentUrlApi from "../../api/attachmentUrlApi";
import referralStore from "../../stores/ReferralStore";
import { ImageViewer } from "../AttachmentViewer/Viewers/ImageViewer";
import { RefNumber } from "./RefNumber";
import { AttachmentErrorType } from "../../enums/AttachmentErrorType";
import { VideoViewer } from "../AttachmentViewer/Viewers/VideoViewer";
import { DocumentImageViewer } from "../AttachmentViewer/DocumentImageViewer";
import { valueFormatUtils } from "../../utils/valueFormatUtils";
import fieldValues from "../../constants/referralFieldValueConstants";
import { parseBool } from "../../utils/boolUtils";
import { OrganDamagePill } from "./OrganDamagePill";

interface Props {
    fieldName: string;
    attachment: Attachment;
}

export const AttachmentMediaControl = (props: Props) => {
    const [, setForceUpdate ] = useState(0);
    const [allLoaded, setAllLoaded ] = useState(false);
    const [anyDownloading, setAnyDownloading ] = useState(false);
    const [viewAttachment, setViewAttachment ] = useState<string | undefined>(undefined);

    const datetime        = complexDateUtil.convertStringToDate(props.attachment.timestamp);
    const title           = referenceDataStore.getCodeSetText(fieldNames.RESOURCEMETADATA_ORGANCATEGORY, props.attachment.organCategory);
    const resourceBatchId = props.fieldName.split('|')[1];
    const fileIds         = AttachmentHelper.getFileDetails(props.attachment).map(f => f.fileId!);
    const showImages      = attachmentFileStore.ifShowImages(resourceBatchId);
    const hasDamage       = parseBool(props.attachment.hasDamage);

    useEffect(() => {
        updateAllLoaded();
        attachmentFileStore.onChange.subscribe(handleAttachmentStoreChange);
        return () => {
            attachmentFileStore.onChange.unsubscribe(handleAttachmentStoreChange);
        }
    }, []);

    const renderIcon = (state: AttachmentLoadState, fileId: string) => {
        switch (state) {
        case AttachmentLoadState.None:
        case AttachmentLoadState.Error: 
            return <FileDownloadIcon />
        case AttachmentLoadState.Loading: 
            return <img src={Spinner} className="spin" alt="spinner" />;
        case AttachmentLoadState.Loaded: 
            if (props.attachment.contentType && props.attachment.contentType?.startsWith('image/')) {
                return <ImageViewer resourceBatchId={resourceBatchId} fileId={fileId} />
            } else if (props.attachment.contentType && (props.attachment.contentType?.startsWith('video/') || props.attachment.contentType?.endsWith('mpeg-4'))) {
                return <VideoViewer resourceBatchId={resourceBatchId} fileId={fileId} />
            }
        }
    }

    const download = async (fileIds: string[]) => {
        fileIds.forEach(id => attachmentFileStore.setState(resourceBatchId, id, AttachmentLoadState.Loading));

        try {
            const urls = await attachmentUrlApi.getUrls(referralStore.referralId!, resourceBatchId, fileIds); 

            fileIds.forEach(async (id) => {            
                    var urlInfo = urls?.find(u => u.resourceId === id);
                    await attachmentFileStore.download(resourceBatchId, urlInfo!.resourceId, urlInfo!.url, urlInfo!.filename, urlInfo!.paramsAsRequestHeaders);
            })
        } catch (error: any) {
            fileIds.forEach(id => attachmentFileStore.setState(resourceBatchId, id, AttachmentLoadState.Error, AttachmentErrorType.DownloadFailed));
            console.error('Error:', error.message);
        }    
    }

    const handleOnClick = async (fileId: string) => {
        if (attachmentFileStore.getFileInfo(resourceBatchId, fileId).state === AttachmentLoadState.Loaded) {
            if (props.attachment.type !== fieldValues.ATTACHMENT.TYPE.VIDEO) {
                setViewAttachment(fileId);
            }
        } else {
            await download([ fileId ]);
        }
    }

    const updateAllLoaded = () => {
        var allLoaded = fileIds.map(id => attachmentFileStore.getState(resourceBatchId, id)).every(state => state === AttachmentLoadState.Loaded);
        setAllLoaded(allLoaded);        
    }

    const handleAttachmentStoreChange = (sender: any, id: string | undefined) => {
        if (!id || resourceBatchId === id) {
            updateAllLoaded();
            setAnyDownloading(fileIds.some(id => attachmentFileStore.getState(resourceBatchId, id) === AttachmentLoadState.Loading));
            setForceUpdate(prev => prev + 1);
        }
    }

    const handleOnViewBatch = async () => {
        var notLoadedFileIds = fileIds.filter(id => attachmentFileStore.getState(resourceBatchId, id) !== AttachmentLoadState.Loaded);
        if (notLoadedFileIds.length > 0) {
            await download(notLoadedFileIds);
        }
    }

    const handleOnShowBatch = () => {
        attachmentFileStore.setShowBatchImages(resourceBatchId);
    }

    const renderSize = (state: AttachmentLoadState, fileSizeKb: number | undefined) => {
        if (state === AttachmentLoadState.None || state === AttachmentLoadState.Error) {
            return <div className="size">{valueFormatUtils.formatSize(fileSizeKb)}</div>;
        } else {
            return <></>;
        }  
    }    

    const renderFrame = (fileDetails: AttachmentFileDetails) => {
        var state = attachmentFileStore.getState(resourceBatchId, fileDetails.fileId!);
        var error = attachmentFileStore.getError(resourceBatchId, fileDetails.fileId!);

        return <button key={`frame-${fileDetails.fileId}`} className="file" onClick={() => handleOnClick(fileDetails.fileId!)}>
                    <div key={`frame-button-${fileDetails.fileId}`} className={clsx("frame", AttachmentLoadState[state].toLowerCase())}> 
                        <div className="error-msg">{attachmentFileStore.getErrorMessage(error, props.attachment.type)}</div>
                        <span className="icon">{renderIcon(state, fileDetails.fileId!)}</span>
                        {renderSize(state, fileDetails.fileSizeKb)}
                    </div>
                    <div className="pills">
                        <RefNumber fileId={fileDetails.fileId!} />
                        <Pill code={props.attachment.organCategory} type={PillType.Organ} />
                        <Pill code={props.attachment.organLocation} type={PillType.OrganLocation} />
                    </div>
               </button>
    }
        
    return <>
        <div id={props.fieldName} className='attachment attachment-media'>
            <div className="title-details">
                <span className="title">{title}</span>
                {(!allLoaded && showImages) && <button type="button" disabled={anyDownloading} onClick={() => handleOnViewBatch()}>View media batch</button>}
                {showImages || <button type="button" onClick={() => handleOnShowBatch()}>Show media batch</button>}
            </div>
            {showImages && <div key={`media-files-${resourceBatchId}`} className="files">
                {AttachmentHelper.getFileDetails(props.attachment).map(renderFrame)}
            </div>}
            <div className="pills" key={`pills-${resourceBatchId}`}>
                    {!showImages && <Pill type={PillType.Organ} code={props.attachment.organCategory} />}
                    {!showImages && <Pill type={PillType.OrganLocation} code={props.attachment.organLocation} />}
                    {hasDamage && <OrganDamagePill />}
            </div>
            <div className="details">
                <div className="datetime">
                    <span className="date">{datetime && datetime.format("DD/MM/YYYY")}</span>
                    <span className="time">{datetime && datetime.format("HH:mm")}</span>
                </div>
                <span className="comments">{props.attachment.description}</span>
            </div>
            {viewAttachment && <DocumentImageViewer resourceBatchId={resourceBatchId} 
                                                    onClose={() => setViewAttachment(undefined)}
                                                    firstSelectedFieldId={viewAttachment} />}
        </div>
        <hr />
    </>
}