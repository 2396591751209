import { useContext, useState, useEffect } from "react";
import attachmentFileStore from "../../../stores/AttachmentFileStore";
import { Document, Page, pdfjs } from 'react-pdf';
import { BreakpointContext } from "../../../context/BreakpointContext";
import '../../../assets/react-pdf/styles/AnnotationLayer.css';
import '../../../assets/react-pdf/styles/TextLayer.css';

interface Props {
    resourceBatchId: string,
    fileId?: string,
}

/**
 * Uses 3rd Party library react-pdf to render the pdf pages.
 * Can't use standard embedded option to do this as has a right click Save As option
 */
export const PdfViewer = (props: Props) => {
    const isMobile = useContext(BreakpointContext);
    const [numPages, setNumPages] = useState<number>(0);
    const [cachedData, setCachedData] = useState<ArrayBuffer>();
    
    useEffect(() => {
        // Load the pdf worker script
        var workerPath = (process.env.PUBLIC_URL || process.env.REACT_APP_ADREDIRECTCREDENTIALS) + '/scripts/pdf.worker.min.js';
        pdfjs.GlobalWorkerOptions.workerSrc = new URL(workerPath, import.meta.url,).toString();

        // Copy the data to cachedBuffer to stop error
        //     Cannot perform Construct on a detached ArrayBuffer 
        // on 2nd load
        const data = attachmentFileStore.getFileInfo(props.resourceBatchId, props.fileId)?.data;
        const cachedData = new ArrayBuffer(data!.byteLength);
        const destinationView = new Uint8Array(cachedData);      
        destinationView.set(new Uint8Array(data!));
        setCachedData(cachedData);
    }, []);


    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    }   
    
    if (cachedData) {
        return <Document file={cachedData} 
                        onLoadSuccess={onDocumentLoadSuccess}
                        className='pdf-viewer-container'>
                    {Array.from(new Array(numPages), (_, index) => (<Page key={index + 1} scale={isMobile ? 0.7 : 1.5} pageNumber={index + 1}  />))}
                </Document>;
    } else {
        return <></>;
    }
}