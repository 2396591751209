import { Link } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from '../../../assets/icons/chevron-left.svg';

interface Props {
    previousLinkRoute: string;
    previousLinkText: string;
    pageTitle?: string;
}

export const Breadcrumb = ({ previousLinkRoute, previousLinkText, pageTitle }: Props) => {
    return <nav className='breadcrumb'>
        <ChevronLeftIcon />
        <span className="title-span">
            <Link to={previousLinkRoute} >{previousLinkText}</ Link>
            {pageTitle && <span> / {pageTitle}</span>}
        </span>
    </nav >
}