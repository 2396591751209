import { SignalDispatcher } from "strongly-typed-events";

export class ClinicallySignificantStore {
    allHiddenFields: Set<string> = new Set(); 
    expandedFields: Set<string> = new Set();
    clinicallySignificantOnly: boolean = false;

    private _onChange = new SignalDispatcher();    
        
    public get onChange() {
        return this._onChange.asEvent();
     }

    showFields(fieldNames: string[]): void {
        fieldNames.filter(s => s && s !== '')
                  .forEach(s => this.expandedFields.add(s));

        if (this.clinicallySignificantOnly && this.allHiddenFields.size <= this.expandedFields.size) {
            this.setShowClinicallySignificantOnly(false);
        }

        this._onChange.dispatchAsync();
    }

    setShowClinicallySignificantOnly(clinicallySignificantOnly: boolean): void {
        this.clinicallySignificantOnly = clinicallySignificantOnly;
        this.expandedFields.clear();
        this.allHiddenFields.clear();
        this._onChange.dispatchAsync();
    }

    isFieldShown(fieldName: string): boolean {
        return !this.clinicallySignificantOnly || this.expandedFields.has(fieldName);
    }

    addToHiddenFields(fieldNames: string[]): void {
        fieldNames.filter(s => s && s !== '')
                  .forEach(s => this.allHiddenFields.add(s));        
    }
}

const clinicallySignificantStore = new ClinicallySignificantStore();
export default clinicallySignificantStore;
