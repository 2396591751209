import { ReactComponent as Dots } from '../../../assets/icons/three-dots-horizontal.svg';
import clinicallySignificantStore from '../../../stores/ClinicallySignificantStore';
import { PageItemModel } from '../../../stores/PageLayoutStore';

interface Props {
    pageItem: PageItemModel;
}

export const HiddenSection = ({ pageItem }: Props) => {
    const handleExpandSection = () => {
        if (pageItem.field) {
            clinicallySignificantStore.showFields(pageItem.field.split(','));
        }
    }
    return <button onClick={handleExpandSection} className="hidden-section"><Dots /></button>
}