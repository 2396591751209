import { useEffect } from 'react';
import './App.scss';
import { Router } from './Router';
import historyStore from './stores/HistoryStore';
import { BreakpointProvider } from './context/BreakpointContext';

function App() {
   const onBackButtonEvent = (event: PopStateEvent) => {
      historyStore.pop(window.location.pathname);
   };

   const onResize = () => {
      document.documentElement.style.setProperty('--display-height', `${window.innerHeight}px`)
   };

   useEffect(() => {
      window.addEventListener('popstate', onBackButtonEvent);
      window.addEventListener('resized', onResize);
      return () => {
         window.removeEventListener('popstate', onBackButtonEvent);
         window.removeEventListener('resized', onResize);
      }
   }, []);

   return (
      <div className="App">
         <BreakpointProvider>
            <Router />
         </BreakpointProvider>
      </div>
   );
}

export default App;
