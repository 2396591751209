interface Props {
    labelText: string;
    placeholder?: string;
    value: string;
    setValue: Function;
}

export const LabelledInput = ({ labelText, placeholder, value, setValue }: Props) => {
    return <div className="labelled-input">
        <label>{labelText}
            <input type="text" placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)}></input>
        </label>
    </div>
}
