import { ForwardedRef, forwardRef } from "react";
import { ActivityLogAction } from "../../enums/ActivityLogAction";
import { MenuItem } from "../Navigation/menuItems";
import clsx from "clsx";

export interface ActivityLogItemProps {
    timestamp: moment.Moment;
    actions: Set<ActivityLogAction>;
    pageOrderIndex: number;
    menuItem: MenuItem;
    subMenuItem: MenuItem | null;
    updated: boolean;
}

export const ActivityLogItem = forwardRef((props: ActivityLogItemProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return <button className={clsx('activity-log-item', { updated: props.updated })} ref={ref}>
                <div className='datetime'>
                    <span className='date'>{props.timestamp.format("DD/MM/YYYY")}</span>
                    <span className='time'>{props.timestamp.format("HH:mm")}</span>
                </div>
                <div className='details-section'>
                    <span className='operation'>{Array.from(props.actions).sort((a, b) => a - b)
                                                                          .map(o => o.toString())
                                                                          .join(', ')}:</span>
                    <span className='location'> {props.menuItem.title}</span>
                    {props.subMenuItem && <span> &gt; {props.subMenuItem.title}</span>}
                </div>
            </button>
});