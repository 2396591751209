import clsx from 'clsx';
import referralFieldValueConstants from '../../../constants/referralFieldValueConstants';
import { ControlTypes } from '../../../enums/ControlTypes';
import { FieldInformation } from '../../../stores/FieldInformationStore';
import { PageItemModel } from '../../../stores/PageLayoutStore';
import { ChangeField, ChangeFieldHelper } from '../../../types/ChangeField';
import { valueFormatUtils } from '../../../utils/valueFormatUtils';
import { DisclosurePanelClinicallySignificantUtils } from '../DisclosurePanel/DisclosurePanelClinicallySignificantUtils';
import { observer } from 'mobx-react-lite';

interface Props {
   key: string;
   fieldValue: ChangeField | undefined;
   field?: FieldInformation | undefined;
   pageItem: PageItemModel;
}

export const ReviewBlockControl = observer((props: Props) => {
   const getFieldTitle = () => {
      if (props.pageItem.text && props.pageItem.text !== '') {
         return props.pageItem.text;
      }

      if (!props.field || props.field.controlType !== ControlTypes.Switch) {
         return '';
      }

      return props.field.title;
   };

   const getFieldText = () => {
      if (!props.fieldValue) {
         return '';
      }

      if (props.fieldValue.disabled) {
         return '-';
      }

      var valueFormat = props.pageItem.valueFormatType;
      if (props.field && !valueFormat && props.field.controlType === ControlTypes.Switch) {
         switch (props.fieldValue.toValue) {
            case referralFieldValueConstants.YES:
               return 'Yes';
            case referralFieldValueConstants.NO:
               return 'No';
            case referralFieldValueConstants.UNKNOWN:
               return 'UNKNOWN';
            case referralFieldValueConstants.NA:
               return 'N/A';
         }
      }

      var text = ChangeFieldHelper.getDisplayValue(props.fieldValue.fieldName, props.fieldValue.toValue) || '';
      return valueFormatUtils.formatValue(props.fieldValue.fieldName, text, valueFormat);
   };

   if (!DisclosurePanelClinicallySignificantUtils.ifDisplayed(props.pageItem)) {
      return <></>;
   }

   const reviewBlockFieldClass = clsx({
      'review-block-field': true,
      switch: props.field && props.field.controlType === ControlTypes.Switch,
      text: props.field && (props.field.controlType === ControlTypes.Text || props.field.controlType === ControlTypes.TextArea),
      'not-clinically-significant':
         !props.fieldValue ||
         (props.fieldValue.toValue !== referralFieldValueConstants.YES && props.fieldValue.toValue !== referralFieldValueConstants.UNKNOWN)
   });

   const reviewFieldDataClass = clsx({
      'review-block-field-data': true,
      small:
         props.field &&
         props.field.controlType === ControlTypes.Switch &&
         props.fieldValue &&
         (props.fieldValue.toValue === referralFieldValueConstants.UNKNOWN || props.fieldValue.toValue === referralFieldValueConstants.NA)
   });

   return (
      <div className={clsx('review-block-field-wrapper', props.fieldValue?.updated && 'has-updates')}>
         <div id={props.field?.name} className={reviewBlockFieldClass}>
            <div className="review-block-field-title">
               {getFieldTitle()}
               {props.pageItem.extraText && <div className="review-block-field-extratext">{props.pageItem.extraText || ''}</div>}
            </div>
            <div className={reviewFieldDataClass}>{getFieldText()}</div>
         </div>
      </div>
   );
});
