import { fieldNames } from "../constants/fieldNameConstants";
import { ChangeField } from "../types/ChangeField";

export class UpdatedFieldsHelper {
    private static readonly FieldsCountedAsSingleUpdate = [
        [ 
            fieldNames.PLASMADILUTIONS     + "1", fieldNames.PLASMADILUTIONS     + "2", fieldNames.PLASMADILUTIONS     + "3", fieldNames.PLASMADILUTIONS     + "4",
            fieldNames.PLASMADILUTIONSCALC + "1", fieldNames.PLASMADILUTIONSCALC + "2", fieldNames.PLASMADILUTIONSCALC + "3", fieldNames.PLASMADILUTIONSCALC + "4",
            fieldNames.PATIENTASSESSMENT_CRYSTALLOIDTOTALCALC
        ],
        [ 
            fieldNames.PLASMADILUTIONS     + "5", fieldNames.PLASMADILUTIONS     + "6", fieldNames.PLASMADILUTIONS     + "7",
            fieldNames.PLASMADILUTIONSCALC + "5", fieldNames.PLASMADILUTIONSCALC + "6", fieldNames.PLASMADILUTIONSCALC + "7",
            fieldNames.PATIENTASSESSMENT_BLOODANDCOLLOIDTOTALCALC
        ],
        [ fieldNames.PATIENTDETAIL_WEIGHTKGACCURACYCALC ],
        [ fieldNames.PATIENTDETAIL_BLOODGROUP, fieldNames.PATIENTDETAIL_RHESUS, fieldNames.PATIENTDETAIL_BLOODGROUPRHESUSCALC ],
        [ 
          fieldNames.ABDOMINALRETRIEVALTEAMCALLOUTS + "1", fieldNames.ABDOMINALRETRIEVALTEAMCALLOUTS + "2", fieldNames.ABDOMINALRETRIEVALTEAMCALLOUTS + "3",
          fieldNames.ABDOMINALRETRIEVALTEAMCALLOUTS + "4", fieldNames.ABDOMINALRETRIEVALTEAMCALLOUTS + "5", fieldNames.ABDOMINALRETRIEVALTEAMCALLOUTS + "6",
          fieldNames.ABDOMINALRETRIEVALTEAMCALLOUTS + "7", fieldNames.ABDOMINALRETRIEVALTEAMCALLOUTS + "8", fieldNames.ABDOMINALRETRIEVALTEAMCALLOUTS + "9",
          fieldNames.ABDOMINALRETRIEVALTEAMCALLOUTS + "10",fieldNames.ABDOMINALRETRIEVALTEAMCALLOUTS + "11",fieldNames.ABDOMINALRETRIEVALTEAMCALLOUTSCSV
        ],
        [ 
          fieldNames.CARDIOTHORACICRETRIEVALTEAMCALLOUTS + "1", fieldNames.CARDIOTHORACICRETRIEVALTEAMCALLOUTS + "2", fieldNames.CARDIOTHORACICRETRIEVALTEAMCALLOUTS + "3",
          fieldNames.CARDIOTHORACICRETRIEVALTEAMCALLOUTS + "4", fieldNames.CARDIOTHORACICRETRIEVALTEAMCALLOUTS + "5", fieldNames.CARDIOTHORACICRETRIEVALTEAMCALLOUTS + "6",
          fieldNames.CARDIOTHORACICRETRIEVALTEAMCALLOUTS + "7", fieldNames.CARDIOTHORACICRETRIEVALTEAMCALLOUTS + "8", fieldNames.CARDIOTHORACICRETRIEVALTEAMCALLOUTS + "9",
          fieldNames.CARDIOTHORACICRETRIEVALTEAMCALLOUTS + "10",fieldNames.CARDIOTHORACICRETRIEVALTEAMCALLOUTS + "11",fieldNames.CARDIOTHORACICRETRIEVALTEAMCALLOUTSCSV
        ]
    ];

    private static readonly DuplicatedFields = [
        { fieldName: fieldNames.KIDNEYANATOMY_PERFUSATETYPE, count: 2 },
        { fieldName: fieldNames.KIDNEYANATOMY_OTHERABNORMALITIES, count: 2 },
        { fieldName: fieldNames.KIDNEYANATOMY_OTHERRETRIEVALDAMAGE, count: 2 },
    ];

    private static readonly UpdatesMappingToMultipleFields: { [key: string]: string[] } = {
        [ fieldNames.HLASAMPLEID ]: [fieldNames.HLADATA_LABNAME, fieldNames.HLADATA_COMMENT, fieldNames.HLARESULTS]
    };

    private static readonly FieldsNotPartOfUpdate = [
        fieldNames.HLASAMPLEID,
        fieldNames.RESOURCEMETADATAS,
        fieldNames.REFERRALSTATUS_STATUS,
        fieldNames.PATIENTDETAIL_DONORINSCOTLAND,
        fieldNames.RESOURCEREFNUMBERS,
    ];
        
    public static count(changeFields: ChangeField[]): number {
        // Crystalloid infused, and Blood/Colloid infused are 9, and 7 fields respectively but only count as 1 update each
        var updatedFields = changeFields.filter(f => f.updated).map(f => f.fieldName);
        var count = updatedFields.length;
        var additionalItemCount = 0;

        // Crystalloid infused, and Blood/Colloid infused are 9, and 7 fields respectively but only count as 1 update each
        // Also includes some calculated (can't use db setting Poller.FieldsExcludedFromUpdate as calculation won't register as an update)
        UpdatedFieldsHelper.FieldsCountedAsSingleUpdate.forEach(fieldList => {
            // remove all items, and then check if there was one or more in the list then add to the total.
            updatedFields = updatedFields.filter(fieldName => !fieldList.includes(fieldName));
            if (count !== updatedFields.length) {
                count = updatedFields.length;
                additionalItemCount++;
            }
        });

        // Kidney Anatomy has single fields shared between left and right kidney, 
        // this should count as 2 updates
        additionalItemCount += UpdatedFieldsHelper.DuplicatedFields
                                    .filter(field => updatedFields.includes(field.fieldName))
                                    .reduce((total, field) => total + field.count - 1, 0);

        return count + additionalItemCount;        
    }

    /**
     * Set the update flag on fields that have been updated since currentChangeListId
     * changeListIds should be in descending order
     */
    public static updateFlag(fields: ChangeField[], currentChangeListId: string, changeListIds: string[])
    {
        // Get list of new change lists ids (changeListIds is in descending order)
        var index = changeListIds.findIndex(c => c === currentChangeListId);
        if (index > 0) {
            var newChangeListIds = new Set(changeListIds.slice(0, index));
            fields.forEach(f => f.updated = newChangeListIds.has(f.changeListId)); 
        }

        var additionalFieldToUpdate   = new Set<string>();
        var fieldsToExcludeFromUpdate = new Set<string>(UpdatedFieldsHelper.FieldsNotPartOfUpdate.map(f => f.split('|', 2)[0]));

        // Get list of additions fields that need updated flag set
        var multiFieldUpdates = Object.keys(UpdatedFieldsHelper.UpdatesMappingToMultipleFields)
        for (let f of fields) {
            // Check for fields that expand to multiple fields
            if (f.updated && multiFieldUpdates.includes(f.fieldName)) {
                UpdatedFieldsHelper.UpdatesMappingToMultipleFields[f.fieldName].forEach(c => additionalFieldToUpdate.add(c));
            }
        }

        // Set updated flag for additional fields, and clear flag for items that should not be updated
        for (let fieldValue of fields) {
            let baseFieldName = fieldValue.fieldName.split('|')[0]; 
            if (additionalFieldToUpdate.has(baseFieldName)) {
                fieldValue.updated = true;
            }
            else if (fieldsToExcludeFromUpdate.has(baseFieldName)) {
                fieldValue.updated = false;
            }
        }
    }
}