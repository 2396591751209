import { useEffect, useRef } from "react";
import attachmentFileStore from "../../../stores/AttachmentFileStore";

interface Props {
    resourceBatchId: string,
    fileId?: string,
}

/**
 * Uses 3rd Party library VolodymyrBaydalka / docxjs to render the Word doc (only supports docx)
 * the library is a javascript library loaded on the main index.html file docx-preview.min.js, and jszip.min.js
 * and is stored in the public/scripts folder.
 */
export const DocxViewer = (props: Props) => {
    const containerRef = useRef(null);

    useEffect(() => {        
        const data = attachmentFileStore.getFileInfo(props.resourceBatchId, props.fileId)?.data;
        if (data && containerRef.current) {
            const docxOptions = Object.assign((window as any).docx.defaultOptions, {
                ignoreWidth: true,
                ignoreHeight: true,
                inWrapper: false,
            });

            const renderDocx = async () => { await (window as any).docx.renderAsync(data, containerRef.current, null, docxOptions); };
            renderDocx();
        }
     }, [containerRef.current]);

    return <div className="docx-viewer-container" ref={containerRef}></div>
}