export enum ValueFormatType {
    //DateAndAge = "DateAndAge",
    ValueAndCode = "ValueAndCode",
    UpperCase = "UpperCase",
    CapitaliseEachWord = "CapitaliseEachWord",
    CapitaliseEachWordIfRequired = "CapitaliseEachWordIfRequired",
    CollectionItem = "CollectionItem",
    PhoneNumber = "PhoneNumber",
    MonthYear = "MonthYear",
    //FirstNotEmpty = "FirstNotEmpty",
    //CsvListNotEmpty = "FirstNotEmpty",
    //ReferralId = "ReferralId",
    //EyeAndTissue = "EyeAndTissue",
    //CheckboxValue = "CheckboxValue",
    //CapitalizedCsvListNotEmpty = "CapitalizedCsvListNotEmpty",
    //AuthorisationTissues = "AuthorisationTissues",
    //ReviewItemFromTag = "ReviewItemFromTag"
    NHSNumber = "NHSNumber",

    Error = "Error"
}