import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useSideMenu } from '../../../context/SideMenuContext';
import { ReactComponent as PointerIcon } from '../../../assets/icons/pointer.svg';
import { motion, Variants } from 'framer-motion';
import { MenuItem } from '../menuItems';
import { useState } from 'react';
import { InView } from 'react-intersection-observer';

interface SubMenuProperties {
   subMenuItem: MenuItem[];
   parentId: string;
   isactiveId: string | null;
   open: boolean;
   handleClickNavigation: Function;
   hasUpdates?: boolean;
}

const itemVariants: Variants = {
   open: {
      opacity: 1,
      y: 0,
      transition: { type: 'tween', stiffness: 100, damping: 24 }
   },
   closed: { opacity: 0, y: 80, transition: { duration: 0.1 } }
};

export const SubMenu = ({ subMenuItem, parentId, isactiveId, open, handleClickNavigation }: SubMenuProperties) => {
   const { openMenuIds, handleMenuIdsOutsideSideMenuViewPort } = useSideMenu();

   const onViewChange = (inView: boolean, entry: any) => {
      const { id } = entry.target.dataset;
      const location = entry.boundingClientRect.bottom > entry.rootBounds.bottom ? 'bottom' : 'top'
      if (inView || (entry.boundingClientRect.bottom === 0 && entry.rootBounds.bottom === 0)) {
         handleMenuIdsOutsideSideMenuViewPort(id, true, undefined);       
      } else {
         handleMenuIdsOutsideSideMenuViewPort(id, false, location);         
      }
   }

   return (
      <motion.nav initial={false} animate={open ? 'open' : 'closed'} className="menu">
         <motion.ul
            variants={{
               open: {
                  clipPath: 'inset(0% 0% 0% 0% round 10px)',
                  transition: {
                     type: 'spring',
                     bounce: 0,
                     duration: 0.5,
                     staggerChildren: 0.05
                  }
               },
               closed: {
                  clipPath: 'inset(10% 50% 90% 50% round 10px)',
                  transition: {
                     type: 'spring',
                     bounce: 0,
                     duration: 0.5
                  }
               }
            }}
            className={clsx('sub-menu', { close: !open || !openMenuIds.includes(parentId) })}
            style={{ pointerEvents: open ? 'auto' : 'none' }}
         >
            {subMenuItem && subMenuItem.length > 0 && (
               <>
                  {subMenuItem.map(mi => (
                     <InView onChange={onViewChange} data-id={mi.path}>
                        <motion.li key={mi.id} variants={itemVariants} onClick={() => handleClickNavigation(mi.path ?? '')}>
                           <PointerIcon className={clsx('pointer', { active: isactiveId === mi.path })} />
                           <Link className={clsx('submenu-link', { 'has-updates': mi.hasUpdates })} to={'#' + (mi.path ?? '')}>{mi.title}</Link>
                        </motion.li>
                     </InView>
                  ))}
               </>
            )}
         </motion.ul>
      </motion.nav>
   );
};
