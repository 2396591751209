import { useEffect, useState } from 'react';
import { SearchApi } from '../../api/searchApi';
import { ReactComponent as MagnifyingGlass } from '../../assets/icons/magnifyingGlass.svg';
import { complexDateUtil } from '../../utils/complexDateUtil';
import { ButtonWithIcon } from '../Inputs/ButtonWithIcon/ButtonWithIcon';
import { LabelledInput } from '../Inputs/LabelledInput/LabelledInput';
import clsx from 'clsx';
import moment from 'moment';

interface Props {
   setOpenPastCutOffDateDialog: Function;
   setOpenNotFoundDialog: Function;
   setOpenDonorDetails: Function;
   setDonorDetails: Function;
   setFailedToOpenDonor: Function;
   isOverlay?: boolean;
   hidden?: boolean;
   invalidDob: Function;
   invalidOdtNumber: Function;
}

export const RecordSearchBar = ({ setOpenPastCutOffDateDialog, setOpenNotFoundDialog, setOpenDonorDetails, setFailedToOpenDonor, setDonorDetails, isOverlay, hidden, invalidDob, invalidOdtNumber }: Props) => {
   const [submitDisabled, setSubmitDisabled] = useState(true);

   const [odtNumber, setOdtNumber] = useState('');
   const [dob, setDob] = useState('');
   const [isLoading, setIsLoading] = useState(false);

   const searchForReferral = async (odtNumber: string, dob: string) => {
      setIsLoading(true);
      const api = new SearchApi();
      try {
         const response = await api.postDonorRecordSearch(+odtNumber, dob);
         if (response && !response.beforeCutOffDate) {
            const dob = new Date(response.dob);
            setDonorDetails({
               referralId: response.recordId,
               odtNumber: response.ntxdDonorId,
               dob: complexDateUtil.formatDobStringFromDate(dob, response.ageText),
               hospital: response.hospitalText,
               donorType: response.donorTypeText,
               sex: response.genderText,
               currentChangeListId: response.currentChangeListId,
               createdDate: response.createdDate
            });
            setOpenDonorDetails(true);
         } else if (response && response.beforeCutOffDate) {
            setOpenPastCutOffDateDialog(true);
         } else {
            setOpenNotFoundDialog(true);
         }
      } catch (e) {
         setFailedToOpenDonor(true);
      }
      setIsLoading(false);
   };

   const validateOdtNumber = (odtNumber: string): boolean => {
      try
      {
         return !Number.isNaN(Number(odtNumber));
      } catch (e) {
         return false;
      }
   }

   const validateDateOfBirth = (dob: string): boolean => {
      try
      {
         var date = moment(dob, 'DD/MM/YYYY');
         if (!date.isValid()) {
            return false;
         }

         if (date.date() < 1 || date.date() > 31 || date.month() < 0 || date.month() > 11 || date.year() < 1900) {
            return false;
         }

         if (dob.split('/')[2].length !== 4) {
            return false;
         }

         return true;
      }
      catch (ex)
      {
         return false;
      }
   };

   useEffect(() => {
      setSubmitDisabled(odtNumber.length === 0 || dob.length === 0);
   }, [dob, odtNumber]);

   const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!validateDateOfBirth(dob)) {
         invalidDob(true);
      } else if (!validateOdtNumber(odtNumber)) {
         invalidOdtNumber(true);
      } else {
         searchForReferral(odtNumber, complexDateUtil.formatDateStringForApi(dob));
      }
   };

   return (
      <div className={clsx('record-search', isOverlay && 'overlay-search', hidden && 'desktopOnly')}>
         <div className="record-search-content">
            <p>Find a Donor Record</p>
            <form className="controls" onSubmit={e => handleSearch(e)}>
               <span>
                  <LabelledInput labelText="ODT Number" value={odtNumber} setValue={setOdtNumber} />
                  <LabelledInput labelText="Date of Birth" placeholder="DD/MM/YYYY" value={dob} setValue={setDob} />
               </span>
               <ButtonWithIcon disabled={submitDisabled} icon={<MagnifyingGlass className="search-icon" />} isLoading={isLoading} />
            </form>
         </div>
      </div>
   );
};
