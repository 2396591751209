export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_ADCLIENTID || "",
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_ADTENANCYID}`,
        redirectUri: process.env.REACT_APP_ADREDIRECTCREDENTIALS || "",
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true 
    }
};
  
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [process.env.REACT_APP_ADSCOPE || ""]
};
