import clsx from 'clsx';
import { ReactElement, useEffect } from 'react';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import { Key } from 'ts-key-enum';


interface AccountMenuProperties {
   /** toggles the account menu drop down */
   show: boolean;
   handler: () => void;
   cookiePrivacyHandler: () => void;
   conditionsHandler:    () => void;
   accessibilityHandler: () => void;
   clickOutsideHandler?: Function;
}

const AccountMenu = ({ show = false, handler, cookiePrivacyHandler, conditionsHandler, accessibilityHandler, clickOutsideHandler }: AccountMenuProperties): ReactElement => {

   useEffect(() => {
      if (clickOutsideHandler) {
         document.addEventListener('click', clickOutsideHandler as EventListener);
         return () => {
            document.removeEventListener('click', clickOutsideHandler as EventListener)
         };
      }
   }, [])


   const handleKeydown = (event: KeyboardEvent) => {
      if (clickOutsideHandler && event.key === Key.Escape) {
         clickOutsideHandler();
      }
   }

   useEffect(() => {
      document.addEventListener('keydown', handleKeydown)
      return () => {
         document.removeEventListener('keydown', handleKeydown);
      }
   })

   return (
      <div className={clsx('account-menu', { hide: !show })} >
         <ul>
            <li>
               <button id="account-menu-cookie" onClick={cookiePrivacyHandler}>Cookies & Privacy</button>
            </li>
            <li>
               <button id="account-menu-conditions" onClick={conditionsHandler}>Terms and Conditions</button>
            </li>
            <li>
               <button id="account-menu-accessibility" onClick={accessibilityHandler}>Accessibility Statement</button>
            </li>
            <li>
               <button id="account-menu-logout" className="logout" onClick={handler}>
                  <span className="logout-text">Logout</span>
                  <LogoutIcon />
               </button>
            </li>
         </ul>
         <div id="account-menu-version" className="version">
            Version {process.env.REACT_APP_VERSION || '1.0.0.0'}
         </div>
      </div>
   );
};

export default AccountMenu;
