import { action, makeObservable, observable } from "mobx";

const InfoPageUrls = ['/accessibility', '/privacy-cookie', '/terms-conditions'];

export class HistoryStore {
    history: string[] = [];

    constructor() {
        makeObservable(this, {
            history: observable,
            push: action,
            pop: action
        })
    }

    push(path: string): void {
        if (path) {
            if (this.history.length === 0 || this.history[this.history.length -1] !== path) {
                this.history.push(path);
            }
        }
    };

    pop(path: string): void {
        do
        {
            this.history.pop();
        } while (this.history.length > 0 && this.history[this.history.length - 1] !== path);
    };

    clear(): void {
        this.history = [];
    }

    getPrevious(excludeInfoPages: boolean): string | undefined {
        const excludeUrls = excludeInfoPages ? InfoPageUrls : []; 
        for (let i = 0; i + 1 < this.history.length; i++) {
            var url = this.history[this.history.length - 2 - i];
            if (!excludeUrls || !excludeUrls.includes(url.toLocaleLowerCase())) {
                return url;
            }
        }

        return undefined;
    };
}

const historyStore = new HistoryStore();
export default historyStore;
