interface Props {
    value: boolean;
    handler: (e: boolean) => void;
}
export const ToggleSwitch = ({ value, handler }: Props) => {
    return <span className='toggle-switch'>
        <label>
            <input type='checkbox' onChange={(e) => handler(e.target.checked)} checked={value} />
            <span className="slider" />
        </label>
    </span>
};
