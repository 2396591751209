import clsx from 'clsx';
import { Fragment, useContext, useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as HamburgerIcon } from '../../../assets/icons/hamburger.svg';
import { ReactComponent as UnselectedBlob } from '../../../assets/icons/sideMenu/unselected.svg';
import { ReactComponent as SelectedBlob } from '../../../assets/icons/sideMenu/selected.svg';
import { ReactComponent as HasUpdatesBlob } from '../../../assets/icons/sideMenu/hasUpdates.svg';
import { useSideMenu } from '../../../context/SideMenuContext';
import { MenuItem, menuItems, scrollToPage } from '../menuItems';
import Menu from './Menu';
import referenceDataStore from '../../../stores/ReferenceDataStore';
import { observer } from 'mobx-react-lite';
import { StoreLoadingStateTypes } from '../../../enums/StoreLoadingStateTypes';
import referralStore from '../../../stores/ReferralStore';
import { BreakpointContext } from '../../../context/BreakpointContext';
import { Key } from 'ts-key-enum';
import { fieldNames } from '../../../constants/fieldNameConstants';
import userStore from '../../../stores/UserStore';
import { AttachmentFilter, AttachmentFilterToType } from '../../../enums/AttachmentFilter';
import { Attachment } from '../../../types/Attachment';

interface SideMenuProperties {
   contentScrolling: boolean;
}

const SideMenu = observer(({ contentScrolling }: SideMenuProperties) => {
   const sessionStorageKey_PersistedActiveId = 'persistedActiveId';
   const persistedActiveId: string | null = sessionStorage.getItem(sessionStorageKey_PersistedActiveId);
   const [activeId, setActiveId] = useState<string | null>(persistedActiveId ?? referenceDataStore.pageLayout.getFirstActivePageId());
   const [attachmentCount, setAttachmentCount] = useState(0);

   const isActiveHandler = (activeId: string) => {
      setActiveId(activeId);
      sessionStorage.setItem(sessionStorageKey_PersistedActiveId, activeId);
   };
   const [openMobileMenu, setOpenMobileMenu] = useState(false);
   const [, setRefresh] = useState(false);
   const { menuIdsAboveViewPort, menuIdsBelowViewPort, calcIfMenuItemHasUpdates, isOpening } = useSideMenu();
   const [hiddenUpdatesAtTop, setHiddenUpdatesAtTop] = useState(false);
   const [hiddenUpdatesAtBottom, setHiddenUpdatesAtBottom] = useState(false);

   const openMobileMenuHandler = () => setOpenMobileMenu(true);
   const closeMobileMenuHandler = () => setOpenMobileMenu(false);   

   const isActiveMenuItem = (menuItem: MenuItem): boolean => {
      if (menuItem.path === activeId) {
         return true;
      }

      return menuItem.subMenu ? menuItem.subMenu.some(c => c.path === activeId) : false;
   };

   const calcHiddenMenuItemWithUpdates = () => {      
      var menuItemsWithUpdates = menuItems.flatMap(menuItem => [menuItem, ...(menuItem.subMenu || [])])
                                          .filter(menuItem => menuItem.hasUpdates || 
                                                              (menuItem.subMenu && menuItem.subMenu.some(s => s.hasUpdates)))
                                          .map(menuItem => menuItem.path); 
      if (menuIdsAboveViewPort) {                                          
         setHiddenUpdatesAtTop(menuIdsAboveViewPort.some(menuId => menuItemsWithUpdates.includes(menuId)));
      }
      if (menuIdsBelowViewPort) {
         setHiddenUpdatesAtBottom(menuIdsBelowViewPort.some(menuId => menuItemsWithUpdates.includes(menuId)));
      }
   }

   const calcAttachments = () => {
      var attachments = referralStore.getFieldCollection(fieldNames.RESOURCEMETADATAS).filter(a => a.toValue);
      if (!userStore.canViewMediaAttachments) {
         const filter = AttachmentFilterToType[AttachmentFilter.Files];
         attachments = attachments.filter(a => filter.includes((JSON.parse(a.toValue!) as Attachment).type));
      }

      setAttachmentCount(attachments.length);
   }

   useEffect(() => {
      if (!isOpening) {
         calcHiddenMenuItemWithUpdates();
      }
   }, [menuIdsAboveViewPort, menuIdsBelowViewPort, isOpening]);

   useEffect(() => {
      calcIfMenuItemHasUpdates();
      calcHiddenMenuItemWithUpdates();
      calcAttachments();
      setRefresh(prev => !prev);
   }, [referralStore.updatedFieldNames, referenceDataStore.state]);   

   useEffect(() => {      
      if (activeId) {
         scrollToPage(activeId);
      }
   }, [referenceDataStore.state]);

   useEffect(() => {
      var pageId = referenceDataStore.pageLayout.firstPageInView;
      if (pageId && pageId !== activeId && referenceDataStore.state === StoreLoadingStateTypes.Ready) {
         setActiveId(referenceDataStore.pageLayout.firstPageInView);
         sessionStorage.setItem(sessionStorageKey_PersistedActiveId, pageId);
      }
   }, [referenceDataStore.pageLayout.firstPageInView]);

   const isMobile = useContext(BreakpointContext);

   const handleKeydown = (event: KeyboardEvent) => {
      if (isMobile && event.key === Key.Escape) {
         closeMobileMenuHandler();
      }
   }

   useEffect(() => {
      document.addEventListener('keydown', handleKeydown)
      return () => {
         document.removeEventListener('keydown', handleKeydown);
      }
   })

   return (
      <>
         <nav className={clsx('mobile-side-nav', { scroll: contentScrolling })}>
            <button onClick={openMobileMenuHandler}>
               <HamburgerIcon className="hamburger" />
            </button>
            {menuItems.map(menuItem => {
               var blob;
               if (isActiveMenuItem(menuItem)) {
                  blob = <SelectedBlob className='dot' />
               } else if (menuItem.hasUpdates || (menuItem.subMenu && menuItem.subMenu.some(s => s.hasUpdates))) {
                  blob = <HasUpdatesBlob className='dot' />
               } else {
                  blob = <UnselectedBlob className='dot' />
               }

               var dotClass = clsx('dot', { 'has-updates': menuItem.hasUpdates || (menuItem.subMenu && menuItem.subMenu.some(s => s.hasUpdates)) });
               return (
                  <Fragment key={menuItem.id}>
                     {blob}
                     {menuItem.id === 'snod'     ? <hr /> : null}
                  </Fragment>
               );
            })}
         </nav>
         {(!isMobile || (isMobile && openMobileMenu)) && (
            <div className={clsx('side-menu scroll-style-1', 
                                 { close: !openMobileMenu }, 
                                 { open: openMobileMenu },
                                 { 'has-updates-top': hiddenUpdatesAtTop },
                                 { 'has-updates-bottom': hiddenUpdatesAtBottom },
                                 )}>
               <button className="sideMenu-close-icon" onClick={closeMobileMenuHandler} >
                  <CloseIcon  />
               </button>

               <div className="nav">
                     {menuItems.map(menuItem => {
                        return menuItem.id === 'snod' ? (
                           <Fragment key={menuItem.id}>
                              <Menu
                                 title={menuItem.title}
                                 key={menuItem.id}
                                 path={menuItem.path || ''}
                                 id={menuItem.id}
                                 isActiveHandler={isActiveHandler}
                                 closeMobileMenu={closeMobileMenuHandler}
                                 isActiveId={activeId}
                                 hasUpdates={menuItem.hasUpdates}
                                 subMenu={menuItem.subMenu ?? []}
                              />
                              <hr />
                           </Fragment>
                        ) : (
                           <Menu
                              title={menuItem.title + (menuItem.path === 'Attachments' && attachmentCount > 0 ? ` (${attachmentCount})` : '')}
                              key={menuItem.id}
                              path={menuItem.path || ''}
                              id={menuItem.id}
                              isActiveHandler={isActiveHandler}
                              closeMobileMenu={closeMobileMenuHandler}
                              isActiveId={activeId}
                              hasUpdates={menuItem.hasUpdates}
                              subMenu={menuItem.subMenu ?? []}
                           />
                        );
                     })}
               </div>
            </div>
         )}
      </>
   );
});

export default SideMenu;
