import { FieldAlternativeTextModel } from "../api/Models/referenceDataModel";
import { ConditionOperatorType } from "../enums/ConditionOperatorType";
import { ValidationConditionModel, PageItemValidationModel } from "../stores/PageLayoutStore";
import referenceDataStore from "../stores/ReferenceDataStore";
import referralStore from "../stores/ReferralStore";
import { ValidationConditionFunc } from "../types/FieldConditionRule";

export class PageLayoutToFieldInfoConverter {
    /**
     * Converts a PageItemValidationModel to a condition
     *
     * @param validation       - PageItemValidationModel from the server
     * @param invertCondition  - if the result of action should be inverted (as error and warning trip if action returns false)
     */
     public static ConvertPageItemValidation(validation: PageItemValidationModel | FieldAlternativeTextModel, invertCondition: boolean): ValidationConditionFunc {
         let action: ValidationConditionFunc = () => false;
         var actions: any[] = [];
         if (validation.conditions!.length === 1) {
             action = PageLayoutToFieldInfoConverter.ConvertPageItemValidationCondition(validation.conditions![0]);
         } else if (!!validation.conditionsOperator) {
             switch (validation.conditionsOperator) {
                 case ConditionOperatorType.Or:
                     actions = validation.conditions!.map(
                         PageLayoutToFieldInfoConverter.ConvertPageItemValidationCondition
                     );
                     action = (fieldName) => actions.some(a => a(fieldName));
                     break;
                 case ConditionOperatorType.And:
                     actions = validation.conditions!.map(
                         PageLayoutToFieldInfoConverter.ConvertPageItemValidationCondition
                     );
                     action = (fieldName) => actions.every(a => a(fieldName));
                     break;
                 default:
             }
         }

         // Invert action (as warnings and errors are triggered on false return value)
         let correctedAction = action;
         if (invertCondition) {
             correctedAction = (fieldName) => {
                 return !action(fieldName);
             };
         }

         return correctedAction;
    }

     public static ConvertPageItemValidationCondition(condition: ValidationConditionModel): ValidationConditionFunc {
        let result: ValidationConditionFunc;

        switch (condition.type.toLowerCase()) {
            case "areallfieldsempty": // AreAllFieldsEmpty
                result = () => {
                    const fieldNames = condition.parameter1!.split(",");
                    var changeFields = fieldNames.map(fieldName => referralStore.getField(fieldName));
                    return changeFields.every(changeField => !changeField || !changeField.toValue);
                };
                break;
            case "isanyfieldequaltovalue":  // IsAnyFieldEqualToValue
                result = (fieldName) => {
                    const fieldNames   = condition.parameter1!.split(",");
                    const equalToValue = condition.parameter2!.split(",");
                    var changeFields = fieldNames.map(fieldName => referralStore.getField(fieldName));                    
                    return changeFields.some(changeField => changeField && changeField.toValue && equalToValue.includes(changeField.toValue));
                };
                break;
            case "isreport": // IsReport
                result = () => false;
                break;

            case "iscomplextypepresent": // IsComplexTypePresent
                result = () => {
                    const baseFieldName = condition.parameter1!;
                    var complexField = referralStore.getFieldCollection(baseFieldName);
                    return complexField.length > 0 && complexField.some(f => f.toValue !== null);
                }
                break;

            case "createddateaftersetting":
                result = () => {
                    var settingName = condition.parameter1 || '';
                    var settingDate = referenceDataStore.settings.getSettingAsDate(settingName);
                    return !!settingDate && referralStore.createdDateSameOrAfter(settingDate);            
                };
                break;       

            default:
                console.error("Unsupported layout validation condition " + condition.type);
                result = () => false;
                break;
         }

         if (condition.notCondition) {
             return (fieldName) => !result(fieldName);
         } else {
             return result;
         }
     }
}
