import attachmentFileStore from "../../../stores/AttachmentFileStore"

interface Props {
    resourceBatchId: string,
    fileId?: string,
}

/**
 * Renders the text in a div
 */
export const TxtViewer = (props: Props) => {
    var data = attachmentFileStore.getFileInfo(props.resourceBatchId, props.fileId)?.data;
    const decoder = new TextDecoder();
    return <div className="txt-viewer-container">{decoder.decode(new Uint8Array(data!))}</div>
}