import BaseHub, { HubMethodMap } from "./baseHub";
import referralPollUpdatesApi from "../api/referralPollUpdatesApi";
import { SettingConstants } from "../constants/settingConstants";

class ReferralHub extends BaseHub {
    private referralId: string | undefined;
    private currentTxPChangeListId: string | undefined;

    public constructor() {
        super(`${process.env.REACT_APP_TRANSPLANTPATH_API_BASEURL}/Hub/Referral`, 
              SettingConstants.WEB_CLIENT_REFERRAL_UPDATE_POLL_INTERVAL, 
              (methodMap: HubMethodMap) => { this.manuallyPollEndPointFunc(methodMap)} );
    }

    public onNewUpdates(newMethod: (referralId: string, txpChangeListId: string | undefined, currentChangeListId: string | undefined) => void) {
        this.on("NewUpdates", newMethod);
    }

    public offNewUpdates(newMethod: (referralId: string, txpChangeListId: string | undefined, currentChangeListId: string | undefined) => void) {
        this.off("NewUpdates", newMethod);
    }    

    public onAcknowledgedUpdates(newMethod: (referralId: string, txpChangeListId: string) => void) {
        this.on("AcknowledgedUpdates", newMethod);
    }

    public offAcknowledgedUpdates(newMethod: (referralId: string, txpChangeListId: string) => void) {
        this.off("AcknowledgedUpdates", newMethod);
    }

    public OpenedReferral(referralId: string, currentTxPChangeListId: string | undefined) {
        this.referralId = referralId;
        this.currentTxPChangeListId = currentTxPChangeListId;
        this.invoke("OpenedReferral", referralId, currentTxPChangeListId);
    }

    public async manuallyPollEndPointFunc(methodMap: HubMethodMap) {
        if (this.referralId != null) {
            var result = await referralPollUpdatesApi.updatesPoller(this.referralId, this.currentTxPChangeListId);
            methodMap["NewUpdates"](result.referralId, result.txpChangeListId);
        }
     }    
}

export const referralHub = new ReferralHub();
