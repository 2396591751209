import clsx from "clsx";

interface Props {
   dose?: string;
   frequency?: string;
   route?: string;
   dateCommenced?: string;
   dateCeased?: string;
   name?: string | null;
   administered?: boolean;
   fieldName?: string;
   hasUpdates?: boolean;   
}

const OtherDrug = ({ dose, frequency, route, dateCommenced, dateCeased, name, administered, fieldName, hasUpdates }: Props) => {
   const [_dateCommenced] = dateCommenced?.split(' ') || [];
   const [_dateCeased] = dateCeased?.split(' ') || [];
   
   const drugViewWrapperClass = clsx({
      "drug-view-wrapper": true,
      "has-updates":hasUpdates === true,
   });

   return (
      <div className={drugViewWrapperClass}>
         <div className="other-drug-view">
            <div className="other-drug-header">
               <div className="name">
                  {fieldName && <span className={clsx(!name && 'no-name')}>{fieldName}</span>}
                  {name && <span className="text">{name}</span>}
               </div>
               <div className="administered">{administered === undefined ? '' : administered ? 'Yes' : 'No'}</div>
            </div>
            <div className="other-drug-body">
               <div className="other-drug-dose">
                  <span className="key">Dose</span>
                  <span className="dose">{dose}</span>
               </div>
               <hr></hr>
               <div className="other-drug-frequency">
                  <div>
                     <span className="key">Frequency</span>
                     <span className="text">{frequency}</span>
                  </div>
                  <div>
                     <span className="key">Route</span>
                     <span className="text">{route}</span>
                  </div>
                  <div>
                     <span className="key">Date commenced</span>
                     <span className="text">
                        <span style={{ marginRight: '12px' }}>{_dateCommenced}</span>
                     </span>
                  </div>
                  <div>
                     <span className="key">Date ceased</span>
                     <span className="text">
                        <span style={{ marginRight: '12px' }}>{_dateCeased}</span>
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default OtherDrug;
