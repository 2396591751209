import clsx from 'clsx';
import { MouseEventHandler } from 'react';
import { ReactComponent as Spinner } from '../../../assets/icons/spinner.svg';

interface ButtonProperties extends React.ButtonHTMLAttributes<HTMLButtonElement> {
   /** The text displayed on the button */
   text?: string;
   /** Makes the button fill the full width of its parent */
   fullWidth?: boolean;
   /** The event which will trigger when the button is activated */
   onClick?: MouseEventHandler<HTMLButtonElement>;
   /** Determines whether the button is disabled */
   isDisabled?: boolean;
   /** Sets the type of the button to submit, so that it can be used to submit a form */
   submit?: boolean;
   /** Allows extra classes to be added to the button, such as margins */
   className?: string;
   /** The loading state of the button */
   isLoading?: boolean;
}

const Button = ({ text, fullWidth = false, onClick, isDisabled = false, className, submit, isLoading, ...restProperties }: ButtonProperties) => {
   return (
      <button
         className={clsx('btn', { 'fullWidth-btn': fullWidth }, className)}
         onClick={onClick}
         {...restProperties}
         disabled={isDisabled || isLoading}
      >
         {isLoading ? <Spinner className="spin loading-spinner" /> : <div className="btn-content">{text}</div>}
      </button>
   );
};

export default Button;
