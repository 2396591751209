import { baseApi } from "./baseApi";
import { ReferenceDataModel } from "./Models/referenceDataModel";

class ReferenceDataApi extends baseApi {
    public getReferenceData(fieldVersion: number, codeSetVersion: number, pageLayoutVersion: number): Promise<ReferenceDataModel> {
        var url = `${process.env.REACT_APP_TRANSPLANTPATH_API_BASEURL}/api/ReferenceData?fieldVersion=${fieldVersion}&codeSetVersion=${codeSetVersion}&pageLayoutVersion=${pageLayoutVersion}`;
        return super.get<ReferenceDataModel>(url);
    }
}

const referenceDataApi = new ReferenceDataApi();
export default referenceDataApi;
