import { useEffect, useRef, useState } from 'react';
import { Breadcrumb } from '../../components/Header/Breadcrumb/Breadcrumb';
import { BreadcrumbNav } from '../../components/Header/BreadcrumbNav/BreadcrumbNav';
import { Header } from '../../components/Header/Header';
import historyStore from '../../stores/HistoryStore';
import { Key } from 'ts-key-enum';

export const TermsConditions = () => {
   const [previousLinkRoute, setPreviousLinkRoute] = useState('');
   const [previousLinkText, setPreviousLinkText] = useState('');
   const [activeTab, setActiveTab] = useState();
   const sectionRef = useRef<HTMLDivElement>(null);
   const keyboardScrollAmount = 30;

   const handleKeydown = (event: KeyboardEvent) => {
     if (event.key === Key.ArrowDown) {
        sectionRef.current?.scrollBy({top: keyboardScrollAmount});
     }else if (event.key === Key.ArrowUp) {
        sectionRef.current?.scrollBy({top: -keyboardScrollAmount});
     }
   }

   useEffect(() => {
     document.addEventListener('keydown', handleKeydown)
     return () => {
        document.removeEventListener('keydown', handleKeydown);
     }
   })

   useEffect(() => {
      var url = historyStore.getPrevious(true);

      setPreviousLinkRoute(url || '/');

      var previousLinkText = 'Log in';
      if (url && url.toLocaleLowerCase().indexOf('/referral') > -1) {
         previousLinkText = 'Donor Record';
      } else if (url && url.toLocaleLowerCase().indexOf('/search') > -1) {
         previousLinkText = 'Search';
      }
      setPreviousLinkText(previousLinkText);
   }, [historyStore.history]);

   const breadcrumb = <Breadcrumb previousLinkRoute={previousLinkRoute} previousLinkText={`Back to ${previousLinkText}`} />

   return (
      <>
         <Header leftContentMobile={breadcrumb} activeTab={activeTab} setActiveTab={setActiveTab} showAlerts/>
         <BreadcrumbNav hideOnMobile pageTitle={"Terms and Conditions"} previousLinkRoute={previousLinkRoute} previousLinkText={previousLinkText} />
         <section className="terms-conditions" ref={sectionRef}>
            <div className="container">
               <h1>Terms and Conditions</h1>

               <h2>The Service</h2>
               <p>TransplantPath is a service provided by NHSBT for NHS transplant 
               colleagues in UK Donating Hospitals/National Transplant Centres/National
               Tissue Centres/Approved Research Institutions to assess the suitability of
               an organ offer.</p>
               <p>It is a view only web application that can be accessed from any internet
               enabled device. The application provides a real time view of organ/tissue
               donor information that is added to DonorPath by Specialist Nurses.</p>
               <p>Access to the service is restricted to employees of NHS
               organisations/tissue organisations/approved research institutions that
               have a current data Information Sharing Protocol in place with NHSBT.</p>
               <p>If you wish to apply for access, contact NHSBT Service Desk on <a href="mailto:service.desk@nhsbt.nhs.uk">service.desk@nhsbt.nhs.uk</a></p>

               <h2>Terms of Use</h2>
               <p>Access to the service is restricted to employees of organisations that have
               a current data sharing Memorandum of Understanding/Material Transfer
               Agreement with NHSBT.</p>                
               <p>To become a registered user of the service, you are required to complete
               a course of mandatory training and make a formal application to NHSBT.</p>

               <h2>Accessing the Service</h2>
               <p>​TransplantPath credentials are managed via Azure AD and the Admin
               Portal can only be accessed via the NHSBT Private Network.</p>
               <p>​TransplantPath users must have an Azure AD account belonging to a trust
               that NHSBT has granted access to. TransplantPath authenticates users
               using Azure SSO, their own NHS trust policies for user account, password
               management and 2 Factor Authentication (2FA) apply. Additionally,
               NHSBT enforces its own 2FA via Azure AD. Each individual user will also
               need to be granted access to TransplantPath via the Admin Portal, where
               their user role can be applied.</p>

               <h2>The data</h2>
               <p>All data provided by this service is in line with Data Protection and Caldicott principles.</p>
               <p>The data provided is compliant with GDPR Articles 6-1e, 6-1a, 9-2h &amp; 9-2a.</p>

               <h2>Issues with Service</h2>
               <p>If you are experiencing any issues with accessing this service please contact your internal Information Technology services or 
               if there are issues using this service, contact NHSBT Service Desk on <a href="mailto:service.desk@nhsbt.nhs.uk">service.desk@nhsbt.nhs.uk</a></p>

               <h2>Consent</h2>
               <p>By agreeing to these Terms and Conditions you consent to NHSBT
               recording in an audit log your activity in the service. The audit log will
               record your username, and time and date stamps of all actions
               performed in the service.</p>
               <p>This data is required to optimise the delivery of the service and to act as
               a medical / legal record to be able to support any investigations.</p>
               <p>You agree to upholding all principles of Data Protection Legislation especially as it relates to PID. The supplied data may only be reviewed 
               by the intended recipient and must not be shared with any other parties.</p>
               <p>You confirm that you have received appropriate data security and
               protection training in the last 12 months and are aware of the
               confidential nature and duties placed on those processing identifiable,
               sensitive, confidential, and anonymised/pseudonymised information.</p>
               <p>If you become aware of any unlawful accessing of the service, then you
               must notify NHSBT Service Desk on <a href="mailto:service.desk@nhsbt.nhs.uk">service.desk@nhsbt.nhs.uk</a> within 24 hours.</p>
               <p>If you leave your role you must ensure your account is closed by
               notifying your local organisation who will revoke your access to your Azure AD account.</p>
               <p>Your TransplantPath access will be deactivated after 12 months on non-usage. 
               If you still require access after this time, please reapply as outlined in ‘Accessing the Service’ above.</p>
            </div>
         </section>
      </>
   );
};
