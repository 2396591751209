import { baseApi } from "./baseApi";
import { ReferralModel } from "./Models/ReferralModel";

class DetailsApi extends baseApi {
    public getDetails(referralId: string, opening?: boolean): Promise<ReferralModel> {
        const openingQueryString = opening ? `&opening=true` : '';
        var url = `${process.env.REACT_APP_TRANSPLANTPATH_API_BASEURL}/api/Referral/Details?referralId=${referralId}${openingQueryString}`;
        return super.get<ReferralModel>(url);
    }
}

const detailsApi = new DetailsApi();
export default detailsApi;
