import clsx from "clsx";
import { AttachmentLoadState } from "../../enums/AttachmentLoadState";
import { Attachment, AttachmentHelper } from "../../types/Attachment";
import { Pill, PillType } from "./Pill";
import { useState, useEffect } from "react";
import { complexDateUtil } from "../../utils/complexDateUtil";
import Spinner from '../../assets/icons/spinner-blue.png';
import { ReactComponent as FileDownloadedIcon } from '../../assets/icons/fileDownloaded.svg';
import attachmentFileStore from "../../stores/AttachmentFileStore";
import attachmentUrlApi from "../../api/attachmentUrlApi";
import referralStore from "../../stores/ReferralStore";
import { DocumentViewer, DocumentViewerSupportedFileTypes } from "../AttachmentViewer/DocumentViewer";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";
import fieldValues from "../../constants/referralFieldValueConstants";
import { DocumentImageViewer } from "../AttachmentViewer/DocumentImageViewer";
import { AttachmentErrorType } from "../../enums/AttachmentErrorType";
import attachmentDownload, { AttachmentDownload } from "../../api/attachmentDownload";
import { fieldNames } from "../../constants/fieldNameConstants";
import eventApi from "../../api/eventApi";
import { SystemEventType } from "../../enums/SystemEventType";

interface Props {
    fieldName: string;
    attachment: Attachment;
}

const DocumentDownloadFileTypes = [
    'application/xml',
];

export const AttachmentDocumentControl = (props: Props) => {
    const [ , setForceUpdate ] = useState(0);
    const [ viewAttachment, setViewAttachment ] = useState(false);

    const datetime        = complexDateUtil.convertStringToDate(props.attachment.timestamp);
    const resourceBatchId = props.fieldName.split('|')[1];
    const fileIds         = AttachmentHelper.getFileDetails(props.attachment).map(f => f.fileId!);

    useEffect(() => {
        attachmentFileStore.onChange.subscribe(handleAttachmentStoreChange);
        return () => {
            attachmentFileStore.onChange.unsubscribe(handleAttachmentStoreChange);
        }
    }, []);

    // There might be more that one document so state will be the accumulation of all states
    const getState = (): AttachmentLoadState => {
        var states = fileIds.map(fileId => attachmentFileStore.getState(resourceBatchId, fileId));
        if (states.includes(AttachmentLoadState.Error)) {
            return AttachmentLoadState.Error;
        } else if (states.includes(AttachmentLoadState.Loading)) {
            return AttachmentLoadState.Loading;
        } else if (states.every(s => s === AttachmentLoadState.Loaded)) {
            return AttachmentLoadState.Loaded;
        } else {
            return AttachmentLoadState.None;
        }
    }

    const getErrorMsg = (): string | undefined => {
        var errorType= fileIds.map(fileId => attachmentFileStore.getError(resourceBatchId, fileId))
                              .find(error => error !== AttachmentErrorType.None) || AttachmentErrorType.None;
        return attachmentFileStore.getErrorMessage(errorType, props.attachment.type);
    }

    const handleAttachmentStoreChange = (sender: any, id: string | undefined) => {
        if (!id || resourceBatchId === id) {
            setForceUpdate(prev => prev + 1);
        }
    }    

    const handleOnClick = async () => {
        const firstFileId = fileIds.length === 0 ? undefined : fileIds[0];

        if (props.attachment.contentType && DocumentDownloadFileTypes.includes(props.attachment.contentType.toLowerCase())) {
            attachmentFileStore.setState(resourceBatchId, firstFileId, AttachmentLoadState.Loading);
            
            var loaded = false;
            try {
                const urls = (await attachmentUrlApi.getUrls(referralStore.referralId!, resourceBatchId, fileIds));
                await urls?.forEach(async (data, index) => {
                    await attachmentDownload.download(data.url, data.filename);
                    attachmentFileStore.setState(resourceBatchId, firstFileId, AttachmentLoadState.Loaded);
                    if (getState() === AttachmentLoadState.Loaded) {
                        setViewAttachment(true);
                    }    
                });           
                loaded = true;     
            } catch (error: any) {
                attachmentFileStore.setState(resourceBatchId, firstFileId, AttachmentLoadState.Error, AttachmentErrorType.DownloadFailed);
                console.error('Error:', error.message);
            }


            try {
                if (loaded) {
                    var referralStatus = referralStore.getField(fieldNames.REFERRALSTATUS_STATUS);
                    var eventMessage = `Download attachment ${resourceBatchId} - ${resourceBatchId} - ${referralStatus?.toValue || ''}`;
                    eventApi.postEvent(referralStore.referralId!, SystemEventType.OpenAttachment, eventMessage);
                }
            }
            catch (error: any) {
            }            
        } else if (getState() === AttachmentLoadState.Loaded) {
            setViewAttachment(true);
        } else if (!props.attachment.contentType || !DocumentViewerSupportedFileTypes.includes(props.attachment.contentType.toLowerCase())) {
            attachmentFileStore.setState(resourceBatchId, firstFileId, AttachmentLoadState.Error, AttachmentErrorType.UnsupportedType);
        } else {
            attachmentFileStore.setState(resourceBatchId, firstFileId, AttachmentLoadState.Loading);
            
            try {
                const urls = (await attachmentUrlApi.getUrls(referralStore.referralId!, resourceBatchId, fileIds));
                await urls?.forEach(async (data, index) => {
                    await attachmentFileStore.download(resourceBatchId, data.resourceId, data.url, data.filename, data.paramsAsRequestHeaders);
                    if (getState() === AttachmentLoadState.Loaded) {
                        setViewAttachment(true);
                    }    
                });    
            } catch (error: any) {
                attachmentFileStore.setState(resourceBatchId, firstFileId, AttachmentLoadState.Error, AttachmentErrorType.DownloadFailed);
                console.error('Error:', error.message);
            }
        }                            
    }

    const onRenderError = () => {
        const firstFileId = fileIds.length === 0 ? undefined : fileIds[0];
        attachmentFileStore.setState(resourceBatchId, firstFileId, AttachmentLoadState.Error, AttachmentErrorType.RenderError);
    }

    const renderIcon = () => {
        if (getState() === AttachmentLoadState.Loading) {
            return <img src={Spinner} className="spin" alt="spinner" />;        
        } else  {
            return <FileDownloadedIcon />;
        }
    }

    const renderDocumentViewer = () => {
        if (props.attachment.type === fieldValues.ATTACHMENT.TYPE.DOCUMENT_IMAGE) {
            return <ErrorBoundary onError={onRenderError}>
                       <DocumentImageViewer resourceBatchId={resourceBatchId} onClose={() => setViewAttachment(false)} />
                   </ErrorBoundary>
        } else {
            const firstFileId = fileIds.length === 0 ? undefined : fileIds[0];
            return <ErrorBoundary onError={onRenderError}>
                       <DocumentViewer resourceBatchId={resourceBatchId} fileId={firstFileId} onClose={() => setViewAttachment(false)} />
                   </ErrorBoundary>
        }
    }    

    return <div id={props.fieldName} className='attachment attachment-document'>
                <button className={clsx("frame", AttachmentLoadState[getState()].toLowerCase())} onClick={handleOnClick}> 
                    <div className="error-msg">{getErrorMsg()}</div>
                    <span className="icon">{renderIcon()}</span>
                </button>
                <div className="details">
                    <Pill code={props.attachment.category} type={PillType.Category}  />
                    <hr />
                    <div className="datetime">
                        <span className="date">{datetime && datetime.format("DD/MM/YYYY")}</span>
                        <span className="time">{datetime && datetime.format("HH:mm")}</span>
                    </div>
                </div>
                {viewAttachment && renderDocumentViewer()}
           </div>
};
