import { observer } from "mobx-react-lite"
import referralStore from "../../stores/ReferralStore";
import { useContext, useEffect, useRef, useState } from "react";
import referenceDataStore from "../../stores/ReferenceDataStore";
import { getMenuItemFromPath, getParentMenu } from "../Navigation/menuItems";
import { ActivityLogAction } from "../../enums/ActivityLogAction";
import { complexDateUtil } from "../../utils/complexDateUtil";
import { ActivityLogItem, ActivityLogItemProps } from "./ActivityLogItem";
import { Key } from "ts-key-enum";
import { BreakpointContext } from "../../context/BreakpointContext";
import { isSafari } from "react-device-detect";
import { fieldNames } from "../../constants/fieldNameConstants";

interface Props {
    onClose: (target?: Node) => void;
}

export const ActivityLogDialog = observer((props: Props) => {
    const MaxViewableItemsInDesktop = 6;
    const MaxViewableItemsInMobile  = 5;
    const FieldsExcludedFromLog = [
        fieldNames.HLASAMPLEID,
        fieldNames.HLADATA_LABNAME, 
        fieldNames.HLADATA_COMMENT, 
        fieldNames.HLARESULTS,
        fieldNames.RESOURCEMETADATAS
    ];

    const [history, setHistory] = useState<ActivityLogItemProps[]>([]);
    const refContainer = useRef<HTMLDivElement>(null);
    const refItems = useRef<(HTMLButtonElement | null)[]>([]);
    const isMobile = useContext(BreakpointContext);
        
    const getHistory = (): ActivityLogItemProps[] => {
        let historyItems: { [key: string]: ActivityLogItemProps } = {};
        let changeListsToDisplay = new Set(referralStore.getChangeListIdFromFirstView(false));
        let changeListsUpdated   = new Set(referralStore.getChangeListIdFromCurrentTxpChangeListId());

        for (const field of Object.values(referralStore.fields)) {
            if (field.fieldName && field.history && field.history.length > 0 && !FieldsExcludedFromLog.includes(field.fieldName)) {
                var page = referenceDataStore.pageLayout.getPageByFieldName(field.fieldName);
                if (!page) {
                    continue;
                }

                var pageOrderIndex = referenceDataStore.pageLayout.getOrderedPageKeys().indexOf(page.pageId);
                var menuItem = getMenuItemFromPath(page.pageId);
                var parentMenuItem = getParentMenu(page.pageId);

                for (let i = 0; i < field.history.length; i++) {
                    if (!changeListsToDisplay.has(field.history[i].changeListId)) {
                        continue;
                    }

                    const timestamp = complexDateUtil.convertStringToDate(field.history[i].timestamp);                    

                    const key = `${timestamp!.format("mmHHDDMMYYYY")}-${(parentMenuItem ? parentMenuItem.path : '')}-${menuItem!.path}`;
                    if (Object.keys(historyItems).includes(key)) {
                        historyItems[key].actions.add(field.history[i].action);
                        historyItems[key].updated ||= changeListsUpdated.has(field.history[i].changeListId);
                    }
                    else {                    
                        historyItems[key] = {
                                                timestamp: timestamp!,
                                                actions: new Set([field.history[i].action]),
                                                pageOrderIndex: pageOrderIndex,
                                                menuItem: parentMenuItem ?? menuItem!,
                                                subMenuItem: parentMenuItem && parentMenuItem.path !== menuItem?.path ? menuItem! : null,
                                                updated: changeListsUpdated.has(field.history[i].changeListId),                                        
                                            };
                    }
                }
            }
        }

        // sort by timestamp descending, and menu\submenu item ascending
        return Object.values(historyItems).sort((a,b) => 
                    a.timestamp.isSame(b.timestamp) ? a.pageOrderIndex - b.pageOrderIndex : b.timestamp.valueOf() - a.timestamp.valueOf()
                );
    }
    
    const handleClickOutside = (event: Event) => {
        if (refContainer.current && !refContainer.current.contains(event.target as Node)) {
            props.onClose(event.target as Node);
        }
    };

    const updateFocusedItem = (steps: number) => {
        if (refItems && refItems.current.length > 0) {
            var currentFocusedIndex = refItems.current.findIndex(i => i === document.activeElement);
            if (currentFocusedIndex !== -1) {
                currentFocusedIndex += steps;
            }

            currentFocusedIndex = Math.min(Math.max(currentFocusedIndex, 0), refItems.current.length - 1);
            refItems.current[currentFocusedIndex]?.focus();
        }
    };

    const handleKeydown = (event: KeyboardEvent) => {
        switch (event.key) {
        case Key.Escape: props.onClose(); break;
        case Key.ArrowDown:updateFocusedItem(1);  event.preventDefault(); event.stopPropagation(); break;
        case Key.ArrowUp:  updateFocusedItem(-1); event.preventDefault(); event.stopPropagation(); break;
        case Key.PageDown: updateFocusedItem(isMobile ?  MaxViewableItemsInMobile :  MaxViewableItemsInDesktop); event.preventDefault(); break;
        case Key.PageUp:   updateFocusedItem(isMobile ? -MaxViewableItemsInMobile : -MaxViewableItemsInDesktop); event.preventDefault(); break;
        }
    };

    useEffect(() => {
        let history = getHistory();
        if (history.length === 0) {
            props.onClose();
        } else {
            setHistory(history);        
        }
    }, [referralStore.latestChangeListId]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeydown, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keydown', handleKeydown, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return <>
        <button className='mobile-account-menu-overlay' onClick={() => props.onClose()} />
        <div ref={refContainer} className="activity-log-container">
            <div className="scroll-style-1">
                {history.map((props, index) => <ActivityLogItem ref={(ref) => (refItems.current[index] = ref)}  {...props} />)}
            </div>
            {isMobile && isSafari && history.length > MaxViewableItemsInMobile && <div className="more-indicator"></div>}
        </div>
    </>
});

