export const fieldNames = {
   OTHER_USER_SPECIFIED_DRUG_ROUTE: 'OtherUserSpecifiedDrug_Route',
   OTHER_USER_SPECIFIED_DRUG_NAME: 'OtherUserSpecifiedDrug_Name',
   OTHER_PREDEFINED_DRUG_TYPE: 'OtherPredefinedDrug_Type',
   OTHER_USER_SPECIFIED_DRUG_FREQUENCY: 'OtherUserSpecifiedDrug_Frequency',
   OTHER_USER_SPECIFIED_DRUG_COMMENCED_DATE: 'OtherUserSpecifiedDrug_CommencedDate',
   OTHER_USER_SPECIFIED_DRUG_CEASED_DATE: 'OtherUserSpecifiedDrug_CeasedDate',

   PLASMADILUTIONS: 'PlasmaDilutions|',
   PLASMADILUTIONSCALC: 'PlasmaDilutionsCalc|',
   PATIENTASSESSMENT_CRYSTALLOIDTOTALCALC: "PatientAssessment_CrystalloidTotalCalc",
   PATIENTASSESSMENT_BLOODANDCOLLOIDTOTALCALC: "PatientAssessment_BloodAndColloidTotalCalc",

   KIDNEYANATOMY_PERFUSATETYPE: "KidneyAnatomy_PerfusateType",
   KIDNEYANATOMY_OTHERABNORMALITIES: "KidneyAnatomy_OtherAbnormalities",
   KIDNEYANATOMY_OTHERRETRIEVALDAMAGE: "KidneyAnatomy_OtherRetrievalDamage",

   PATIENTDETAIL_WEIGHTKG: "PatientDetail_WeightKg",
   PATIENTDETAIL_WEIGHTACCURACY: "PatientDetail_WeightAccuracy",
   PATIENTDETAIL_WEIGHTKGACCURACYCALC: "PatientDetail_WeightKgAccuracyCalc",

   PATIENTDETAIL_BLOODGROUP: "PatientDetail_BloodGroup", 
   PATIENTDETAIL_RHESUS: "PatientDetail_Rhesus",
   PATIENTDETAIL_BLOODGROUPRHESUSCALC: "PatientDetail_BloodGroupRhesusCalc",

   ABDOMINALRETRIEVALTEAMCALLOUTS: "AbdominalRetrievalTeamCallouts|",
   ABDOMINALRETRIEVALTEAMCALLOUTSCSV: "AbdominalRetrievalTeamCalloutsCsv",

   CARDIOTHORACICRETRIEVALTEAMCALLOUTS: "CardiothoracicRetrievalTeamCallouts|",
   CARDIOTHORACICRETRIEVALTEAMCALLOUTSCSV: "CardiothoracicRetrievalTeamCalloutsCsv",

   RESOURCEMETADATAS: "ResourceMetadatas|",
   RESOURCEMETADATA_CATEGORY: "ResourceMetadata_Category",
   RESOURCEMETADATA_ORGANCATEGORY: "ResourceMetadata_OrganCategory",
   RESOURCEMETADATA_ORGANLOCATION: "ResourceMetadata_OrganLocation",

   RESOURCEREFNUMBERS: "ResourceRefNumbers|",

   HLASAMPLEID: "HlaSampleId",
   HLADATA_LABNAME: "HlaData_LabName",
   HLADATA_COMMENT: "HlaData_Comment",
   HLARESULTS: "HlaResults|",

   REFERRALSTATUS_STATUS: "ReferralStatus_Status",
   PATIENTDETAIL_DONORINSCOTLAND: "PatientDetail_DonorInScotland",

   REFERRAL_CREATEDDATE: "CreatedDate",

   PATIENTDEMOGRAPHIC_GENDER: "PatientDemographic_Gender",
};
