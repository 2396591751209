import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import referenceDataStore from '../../stores/ReferenceDataStore';
import { ControlFactory } from '../ControlFactory/ControlFactory';

type Props = {
   key: string;
   pageKey: string;
   updateViewState: Function;
};

export const PageView = observer((props: Props) => {
   const { ref, inView, entry } = useInView();

   const getControls = () => {
      var controls = referenceDataStore.pageLayout.getPageLayoutByPage(props.pageKey);
      return controls ? controls?.map((c, i) => <ControlFactory pageKey={props.pageKey} pageLayoutItem={c} key={i} />) : <></>;
   };

   useEffect(() => {
      props.updateViewState(props.pageKey, inView);
   }, [inView]);

   return (
      <div id={props.pageKey} className="page" ref={ref}>
         {getControls()}
      </div>
   );
});
