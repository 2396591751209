import { makeObservable, observable, computed, action, flow } from "mobx"
import lastViewedApi from "../api/lastViewedApi";
import { ReferralFromSearch } from "../api/Models/ReferralFromSearch";
import { StoreLoadingStateTypes } from "../enums/StoreLoadingStateTypes";

export class LastViewedStore {
    referrals: ReferralFromSearch[] = []
    state: StoreLoadingStateTypes = StoreLoadingStateTypes.Empty;
    makingApiCall: boolean = false;

    constructor() {
        makeObservable(this, {
            referrals: observable,
            state: observable,
            makingApiCall: observable,
            updateFromServer: action,
            refreshFromServer: action,
            refresh: action,
        });

        lastViewedApi.onResponseSuccess.subscribe((sender, args) => this.updateFromServer(args));
        lastViewedApi.onResponseError.subscribe(() => this.makingApiCall = false);
    }

    public refresh() {
        if (this.state === StoreLoadingStateTypes.Empty) {
            this.state = StoreLoadingStateTypes.Loading;
        }
        this.makingApiCall = true;
        lastViewedApi.getLastViewed();
    }

    updateFromServer(referrals: ReferralFromSearch[]) {
        this.referrals = referrals;
        this.makingApiCall = false;
        this.state = StoreLoadingStateTypes.Ready;
    }    

    refreshFromServer(referrals: ReferralFromSearch[]) {
        referrals.forEach(newReferralInfo => {
            var currentIndex = this.referrals.findIndex(i => i.recordId === newReferralInfo.recordId);
            if (currentIndex >= 0)
            {
                var currentReferralInfo = this.referrals[currentIndex];
                newReferralInfo.hasUpdates = currentReferralInfo.hasUpdates || newReferralInfo.currentTxpChangeListId !== currentReferralInfo.currentTxpChangeListId;
                newReferralInfo.currentTxpChangeListId = currentReferralInfo.currentTxpChangeListId;  
                this.referrals[currentIndex] = newReferralInfo;
            }
        });
    }
}

const lastViewedStore = new LastViewedStore();
export default lastViewedStore;
