import { ReactComponent as Paperclip } from "../../assets/icons/paperclip.svg";

export const AttachmentsEmptyMessage = () => {
    return <div className="attachments-empty-message">
    <div className="icon">
        <Paperclip />
    </div>
    <div className="records-list">
        <h3>There aren't any attachments yet...</h3>
        <p>Once files, images and videos have been uploaded to the Donor Record they’ll appear here.</p>
    </div>
</div>
}