import clsx from "clsx";
import { ReactNode } from "react"
import { ReactComponent as Spinner } from '../../../assets/icons/spinner.svg';

interface Props {
    icon: ReactNode;
    disabled: boolean;
    submit?: boolean;
    isLoading?: boolean;
}

export const ButtonWithIcon = ({ icon, disabled, submit, isLoading }: Props) => {
    return <button className={clsx('button-cta', disabled && 'disabled')} value={submit ? "Submit" : ""} disabled={disabled}>
        {isLoading ? <Spinner className="spin loading-spinner" /> :
            <>
                <span className="icon">{icon}</span>
                <span className="text">Find Donor Record</span>
            </>}
    </button>
}