import { PageItemModel } from "../../../stores/PageLayoutStore";

interface Props {
    pageItem: PageItemModel;
    children: JSX.Element[];
}

export const GroupControl = (props: Props) => {
    let title = props.pageItem.text || "";

    if (!props.pageItem!.children) {
        return <div>group box {props.pageItem.text || ""} has not child items</div>;
    }

    return (<div className="group-control">
                {title !== "" && (
                    <div className="group-legend">
                        <legend>{title}</legend>
                    </div>
                )}
                <fieldset>{props.children}</fieldset>
            </div>);
}