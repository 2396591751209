import { useEffect, useRef, useState } from 'react';
import detailsApi from '../../api/detailsApi';
import { Header } from '../../components/Header/Header';
import referenceDataStore from '../../stores/ReferenceDataStore';
import referralStore from '../../stores/ReferralStore';
import { Navigate, useLocation } from 'react-router-dom';
import { BreadcrumbNav } from '../../components/Header/BreadcrumbNav/BreadcrumbNav';
import { Breadcrumb } from '../../components/Header/Breadcrumb/Breadcrumb';
import { PageView } from '../../components/PageView/PageView';
import { observer } from 'mobx-react-lite';
import userStore from '../../stores/UserStore';
import { StoreLoadingStateTypes } from '../../enums/StoreLoadingStateTypes';
import SideMenu from '../../components/Navigation/SideMenu/SideMenu';
import clsx from 'clsx';
import { observable } from 'mobx';
import { DonorDetailsHeader } from '../../components/DonorDetailsHeader/DonorDetailsHeader';
import { ChangeField, ChangeFieldHelper } from '../../types/ChangeField';
import { parseDonorStatus } from '../../utils/parseDonorStatus';
import { parseBloodGroupForHeader } from '../../utils/parseBloodGroupForHeader';
import { StickyHeader } from '../../components/StickyHeader/StickyHeader';
import { StringUtil } from '../../utils/stringUtil';
import { referralHub } from '../../hub/ReferralHub';
import FullPageLoadingSpinner from '../../components/Loader/FullPageLoadingSpinner';
import { getMenuItemFromTitle } from '../../components/Navigation/menuItems';
import clinicallySignificantStore from '../../stores/ClinicallySignificantStore';
import { SettingConstants } from '../../constants/settingConstants';
import { SideMenuProvider } from '../../context/SideMenuContext';
import { Key } from 'ts-key-enum';
import { ReferralStatus } from '../../enums/ReferralStatus';
import attachmentFileStore from '../../stores/AttachmentFileStore';

let elementIdAtFullScreen: string | undefined;

export const Referral = observer(() => {
   const preventDonorHeaderCondensing = useRef(false);
   const [userInfo] = useState(() => observable({ userStore }));
   const location = useLocation();
   const [contentScrolling, setContentScrolling] = useState(false);
   const [refresh, setRefresh] = useState(false);
   const [activeTab, setActiveTab] = useState();
   const [donorHeaderIsCondensed, setDonorHeaderIsCondensed] = useState(false);
   const [acknowledgeCondensed, setAcknowledgeCondensed] = useState(false);
   const [showPrintButton, setShowPrintButton] = useState(false);

   const donorRecordRef = useRef<HTMLDivElement>(null);
   const keyboardScrollAmount = 30;

   useEffect(() => {
      clinicallySignificantStore.setShowClinicallySignificantOnly(false);
      clinicallySignificantStore.onChange.subscribe(onClinicallySignificantStoreChange);
      document.addEventListener("fullscreenchange", handleFullScreen);
      document.addEventListener("webkitfullscreenchange", handleFullScreen);
      return () => {
          clinicallySignificantStore.onChange.unsubscribe(onClinicallySignificantStoreChange);
          document.removeEventListener("fullscreenchange", handleFullScreen);
          document.removeEventListener("webkitfullscreenchange", handleFullScreen);
      }
   }, []);

   useEffect(() => {
      if (
         !referralStore.referralId &&
         location.pathname.length === 46 &&
         userStore.isLoggedIn &&
         referralStore.state === StoreLoadingStateTypes.Empty
      ) {
         const referralId = location.pathname.replace('/Referral/', '');

         referralStore.loading(referralId);
         refreshReferral(true);
      }
   }, [userStore.isLoggedIn, location.pathname]);

   useEffect(() => {
      if (referenceDataStore.isLoaded && referralStore.state === StoreLoadingStateTypes.Ready) {
         referralHub.onConnect.subscribe(onHubConnected);
         referralHub.startAsync();
         referralHub.onNewUpdates(onNewUpdates);
         referralHub.onAcknowledgedUpdates(onAcknowledgedUpdates);
         
         updatePrintButtonState();

         setRefresh(prev => !prev);

         preventDonorHeaderCondensing.current = true; // prevent condensing on initial load

         return () => {
            referralHub.offNewUpdates(onNewUpdates);
            referralHub.offAcknowledgedUpdates(onAcknowledgedUpdates);
            referralHub.onConnect.unsubscribe(onHubConnected);
            referralHub.stopAsync();
          }         
      }
   }, [referralStore.state, referenceDataStore.isLoaded]);

   const updatePrintButtonState = () => {
      var referralStatus = referralStore.getReferralStatus()
      setShowPrintButton(referralStatus === ReferralStatus.Complete || referralStatus === ReferralStatus.NonProceeding);
   }

   const onNewUpdates = (referralId: string, txpChangeListId: string | undefined, currentChangeListId: string | undefined) => {
      if (referralStore.referralId && referralId === referralStore.referralId && currentChangeListId !== referralStore.latestChangeListId) {
         // To prevent the server being spammed, when new update arrives, 
         // each client requests an update based on a random delay normally max of 30secs
         var maxDelayToGetUpdatesInMins = referenceDataStore.settings.getSettingAsMinsFromTimeStamp(SettingConstants.WEB_CLIENT_DELAY_TO_RECIEVE_REFERRAL_UPDATES) || 0;
         var delayToGetUpdatesInMillisecs = Math.random() * maxDelayToGetUpdatesInMins * 60 * 1000;
         setTimeout(() => refreshReferral(false), delayToGetUpdatesInMillisecs);
      }                                                                 
   }

   const onAcknowledgedUpdates = (referralId: string, txpChangeListId: string) => {
      if (referralStore.referralId === referralId) {
         if (referralStore.currentTxpChangeListId === txpChangeListId) {
            referralStore.clearUpdatedFields(txpChangeListId);
         }
         else {
            refreshReferral(false);
         }
      }
   }

   const refreshReferral = (firstOpen: boolean) => {
      if (referralStore.referralId) {
         detailsApi
            .getDetails(referralStore.referralId, firstOpen)
            .then(data => {
               if (data.referralId === referralStore.referralId) {
                  referralStore.updateFromServer(data.referralId, 
                                                data.lastViewedTxpChangeListId,
                                                data.firstViewedTxpChangeListId,
                                                data.fields, 
                                                data.changeListsIds);
                  updatePrintButtonState();
               } else {
                  referralStore.clear();
                  attachmentFileStore.clear();
                  throw Error(`Wrong referral, should be referral ${referralStore.referralId}, data is for referral ${data.referralId}`);
               }
            })
            .catch(err => console.error(err));      
      }
   }

   const onHubConnected = (): void => {
      if (referralStore.referralId) {
         referralHub.OpenedReferral(referralStore.referralId, referralStore.latestChangeListId);
      }
   }
   
   const onClinicallySignificantStoreChange = () => setRefresh(prev => !prev);

   const getPages = (): JSX.Element[] => {
      return referenceDataStore.pageLayout
         .getOrderedPageKeys()
         .map((p: string) => {
            const menuItem = getMenuItemFromTitle(p);
            return (
               <PageView
                  pageKey={p}
                  key={p}
                  updateViewState={(pageId: string, inView: boolean) => referenceDataStore.pageLayout.setPageInView(pageId, inView)}
               />
            )
         });
   };

   if (userStore.state === StoreLoadingStateTypes.Empty && !userStore.isLoggedIn) {
      return <Navigate to="/login" />;
   }

   const breadcrumb = <Breadcrumb previousLinkRoute="/search" previousLinkText="Back to Search" />;

   const mapReferralProperties = (referralFields: Record<string, ChangeField>) => {
      const bloodGroupDisplay = ChangeFieldHelper.getDisplayValue('PatientDetail_BloodGroup', referralFields['PatientDetail_BloodGroup']?.toValue);
      const bloodDisplay = parseBloodGroupForHeader(bloodGroupDisplay, referralFields['PatientDetail_Rhesus']?.toValue);
      const gender = ChangeFieldHelper.getDisplayValue('PatientDemographic_Gender', referralFields['PatientDemographic_Gender']?.toValue);
      return {
         sex: referralFields['PatientDemographic_Gender']?.disabled ? '-' : StringUtil.capitalizeEachWord(gender),
         dob: referralFields['PatientDemographic_BirthDate']?.disabled
            ? '-'
            : ChangeFieldHelper.getDisplayValue('PatientDemographic_BirthDate', referralFields['PatientDemographic_BirthDate'].toValue),
         age: referralFields['PatientDemographic_AgeCalc']?.disabled
            ? '-'
            : ChangeFieldHelper.getDisplayValue('PatientDemographic_AgeCalc', referralFields['PatientDemographic_AgeCalc']?.toValue),
         donorType: referralFields['PatientDetail_DonorType']?.disabled
            ? '-'
            : ChangeFieldHelper.getDisplayValue('PatientDetail_DonorType', referralFields['PatientDetail_DonorType']?.toValue),
         bloodGroup: referralFields['PatientDetail_BloodGroup']?.disabled ? '-' : bloodDisplay,
         odtNumber: referralFields['NtxdDonorId']?.disabled
            ? '-'
            : ChangeFieldHelper.getDisplayValue('NtxdDonorId', referralFields['NtxdDonorId']?.toValue),
         hospital: referralFields['Admission_DonatingHospital']?.disabled
            ? '-'
            : ChangeFieldHelper.getDisplayValue('Admission_DonatingHospital', referralFields['Admission_DonatingHospital']?.toValue),
         causeOfDeath: referralFields['Admission_CauseOfDeath']?.disabled
            ? '-'
            : ChangeFieldHelper.getDisplayValue('Admission_CauseOfDeath', referralFields['Admission_CauseOfDeath']?.toValue),
         donorStatus: referralFields['ReferralStatus_Status']?.disabled ? '-' : parseDonorStatus(referralFields['ReferralStatus_Status'].toValue),
         weightKg: referralFields['PatientDetail_WeightKg']?.disabled
            ? '-'
            : ChangeFieldHelper.getDisplayValue('PatientDetail_WeightKg', referralFields['PatientDetail_WeightKg']?.toValue),
         weightAcc: referralFields['PatientDetail_WeightAcc']?.disabled
            ? '-'
            : ChangeFieldHelper.getDisplayValue('PatientDetail_WeightAccuracy', referralFields['PatientDetail_WeightAccuracy']?.toValue)
      };
   };

   const handleContentScroll = () => {
      setContentScrolling(true);
      if (!preventDonorHeaderCondensing.current) {
         setDonorHeaderIsCondensed(true);
         setAcknowledgeCondensed(true);
      } else {
         // Clear flag to prevent the header from condensing (debounced)
         setTimeout(() => { preventDonorHeaderCondensing.current = false; }, 500);
      } 
   };

   const sliderHandler = (value: boolean) => {
      clinicallySignificantStore.setShowClinicallySignificantOnly(value);
   }
   
   const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === Key.ArrowDown) {
         donorRecordRef.current?.scrollBy({top: keyboardScrollAmount});
      }else if (event.key === Key.ArrowUp) {
         donorRecordRef.current?.scrollBy({top: -keyboardScrollAmount});
      }
   }

   // TXP-863 Ensure navigation returns to same point in record after exiting full screen mode
   const handleFullScreen = (evn: Event) => {
      if (!!document.fullscreenElement) {
         // Get the first parent with an id
         let elem: Element | null = document.fullscreenElement;
         while (elem != null && !elem.id) {
            elem = elem.parentElement;
         }

         // Track the element that was used to display in full screen
         elementIdAtFullScreen = elem ? elem.id : undefined;
      } else if (!!elementIdAtFullScreen) {
         // Get the element that was last used to display in full screen
         const elem = document.getElementById(elementIdAtFullScreen);
         elementIdAtFullScreen = undefined;

         // If element exists ensure it is in view
         if (!!elem) {
            elem.scrollIntoView({ behavior: 'smooth' });
         }
      }
   }    

   useEffect(() => {
      document.addEventListener('keydown', handleKeydown);
      return () => {
         document.removeEventListener('keydown', handleKeydown);
      }
   });   

   return (
      <>
         <div className="referral">
            <Header showActivityLogIcon leftContentMobile={breadcrumb} activeTab={activeTab} setActiveTab={setActiveTab} showDownloadIcon={showPrintButton} />
            <BreadcrumbNav hideOnMobile previousLinkRoute="/search" previousLinkText="Search" pageTitle="Donor Record" />
            <div className={clsx('referral-content-parent', { scroll: contentScrolling })}>
               <SideMenuProvider>
                  <SideMenu contentScrolling={contentScrolling} />
               </SideMenuProvider>
               <div className="referral-content">
                  {referralStore.state === StoreLoadingStateTypes.Ready && referenceDataStore.state === StoreLoadingStateTypes.Ready && (
                     <DonorDetailsHeader
                        donorDetails={mapReferralProperties(referralStore.fields)}
                        isCondensed={donorHeaderIsCondensed}
                        setIsCondensed={setDonorHeaderIsCondensed}
                     />
                  )}
                  <StickyHeader sliderValue={clinicallySignificantStore.clinicallySignificantOnly} 
                                sliderHandler={sliderHandler} 
                                isAcknowledgeCondensed={acknowledgeCondensed}
                                setAcknowledgeCondensed={setAcknowledgeCondensed} />
                  <div className={'referral-content-inner scroll-style-1'} onScroll={handleContentScroll} ref={donorRecordRef}>
                     {getPages()}
                  </div>
               </div>
            </div>
         </div>
         <FullPageLoadingSpinner
            loading={referralStore.state === StoreLoadingStateTypes.Loading || userStore.state === StoreLoadingStateTypes.Loading}
         />
      </>
   );
});
