import { createContext, useEffect, useState } from "react";
import { throttle } from "lodash";

interface PropsProvider {
    children: React.ReactNode;
};

export const BreakpointProvider: React.FC<PropsProvider> = ({ children }) => {
    const mobileBreakpoint = 786;
    const throttleMilliseconds = 100;
    const calculateIsMobile = (innerWidth: number) => innerWidth < mobileBreakpoint;
    const [isMobile, setIsMobile] = useState<boolean>(calculateIsMobile(window.innerWidth));

    const onResize = throttle(() => setIsMobile(calculateIsMobile(window.innerWidth)), throttleMilliseconds);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, []);

    return (
        <BreakpointContext.Provider value={isMobile}>
            {children}
        </BreakpointContext.Provider>
    );
}

export const BreakpointContext = createContext(false);