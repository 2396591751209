import { makeObservable, observable, computed, action, flow } from "mobx"

export class CodeSet {
    codeSetType: string = "";
    refCode: string = "";
    value: string = "";
    
    constructor() {
        makeObservable(this, {
            value: observable,
        });
    }    
}

export type RefCodeToCodeSet = { [refCode: string]: CodeSet };

export class CodeSetStore {
    codeSets: { [codeSetType: string]: RefCodeToCodeSet } = {}   

    constructor() {
        makeObservable(this, {
            codeSets: observable,
        });
    }

    getCodeSetText(codeSetType: string, refCode: string): string | undefined {
        var codeSetTypeValues = this.codeSets[codeSetType];
        if (!codeSetTypeValues) {
            return undefined;
        }

        var codeSet = codeSetTypeValues[refCode];
        if (!codeSet) {
            return undefined;
        }

        return codeSet.value
    }

    getCodeSet(codeSetType: string): RefCodeToCodeSet | undefined {
        return this.codeSets[codeSetType];
    }

    updateFromServer(codeSets: CodeSet[]) {
        var newCodeSets: { [codeSetType: string]: RefCodeToCodeSet } = {};
        
        codeSets.forEach(c => {
            var codeSetType = newCodeSets[c.codeSetType];
            if (!codeSetType) {
                codeSetType = {};
                newCodeSets[c.codeSetType] = codeSetType;
            }

            codeSetType[c.refCode] = c;
        });

        Object.entries(newCodeSets).forEach(([key, value]) => this.codeSets[key] = value)
    }
}
