export enum ControlTypes {
    CheckBox = "CHECKBOX",
    Complex = "COMPLEX",
    Date = "DATE",
    DateTime = "DATETIME",
    DropDown = "DROPDOWN",
    PartDateTime = "PARTDATETIME",
    Switch = "SWITCH",
    Text = "TEXT",
    TextArea = "TEXTAREA",
    UnsignedNumeric = "UNSIGNEDNUMERIC",
    SignedNumeric = "SIGNEDNUMERIC"
}
