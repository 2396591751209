import * as signalR from "@microsoft/signalr";
import userStore from "../stores/UserStore";
import referenceDataStore from "../stores/ReferenceDataStore";
import { SignalDispatcher } from "strongly-typed-events";

export type HubMethodMap = { [key: string]: (...args: any[]) => any };

export default class BaseHub {
    private connection: signalR.HubConnection | undefined = undefined;
    private keepAlive: boolean = false;
    private hubUrl: string; 
    private settingName: string | undefined;
    private pollerHandler: NodeJS.Timer | undefined;
    private _onConnect = new SignalDispatcher();
    private connectPollerHandler: NodeJS.Timer | undefined;
    private manuallyPollEndPoint: ((methodMap: HubMethodMap) => void) | undefined;
    private connectionRetryAttempts: number = 0;
    protected methodMap: HubMethodMap = {};

    public get onConnect() {
        return this._onConnect.asEvent();
     }
  
    public constructor(hubUrl: string, 
                       settingName: string | undefined, 
                       manuallyPollEndPoint: ((methodMap: HubMethodMap) => void) | undefined) {
        this.hubUrl = hubUrl;
        this.settingName = settingName;
        this.manuallyPollEndPoint = manuallyPollEndPoint;
    }

    private createConnection(): signalR.HubConnection {
        var con = new signalR.HubConnectionBuilder()
                                .withUrl(this.hubUrl,  { 
                                    accessTokenFactory: () => userStore.userData.accessToken!
                                })
                                .configureLogging(signalR.LogLevel.Information)  
                                .build();
        con.onclose(async() => {
            if (this.keepAlive) {
                await this.connectionAsync();
            }
        });
        return con;        
    }

    public async startAsync(resetErrors = true) {
        if (this.keepAlive) {
            return;
        }

        this.keepAlive = true;

        if (resetErrors) {
            this.connectionRetryAttempts  = 0;
        }

        if (this.shouldUsePoller()) {
            if (!this.pollerHandler) {
                let pollerMins = referenceDataStore.settings.getSettingAsMinsFromTimeStamp(this.settingName!) || 0;
                if (!pollerMins) {
                    pollerMins = 1; // Polling due error with signalR
                }

                this.pollerHandler = setInterval(() => {
                    if (this.keepAlive) {
                        this.manuallyPollEndPoint!(this.methodMap);
                    }
                }, pollerMins! * 60000);

                this._onConnect.dispatch();
            }
        } else if (this.connection === undefined) {
            this.connection = this.createConnection();
            await this.connectionAsync();
        } else {
            await this.connectionAsync(); 
        }
    }

    private shouldUsePoller(): boolean {
        if (this.settingName === undefined || this.manuallyPollEndPoint === undefined) {
            return false;
        }

        if (this.connectionRetryAttempts > 10) {
            return true;
        }
            
        var pollerMins = referenceDataStore.settings.getSettingAsMinsFromTimeStamp(this.settingName);
        return pollerMins !== null && pollerMins >= 0;
    }

    protected on(methodName: string, newMethod: (...args: any[]) => any) {
        this.methodMap[methodName] = newMethod;
        if (this.connection) {
            this.connection.on(methodName, newMethod);
        }
    }

    protected off(methodName: string, newMethod: (...args: any[]) => any) {
        delete this.methodMap[methodName];
        if (this.connection) {
            this.connection.off(methodName, newMethod);
        }
    }

    protected invoke(methodName: string, ...args: any[]): Promise<any> {
        if (this.connection) {
            return this.connection.invoke(methodName, ...args);
        }
        return new Promise(_ => { return undefined; });
    }

    public async stopAsync() {
        this.keepAlive = false;

        // Only stop if already connected, else if stop during Connecting state (like on 2nd load of component)
        // Then causes issues with 2nd connect attempt
        if (this.connection && this.connection.state === signalR.HubConnectionState.Connected) {
            await this.connection.stop();
        }

        clearInterval(this.pollerHandler);
        this.pollerHandler = undefined;

        clearInterval(this.connectPollerHandler);
        this.connectPollerHandler = undefined;
    }

    private async connectionAsync() {
        try {
            if (this.connection && 
                (this.connection.state === signalR.HubConnectionState.Disconnected || this.connection.state === signalR.HubConnectionState.Disconnecting) &&
                this.keepAlive) {
                await this.connection.start();

                // Start poller, to send event when connection is established (can't find this on signalR for initial connection)
                this.connectPollerHandler = setInterval(() => {
                    if (this.connection && this.connection.state === signalR.HubConnectionState.Connected) {
                        this._onConnect.dispatchAsync();
                        clearInterval(this.connectPollerHandler);
                        this.connectPollerHandler = undefined;
                    }
                }, 1000);
            }
        } catch (err) {
            this.connectionRetryAttempts++;       
            if (this.shouldUsePoller()) { 
                console.warn("Failed to connect to hub, reverting to manual polling");
                await this.startAsync(false);
            } else {
                setTimeout(() => this.connectionAsync(), 20000);
            }
        }
    }
}
