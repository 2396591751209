import { observable } from 'mobx';
import { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Dialog from '../../components/Dialog/Dialog';
import { Header } from '../../components/Header/Header';
import Button from '../../components/Inputs/Button/Button';
import { NoRecordsMessage } from '../../components/NoRecordsMessage/NoRecordsMessage';
import { RecordSearchBar } from '../../components/RecordSearchBar/RecordSearchBar';
import userStore from '../../stores/UserStore';
import { ReactComponent as InfoOutlineIcon } from '../../assets/icons/infoOutline.svg';
import { ReactComponent as InfoWarning } from '../../assets/icons/warning.svg';
import { DonorDetailsContent } from '../../components/DonorDetails/DonorDetailsContent';
import { DonorDetails } from '../../types/DonorDetails';
import referralStore from '../../stores/ReferralStore';
import detailsApi from '../../api/detailsApi';
import referenceDataApi from '../../api/referenceDataApi';
import referenceDataStore from '../../stores/ReferenceDataStore';
import lastViewedStore from '../../stores/LastViewedStore';
import { LastViewedReferral } from '../../components/LastViewedReferral/LastViewedReferral';
import { observer } from 'mobx-react-lite';
import { HeaderTab } from '../../enums/HeaderTab';
import { StoreLoadingStateTypes } from '../../enums/StoreLoadingStateTypes';
import FullPageLoadingSpinner from '../../components/Loader/FullPageLoadingSpinner';
import { BreakpointContext } from '../../context/BreakpointContext';
import { lastViewedHub } from '../../hub/LastViewedHub';
import { ReferralFromSearch } from '../../api/Models/ReferralFromSearch';
import { Key } from 'ts-key-enum';
import attachmentFileStore from '../../stores/AttachmentFileStore';
import { SettingConstants } from '../../constants/settingConstants';
import { checkForServiceWorkUpdates } from '../../serviceWorkerRegistration';

export const SearchPage = observer(() => {
   const [userInfo] = useState(() => observable({ userStore }));
   const [openNotFoundDialog, setOpenNotFoundDialog] = useState(false);
   const [openDonorDetailsDialog, setOpenDonorDetailsDialog] = useState(false);
   const [openPastCutOffDateDialog, setOpenPastCutOffDateDialog] = useState(false);
   const [donorDetails, setDonorDetails] = useState<DonorDetails>();
   const [isLoading, setIsLoading] = useState(false);
   const [showSearch, setShowSearch] = useState(true);
   const [searchIsOverlay, setSearchIsOverlay] = useState(false);
   const [activeTab, setActiveTab] = useState<HeaderTab | undefined>(HeaderTab.search);
   const [failedToOpenDonor, setFailedToOpenDonor] = useState(false);
   const [openInvalidDobDialog, setOpenInvalidDobDialog] = useState(false);
   const [openInvalidOdtNumber, setOpenInvalidOdtNumber] = useState(false);
   const isMobile = useContext(BreakpointContext);
   const {
      userStore: { isLoggedIn, state }
   } = userInfo;
   const navigate = useNavigate();

   useEffect(() => {
      checkForServiceWorkUpdates();

      sessionStorage.removeItem('openMenuIds');
      sessionStorage.removeItem('persistedActiveId');
      if (!lastViewedStore.makingApiCall && userStore.isLoggedIn) {
         lastViewedStore.refresh();
      }

      referralStore.clear();
      attachmentFileStore.clear();
   }, [userStore.isLoggedIn]);

   useEffect(() => {
      if (referenceDataStore.isLoaded) {
         lastViewedHub.startAsync();
         lastViewedHub.onNewUpdates(onNewUpdates);

         return () => {
            lastViewedHub.offNewUpdates(onNewUpdates);
            lastViewedHub.stopAsync();
         }
      } else if (!referenceDataStore.isLoading && !referenceDataStore.isLoaded) {
         referenceDataStore.setLoading();
         referenceDataApi
         .getReferenceData(referenceDataStore.lastFieldVersion, referenceDataStore.lastCodeSetVersion, referenceDataStore.lastPageLayoutVersion)
         .then(refData => referenceDataStore.updateFromServer(refData));
      }
   }, [referenceDataStore.isLoaded]);

   const handleCloseNotFoundDialog = () => setOpenNotFoundDialog(false);
   const handleClosePastCutOffDateDialog = () => setOpenPastCutOffDateDialog(false)
   const handleCloseDonorDetailsDialog = () => setOpenDonorDetailsDialog(false);
   const handleCloseInvalidDobDialog = () => setOpenInvalidDobDialog(false);
   const handleCloseInvalidOdtNumber = () => setOpenInvalidOdtNumber(false);
   const handleConfirmDonorDetails = async () => {
      setIsLoading(true);
      if (donorDetails && donorDetails.currentChangeListId) {
         openReferral(donorDetails.referralId);
      }
   };

   const openReferral = async (referralId: string) => {
      referralStore.loading(referralId);
      try {
         const referralDetails = await detailsApi.getDetails(referralId, true);
         referralStore.updateFromServer(referralDetails.referralId,
            referralDetails.lastViewedTxpChangeListId,
            referralDetails.firstViewedTxpChangeListId,
            referralDetails.fields,
            referralDetails.changeListsIds);
         navigate(`/Referral/${referralDetails.referralId}`);

         referenceDataApi
            .getReferenceData(referenceDataStore.lastFieldVersion, referenceDataStore.lastCodeSetVersion, referenceDataStore.lastPageLayoutVersion)
            .then(refData => referenceDataStore.updateFromServer(refData));
      } catch (e) {
         setIsLoading(false);
         setOpenDonorDetailsDialog(false);
         setFailedToOpenDonor(true);
      }
   };

   const onNewUpdates = (newUpdates: ReferralFromSearch[]): void => {
      lastViewedStore.refreshFromServer(newUpdates);
   }

   const handleHideSearch = () => {
      if (isMobile && lastViewedStore.referrals.length > 0) {
         setShowSearch(false);
         setActiveTab(undefined);
      }
   };

   const handleToggleSearch = () => {
      setActiveTab(showSearch ? undefined : HeaderTab.search);
      setShowSearch(prev => !prev);
      setSearchIsOverlay(true);
   };

   const handleCloseFailureDialog = () => setFailedToOpenDonor(false);

   const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === Key.Escape) {
         handleHideSearch();
      }
   }

   useEffect(() => {
      document.addEventListener('keydown', handleKeydown)
      return () => {
         document.removeEventListener('keydown', handleKeydown);
      }
   })

   if (!isLoggedIn && state === StoreLoadingStateTypes.Ready) return <Navigate to="/login" />;
   return (
      <>
         <Header showAlerts mobileTabs activeTab={activeTab} setActiveTab={setActiveTab} searchButtonHandler={handleToggleSearch} />
         <div className="search-page">
            <RecordSearchBar
               setOpenPastCutOffDateDialog={setOpenPastCutOffDateDialog}
               setOpenNotFoundDialog={setOpenNotFoundDialog}
               setOpenDonorDetails={setOpenDonorDetailsDialog}
               setDonorDetails={setDonorDetails}
               setFailedToOpenDonor={setFailedToOpenDonor}
               hidden={!showSearch}
               isOverlay={searchIsOverlay}
               invalidDob={setOpenInvalidDobDialog}
               invalidOdtNumber={setOpenInvalidOdtNumber}
            />
            <div className="title-parent">
               <h2 className="title">My Offers</h2>
            </div>
            <hr />
            <div className="content-parent">
               <div className="results scroll-style-1" onScroll={handleHideSearch}>
                  {lastViewedStore.referrals.length === 0 ? (
                     <NoRecordsMessage />
                  ) : (
                     <>
                        {lastViewedStore.referrals.map(r => (
                           <LastViewedReferral
                              key={r.recordId}
                              referral={r}
                              setOpenDonorDetails={setOpenDonorDetailsDialog}
                              setDonorDetails={setDonorDetails}
                           />
                        ))}
                     </>
                  )}
               </div>
            </div>

            {donorDetails && openDonorDetailsDialog && (
               <Dialog maxWidth="sm" className="donor-details-dialog" onClose={() => setOpenDonorDetailsDialog(false)}>
                  <h2>Confirm the donor details</h2>
                  <DonorDetailsContent
                     odtNumber={'' + donorDetails.odtNumber}
                     dob={donorDetails.dob}
                     hospital={donorDetails.hospital}
                     donorType={donorDetails.donorType}
                     sex={donorDetails.sex}
                     createdDate={donorDetails.createdDate}
                  />
                  <div className="dialog-text">Is this the correct donor record?</div>
                  <div className="buttons">
                     <Button text="No" className="" onClick={handleCloseDonorDetailsDialog} />
                     <Button text="Yes" className="" onClick={handleConfirmDonorDetails} isLoading={isLoading} />
                  </div>
               </Dialog>
            )}

            {openPastCutOffDateDialog && <Dialog onClose={handleClosePastCutOffDateDialog} maxWidth="sm" className="record-not-found-dialog">
               <InfoOutlineIcon />
               <div>
                  <h2>Donor record created prior to {referenceDataStore.settings.getSettingAsDate(SettingConstants.CUTOFF_DATE_REFERRAL)!.format("DD/MM/YYYY")}</h2>
                  <div className="dialog-text" style={{ width: "100%" }}>To view historic records, please refer to NHSBT for guidance</div>               
               </div>
               <Button text="Try again" className="" onClick={handleClosePastCutOffDateDialog} />
            </Dialog>}

            {openNotFoundDialog && <Dialog onClose={handleCloseNotFoundDialog} maxWidth="sm" className="record-not-found-dialog">
               <InfoOutlineIcon />
               <div>
                  <h2>Donor record not found</h2>
                  <div className="dialog-text">Please check the details and try again</div>
               </div>
               <Button text="Try again" className="" onClick={handleCloseNotFoundDialog} />
            </Dialog>}

            {openInvalidDobDialog && <Dialog onClose={handleCloseInvalidDobDialog} maxWidth="sm" className="record-not-found-dialog">
               <InfoWarning />
               <div>
                  <h2>Invalid Date of Birth</h2>
                  <div className="dialog-text">Date of birth should be in format DD/MM/YYYY<br/><br/>e.g. 19/5/2022</div>
                  <br/>
               </div>
               <Button text="Try again" className="" onClick={handleCloseInvalidDobDialog} />
            </Dialog>}

            {openInvalidOdtNumber && <Dialog onClose={handleCloseInvalidOdtNumber} maxWidth="sm" className="record-not-found-dialog">
               <InfoWarning />
               <div>
                  <h2>Error loading record</h2>
                  <div className="dialog-text">Invalid ODT Number</div>
               </div>
               <Button text="Try again" className="" onClick={handleCloseInvalidOdtNumber} />
            </Dialog>}

            {/* dialog for failed loading state */}
            {failedToOpenDonor && <Dialog onClose={handleCloseFailureDialog} maxWidth="sm" className="record-not-found-dialog">
               <InfoWarning />
               <div>
                  <h2>Error loading record</h2>
                  <div className="dialog-text">Please try again</div>
               </div>
               <Button text="Try again" className="" onClick={handleCloseFailureDialog} />
            </Dialog>}
            <FullPageLoadingSpinner loading={state === StoreLoadingStateTypes.Loading} />
         </div>
      </>
   );
});
