import { useContext, useState } from 'react';
import Dialog from '../Dialog/Dialog';
import { ReactComponent as InfoOutlineIcon } from '../../assets/icons/infoOutline.svg';
import Button from '../Inputs/Button/Button';
import acknowledgeUpdateApi from '../../api/acknowledgeUpdateApi';
import referralStore from '../../stores/ReferralStore';
import clsx from 'clsx';
import { BreakpointContext } from '../../context/BreakpointContext';

type NotificationBannerProps = {
   count: number;
   isCondensed: boolean;
   setCondensed: React.Dispatch<React.SetStateAction<boolean>>;
};

function NotificationBanner({ count, isCondensed, setCondensed }: NotificationBannerProps) {
   const [isBannerVisible] = useState<boolean>(count > 0 ? true : false);
   const [openDialog, setOpenDialog] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   const handleAcknowledement = (referralId: string, currentTxpChangeListId: string) => {
      setIsLoading(true);
      acknowledgeUpdateApi
         .updateAcknowledgedChanges(referralId, currentTxpChangeListId)
         .then(response => {
            setIsLoading(false);
            referralStore.clearUpdatedFields(currentTxpChangeListId);
            setOpenDialog(false);
         })
         .catch(error => {
            setIsLoading(false);
            setOpenDialog(false);
         });
   };

   const handleCloseDialog = () => setOpenDialog(false);
   const isMobile = useContext(BreakpointContext);

   const notificationBannerContents = () => <>
      <div className="content">
         <span className="count">{count}</span>
         <span className="text">
            <span className="bold-text">{count > 1 ? 'Changes have' : 'Change has'} been made to donor record.</span>{' '}
            <span className={clsx('review-changes', { hide: isCondensed })}>Please review the changes.</span>
         </span>
      </div>
      <button className={clsx('acknowledge-btn', { hide: isCondensed })} onClick={() => setOpenDialog(true)}>
         Acknowledge all
      </button>
   </>

   return isBannerVisible ? (
      <>
         {isMobile ?
            <button
               className={clsx('notification-banner', { expand: !isCondensed })}
               onClick={e => {
                  e.stopPropagation();
                  setCondensed(prev => !prev);
               }}
            >
               {notificationBannerContents()}
            </button>
            : <div className={clsx('notification-banner', { expand: !isCondensed })}>
               {notificationBannerContents()}
            </div>}
         {openDialog && <Dialog onClose={handleCloseDialog}>
            <div className="banner-dialog-content">
               <InfoOutlineIcon />
               <div>
                  <h2>Are you sure you want to acknowledge all updates?</h2>
                  <p>This permanently removes all the yellow update indicators in this record.</p>
                  <p>Any acknowledged updates can be referred to in the Activity Log</p>
               </div>
               <div className="banner-dialog-btns">
                  <Button text="No, do not acknowledge" className="btn dialog-btn" onClick={handleCloseDialog} />
                  <Button
                     text="Yes, acknowledge"
                     className=" btn dialog-btn"
                     onClick={() => handleAcknowledement(referralStore.referralId!, referralStore.latestChangeListId || "")}
                     isLoading={isLoading}
                  />
               </div>
            </div>
         </Dialog>}
      </>
   ) : (
      <></>
   );
}

export default NotificationBanner;
