import { HeaderButton } from "../HeaderButton/headerButton";
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as Spinner } from '../../../assets/icons/spinner.svg';
import { ReactComponent as InfoWarning } from '../../../assets/icons//warning.svg';
import referralStore from "../../../stores/ReferralStore";
import reportApi from "../../../api/reportApi";
import { reportNamesConstants } from "../../../constants/reportNameConstants";
import attachmentDownload from "../../../api/attachmentDownload";
import { useEffect, useState } from "react";
import Dialog from "../../Dialog/Dialog";
import Button from "../../Inputs/Button/Button";

interface Props {
    tab?: boolean;
}

export const PrintButton = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    const onPrintError = () => {
        setLoading(false);
        setShowError(true);
    }

    useEffect(() => {
        reportApi.onResponseError.subscribe(onPrintError);
        attachmentDownload.onResponseError.subscribe(onPrintError);

        return () => {
            reportApi.onResponseError.unsubscribe(onPrintError);
            attachmentDownload.onResponseError.unsubscribe(onPrintError);
        };
    }, []);

    const handlePrint = () => {
        if (!referralStore.referralId || loading) {
            return;
         }

         setLoading(true);
   

         reportApi.generate(reportNamesConstants.TXP_CDD_MASH_RETRIEVAL_INFO, 
                            referralStore.referralId, 
                            Object.values(referralStore.fields))
                  .then(details => {
                    attachmentDownload.download(details!.url, details!.filename);
                    setLoading(false);
                  });   
    }

    var icon = loading ? <Spinner className="spin loading-spinner" /> : <DownloadIcon />;

    return <span className='print'>
        {<HeaderButton text="Download Record" handler={handlePrint} icon={icon} tab={props.tab} active={!loading} />}
        {showError &&
        <Dialog onClose={() => setShowError(false)} maxWidth="md">
            <div className="dialog-content">
               <InfoWarning />
               <div>
                  <h2>Error downloading record</h2>
                  <p>Please try again</p>
               </div>
               <Button text="OK" className=" btn dialog-btn" onClick={() => setShowError(false)} />
            </div>
         </Dialog>}
    </span>
};