import attachmentFileStore from "../../../stores/AttachmentFileStore";

interface Props {
    resourceBatchId: string,
    fileId?: string,
}

/**
 * Uses an iframe to render the html page
 */
export const HtmlViewer = (props: Props) => {
    var file = attachmentFileStore.getFileInfo(props.resourceBatchId, props.fileId);
    const decoder = new TextDecoder();
    return <iframe className="html-viewer-container" title={file?.filename} srcDoc={decoder.decode(new Uint8Array(file!.data!))} />;
}
