import { ResourcesUrlResponseModel } from "./Models/ResourcesUrlResponsModel";
import { baseApi } from "./baseApi";

 
 export class AttachmentUrlApi extends baseApi {
    async getUrls(recordId: string, resourceId: string, fileIds: string[], callbackId?: string): Promise<ResourcesUrlResponseModel[] | undefined> {
        var fileIdParam = fileIds.map(fileId => `&fileIds=${fileId}`).join('');
        var url = `${process.env.REACT_APP_TRANSPLANTPATH_API_BASEURL}/api/Referral/Resources?ReferralId=${recordId}&resourceId=${resourceId}${fileIdParam}`;

        return await super.get<ResourcesUrlResponseModel[]>(url, undefined, callbackId);
    } 
 }

const attachmentUrlApi = new AttachmentUrlApi();
export default attachmentUrlApi;