import { useState, useEffect, useRef } from 'react';
import attachmentFileStore from "../../../stores/AttachmentFileStore";
import referralStore from "../../../stores/ReferralStore";
import { Attachment } from '../../../types/Attachment';
import { fieldNames } from '../../../constants/fieldNameConstants';
import { isIOS, isMobile } from "react-device-detect";

interface Props {
    resourceBatchId: string,
    fileId?: string,
}

/**
 * Uses an video to render the video
 */
export const VideoViewer = (props: Props) => {
    const [imageUrl, setImageUrl] = useState<string>();
    const videoElementRef = useRef<HTMLVideoElement>(null);

    const fileInfo = attachmentFileStore.getFileInfo(props.resourceBatchId, props.fileId);
    const autoPlay = isIOS && isMobile;

    useEffect(() => {
        var attachment = JSON.parse(referralStore.getField(fieldNames.RESOURCEMETADATAS + props.resourceBatchId)!.toValue!) as Attachment;
        const blob = new Blob([fileInfo!.data!], { type: 'video/mp4' });
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
        
        if (videoElementRef?.current && autoPlay) {
            setTimeout(() => videoElementRef.current!.pause(), 250);
        }        
    }, []);    

    const handleOnContextMenu = (event: React.MouseEvent<HTMLVideoElement>) => {
        if (event.button === 2) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const handleOnClick = (event: React.MouseEvent<HTMLVideoElement>) => {
        if (videoElementRef?.current && videoElementRef.current.requestFullscreen) {
            videoElementRef.current.requestFullscreen();
        }        
    }

    if (isIOS) {
            // eslint-disable-next-line jsx-a11y/media-has-caption
            return <video className='video-viewer-container' 
                    controls 
                    controlsList='nodownload' 
                    disablePictureInPicture={true}
                    onClick={handleOnClick}
                    onContextMenu={handleOnContextMenu} 
                    ref={videoElementRef} 
                    preload='none'
                    autoPlay={autoPlay} playsInline>
                    <source src={imageUrl} type="video/mp4" />
                </video>;
    } else {
            // eslint-disable-next-line jsx-a11y/media-has-caption
            return <video src={imageUrl}
                          className='video-viewer-container' 
                          controls 
                          controlsList='nodownload' 
                          disablePictureInPicture={true}
                          onClick={handleOnClick}
                          onContextMenu={handleOnContextMenu} 
                          ref={videoElementRef} />;
    }
}