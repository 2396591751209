import { Link } from 'react-router-dom';

const CookieBanner = ({ onAcceptCookie, onRejectCookie }: { onAcceptCookie: () => void, onRejectCookie: () => void }) => {
   return (
      <div className="overlay">
         <div className="cookie-banner">
            <div className="cookie-content">
               <h3 className="cookie-title">Cookies on Transplant Path</h3>
               <div className="cookie-text">
                  Our site uses cookies for secure authentication and tracking activities for compliance & security. To comply with regulations we track user 
                  activity within TransplantPath, but this information is stored securely, and not shared with 3rd Parties beyond what is defined in our 
                  cookie policy.
               </div>
               <div className="cookie-text">We'd also like to use analytics cookies, to help improve the application.</div>
               <Link to="/privacy-cookie">Read more about cookies and privacy</Link>
            </div>
            <div className="cookie-btn-container">
               <button className="cookie-btn" onClick={onAcceptCookie}><div className="btn-content">I'm OK with analytics cookies</div></button>
               <button className="cookie-btn" onClick={onRejectCookie}><div className="btn-content">Do not use analytics cookies</div></button>
            </div>
         </div>
      </div>
   );
};

export default CookieBanner;
