import pako from 'pako';
import { reportNamesConstants } from '../constants/reportNameConstants';
import { ChangeField } from '../types/ChangeField';
import { ReportModel } from './Models/ReportModel';
import { baseApi } from './baseApi';
const utb = require('uint8-to-base64');

export class ReportApi extends baseApi {
   public generate = async (reportName: string, referralId: string, fields: ChangeField[]): Promise<ReportModel | undefined> => {
      const url = `${process.env.REACT_APP_TRANSPLANTPATH_API_BASEURL}/api/report/generate`;

      const values = fields.map(field => { return { fieldName: field.fieldName, toValue: field.toValue } });
      values.push({ fieldName: reportNamesConstants.APPVERSION_FIELD, toValue: process.env.REACT_APP_VERSION || null });

      const compressed = pako.gzip(JSON.stringify(values));

      const requestBody = {
        reportName,
        referralId,
        values: utb.encode(compressed)
      };
      const response = await super.post<ReportModel>(url, requestBody);
      if (!response) {
         return undefined;
      }
      return response;
   };
}

const reportApi = new ReportApi();
export default reportApi;