import { msalConfig, loginRequest } from '../authConfig';
import { SignalDispatcher } from 'strongly-typed-events';
import { AuthenticationResult, EventMessage, EventType, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { makeObservable, observable, action, computed } from 'mobx';
import loginApi from '../api/loginApi';
import { UserModel } from '../api/Models/UserModel';
import referenceDataApi from '../api/referenceDataApi';
import referenceDataStore from './ReferenceDataStore';
import { StoreLoadingStateTypes } from '../enums/StoreLoadingStateTypes';
import { UserType, UsersNotAllowedToViewMedia } from '../enums/UserType';
import cookieStore from './CookieStore';

interface UserData {
   azureId: string | undefined;
   accessToken: string | undefined;
   username: string | undefined; // Username as stored in the DonorPath Db
   id: number | undefined; // user Id as stored in the DonorPath Db
   userType?: UserType;
}

class UserStore {
   private static readonly sessionStorageCache = 'localAccountId';

   private _onLoggedIn = new SignalDispatcher();
   private _onLoggedOut = new SignalDispatcher();

   msalInstance = new PublicClientApplication(msalConfig);

   userData: UserData;
   onLoginAttemptFail: boolean; // Keeps track of login response
   state: StoreLoadingStateTypes = StoreLoadingStateTypes.Empty;

   constructor() {
      makeObservable(this, {
         userData: observable,
         login: action,
         logout: action,
         isLoggedIn: computed,
         onLoginAttemptFail: observable,
         state: observable
      });

      this.msalInstance.addEventCallback(msg => this.onMsalInstanceChange(msg));
      loginApi.onResponseSuccess.subscribe((sender, args) => this.onLoginSuccess(args));
      loginApi.onResponseError.subscribe((sender, args) => this.onLoginFailed(args.responseData as UserModel));
      this.userData = { azureId: undefined, accessToken: undefined, username: undefined, id: undefined };
      this.onLoginAttemptFail = false;
      this.readFromStorage();
   }

   public get onLoggedIn() {
      return this._onLoggedIn.asEvent();
   }

   public get onLoggedOut() {
      return this._onLoggedOut.asEvent();
   }

   public get isLoggedIn(): boolean {
      return !!this.userData.accessToken && !!this.userData.id;
   }

   public async login() {
      userStore.msalInstance.acquireTokenRedirect(loginRequest);
   }

   public logout() {
      this.clearUser();
   }

   public get canViewMediaAttachments(): boolean {
      return !UsersNotAllowedToViewMedia.includes(this.userData.userType);
   }

   private onMsalInstanceChange(message: EventMessage) {
      if (message.eventType === EventType.LOGIN_SUCCESS || message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
         var payload = message.payload as AuthenticationResult;
         this.userData.accessToken = payload.accessToken;
         this.userData.username = payload.account?.username;
         this.userData.azureId = payload.uniqueId;
         this.userData.id = undefined;
         this.onLoginAttemptFail = false;

         if (typeof window !== 'undefined' && sessionStorage && this.userData.azureId) {
            sessionStorage.setItem(UserStore.sessionStorageCache, this.userData.azureId);
         }
         this.state = StoreLoadingStateTypes.Loading;
         loginApi.login(cookieStore.allowNoneEssentialCookies ?? false);
      } else if (message.eventType === EventType.LOGOUT_SUCCESS) {
         this.clearUser();
      }
   }

   private onLoginSuccess(user: UserModel) {
      //if (this.userData.username?.toLowerCase() === user.username.toLowerCase()) {
         this.userData.id = user.userId;
         this.userData.username = user.username;
         this.userData.userType = user.userType;
         this.state = StoreLoadingStateTypes.Ready;
         this._onLoggedIn.dispatchAsync();
         referenceDataStore.setLoading();
         referenceDataApi.getReferenceData(
            referenceDataStore.lastFieldVersion,
            referenceDataStore.lastCodeSetVersion,
            referenceDataStore.lastPageLayoutVersion
         );
      //}
   }

   private onLoginFailed(user: UserModel) {
      this.clearUser();
      this.onLoginAttemptFail = true;
      this.state = StoreLoadingStateTypes.Ready;
      this.userData.username = user ? user.username : undefined;
   }

   private clearUser() {
      this.userData.username = undefined;
      this.userData.userType = undefined;
      this.userData.accessToken = undefined;
      this.userData.azureId = undefined;
      this.userData.id = undefined;
      this.onLoginAttemptFail = false;

      if (typeof window !== 'undefined' && sessionStorage) {
         sessionStorage.removeItem(UserStore.sessionStorageCache);
      }
   }

   private readFromStorage() {
      var localAccountId: string | null | undefined;
      if (typeof window !== 'undefined' && sessionStorage && typeof sessionStorage.localAccountId !== 'undefined') {
         localAccountId = sessionStorage.getItem(UserStore.sessionStorageCache);
      }

      if (localAccountId) {
         this.state = StoreLoadingStateTypes.Loading;
         this.msalInstance
            .acquireTokenSilent({
               scopes: loginRequest.scopes,
               account: this.msalInstance.getAccountByLocalId(localAccountId) || undefined
            })
            .then(_ => {
               loginApi.login(cookieStore.allowNoneEssentialCookies ?? false);
            })
            .catch(error => {
               if (error instanceof InteractionRequiredAuthError) {
                  this.login();
               }
            });
      }
   }
}

const userStore = new UserStore();
export default userStore;
