import { SimpleEventDispatcher, EventDispatcher, SignalDispatcher } from 'strongly-typed-events';
import userStore from '../stores/UserStore';

export interface BaseApiErrorInfo {
   status: number | undefined;
   callbackId: string | undefined;
   responseData: any;
}

export class baseApi {
   private static _onUnauthorised = new SignalDispatcher();
   private _onBeginRequest = new SimpleEventDispatcher<any>();
   private _onResponseSuccess = new EventDispatcher<number, any>();
   private _onResponseError = new EventDispatcher<baseApi, BaseApiErrorInfo>();

   public get onBeginRequest() {
      return this._onBeginRequest.asEvent();
   }

   public get onResponseSuccess() {
      return this._onResponseSuccess.asEvent();
   }

   public get onResponseError() {
      return this._onResponseError.asEvent();
   }

   public static get onUnauthorized() {
      return baseApi._onUnauthorised.asEvent();
   }

   protected get<T>(url: string, suppressEvents?: boolean, callbackId?: string): Promise<T> {
      return fetch(url, {
            headers: {
               ApiKey: `${process.env.REACT_APP_APIKEY}`,
               Authorization: 'Bearer ' + userStore.userData.accessToken,
               'Content-Type': 'application/json'
            },
            method: 'GET',
            mode: 'cors'
         })
         .then(res => baseApi.handleResponse(res))
         .then((result: Response) => {
            return result.json();
         })
         .then(
            responseData => {
               if (!suppressEvents) {
                  this._onResponseSuccess.dispatch(0, responseData as T);
               }
               return responseData as T;
            },
            async error => {
               if (!suppressEvents) {
                  var resultVal: any = undefined; 
                  try {
                     resultVal = await error.json();
                  } catch (error) {
                     resultVal = undefined;
                  }

                  this._onResponseError.dispatch(this, { status: undefined, callbackId, responseData: resultVal });
               }

               suppressEvents = true; // Suppress the catch below resending the _onResponseError (with null data)
               return Promise.reject(error);
            }
         )
         .catch((err: Response) => {
            if (!suppressEvents) {
               this._onResponseError.dispatch(this, { status: err.status, callbackId, responseData: undefined });
            }
            return Promise.reject(err);
         });
   }

   protected post<T>(url: string, data: any, callbackId?: string): Promise<T | undefined> {
      return fetch(url, {
         headers: {
            ApiKey: `${process.env.REACT_APP_APIKEY}`,
            Authorization: 'Bearer ' + userStore.userData.accessToken,
            'Content-Type': 'application/json'
         },
         body: JSON.stringify(data),
         method: 'POST',
         mode: 'cors'
      })
         .then(res => baseApi.handleResponse(res))
         .then(
            async (result: Response) => {
               var resultVal: T | undefined = undefined;
               try {
                  resultVal = (await result.json()) as T;
               } catch (error) {
                  resultVal = undefined;
               }
               this._onResponseSuccess.dispatch(0, resultVal);
               return resultVal;
            },
            error => {
               this._onResponseError.dispatch(this, { status: undefined, callbackId, responseData: error });
               return Promise.reject(error);
            }
         )
         .catch((err: Response) => {
            this._onResponseError.dispatch(this, { status: err.status, callbackId, responseData: undefined });
            return Promise.reject(err);
         });
   }

   protected download(url: string, filename: string, suppressEvents?: boolean, callbackId?: string) {
      return fetch(url, {
         headers: {
            ApiKey: `${process.env.REACT_APP_APIKEY}`,
            Authorization: 'Bearer ' + userStore.userData.accessToken,
            'Content-Type': 'application/json'
         },
         method: 'GET',
         mode: 'cors'
      })
      .then(res => baseApi.handleResponse(res))
      .then((result: Response) => {
         filename = filename || result.headers.get("filename") || 'Txp File';
         return result.blob();
      })
      .then(
         blob => {
            // Save file
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);

            if (!suppressEvents) {
               this._onResponseSuccess.dispatch(0, null);
            }
         },
         error => {
            if (!suppressEvents) {
               this._onResponseError.dispatch(this, { status: undefined, callbackId, responseData: error });
            }
            return Promise.reject(error);
         }
      )
      .catch((err: Response) => {
         if (!suppressEvents) {
            this._onResponseError.dispatch(this, { status: err.status, callbackId, responseData: undefined });
         }
         return Promise.reject(err);
      });      
   }

   protected downloadToArrayBuffer(url: string, paramsAsRequestHeaders: boolean, suppressEvents?: boolean, callbackId?: string): Promise<{data: ArrayBuffer, callbackId?: string} | undefined> {
      var headers = {
         ApiKey: `${process.env.REACT_APP_APIKEY}`,
         Authorization: 'Bearer ' + userStore.userData.accessToken,
         'Content-Type': 'application/json'
      };

      // These request for storage documents go via the API manager that expects the sas token details
      // to be send via headers (prefixed with x-txp-) rather than on the url (the API manager then rebuilds to a url)
      if (paramsAsRequestHeaders) {
         // get the file location and set it header item x-txp-file
         var urlSplit  = url.split('?', 2);
         var pathSplit = urlSplit[0].split('/');
         (headers as any)["x-txp-file"] = pathSplit.slice(pathSplit.length - 3).join('/');

         // Get the base url
         url = pathSplit.slice(0, pathSplit.length - 3).join('/');

         // Covert the parameters as header tokens
         var paramSplit= urlSplit[1].split('&');
         paramSplit.forEach(p => {
            var valSplit = p.split('=');
            (headers as any)["x-txp-" + valSplit[0]] = decodeURIComponent(valSplit[1]);
         });
      }
 
      return fetch(url, {
               headers: headers,
               method: 'GET',
               mode: 'cors'
            })
            .then(res => baseApi.handleResponse(res))
            .then(async (result: Response) => {
                  var data = await (await result.blob()).arrayBuffer();
                  if (!suppressEvents) {
                     this._onResponseSuccess.dispatch(0, null);
                  }
                  return {data, callbackId};
               },
               error => {
                  if (!suppressEvents) {
                     this._onResponseError.dispatch(this, { status: undefined, callbackId, responseData: error });
                  }
                  return Promise.reject(error);
               }
            )
            .catch((err: Response) => {
               if (!suppressEvents) {
                  this._onResponseError.dispatch(this, { status: err.status, callbackId, responseData: undefined });
               }
               return Promise.reject(err);
            });      
   }   

   private static handleResponse(response: Response): Promise<Response> {
      // If success, resolve the response
      if (response.status >= 200 && response.status < 300) {
         return Promise.resolve(response);
      }
      // If Bad Request, reject with the body of the response. This will include the errors
      if (response.status === 400) {
         return Promise.resolve(response).then((errorBody: unknown) => Promise.reject(errorBody));
      }
      // Unauthorised
      if (response.status === 401) {
         baseApi._onUnauthorised.dispatch();
      }  
      // For any other errors, throw the status text
      return Promise.reject(response);
   }
}
