import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { LabelType } from '../../enums/LabelType';
import { PageItemType } from '../../enums/PageItemType';
import { StoreLoadingStateTypes } from '../../enums/StoreLoadingStateTypes';
import referenceDataStore from '../../stores/ReferenceDataStore';
import { menuItems } from '../Navigation/menuItems';
import { ToggleSwitch } from '../Inputs/ToggleSwitch/ToggleSwitch';
import { BreakpointContext } from '../../context/BreakpointContext';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import referralStore from '../../stores/ReferralStore';
import { AttachmentFilterBar } from '../Attachments/AttachmentFilterBar';

interface Props {
   sliderValue: boolean;
   sliderHandler: (value: boolean) => void;
   isAcknowledgeCondensed: boolean;
   setAcknowledgeCondensed: React.Dispatch<React.SetStateAction<boolean>>;  
}

export const StickyHeader = observer(({ sliderValue, sliderHandler, isAcknowledgeCondensed, setAcknowledgeCondensed }: Props) => {
   const [activeId, setActiveId] = useState<string | null>();
   const [desktopHeader, setDesktopHeader] = useState<string | null>();
   const [mobileHeader, setMobileHeader] = useState<string | null>();
   const [showClinicallySignificantSwitch, setShowClinicallySignificantSwitch] = useState(false);
   const [showAttachmentFilter, setShowAttachmentFilter] = useState(false);

   const isMobile = useContext(BreakpointContext);

   useEffect(() => {
      var desktopheader: string | null = null;
      var mobileheader: string | null = null;
      if (activeId && referenceDataStore.state === StoreLoadingStateTypes.Ready) {
         // Get the header label from the currently active page
         var pageItems = referenceDataStore.pageLayout.getPageLayoutByPage(activeId);
         if (pageItems) {
            var headerControl = pageItems.find(item => item.type === PageItemType.Label && item.labelType === LabelType.Header);
         }

         if (headerControl) {
            desktopheader = headerControl.text;
            mobileheader = headerControl.extraText || headerControl.text;
         }

         // If no header on currently active page, then user the menu text
         if (!desktopheader) {
            const menuItem = menuItems.find(item => item && item.path === activeId);
            if (menuItem) {
               desktopheader = mobileheader = menuItem.title;
            }
         }
         const menuItem = menuItems.find(item => item && item.path === activeId);
         setShowClinicallySignificantSwitch(!!menuItem?.showClinicallySignificantSwitch);
         setShowAttachmentFilter(activeId === 'Attachments');
      }

      setDesktopHeader(desktopheader);
      setMobileHeader(mobileheader);
   }, [activeId]);

   useEffect(() => {
      if (referenceDataStore.state === StoreLoadingStateTypes.Ready) {
         setActiveId(referenceDataStore.pageLayout.topLevelPageInView);
      }
   }, [referenceDataStore.pageLayout.topLevelPageInView]);

   const clinicallySignificantOnlyToggle = showClinicallySignificantSwitch ? (
      <span className="switch-parent">
         <span className="switch-label">Show only clinically significant</span>
         <ToggleSwitch value={sliderValue} handler={sliderHandler} />
      </span>
   ) : undefined;

   const attachmentFilter = showAttachmentFilter ? <AttachmentFilterBar /> : undefined;

   const updateCount = referralStore.updatedFieldCount;
   return (
      <div className="sticky-header-container">
         {isMobile ? (
            <>
               {updateCount > 0 && <NotificationBanner count={updateCount} isCondensed={isAcknowledgeCondensed} setCondensed={setAcknowledgeCondensed} />}
               <div id="sticky-header" className="sticky-header mobile">
                  {mobileHeader || ' '}
                  {clinicallySignificantOnlyToggle}
               </div>
               {attachmentFilter}
            </>
         ) : (
            <>
               {updateCount > 0 && <NotificationBanner count={updateCount} isCondensed={isAcknowledgeCondensed} setCondensed={setAcknowledgeCondensed} />}
               <div id="sticky-header" className="sticky-header desktop">
                  {desktopHeader || ' '}
                  {clinicallySignificantOnlyToggle}
               </div>
               {attachmentFilter}
            </>
         )}
      </div>
   );
});
