import { useEffect, useRef, useState } from 'react';
import { PageItemModel } from '../../stores/PageLayoutStore';
import referenceDataStore from '../../stores/ReferenceDataStore';
import { ChangeField, ChangeFieldHelper } from '../../types/ChangeField';
import styleVariables from '../../styles/_global.scss';
import { clsx } from 'clsx';
import { observer } from 'mobx-react-lite';

interface Props {
   pageLayoutItem: PageItemModel;
   values: ChangeField[];
   hadUpdates: boolean;
}

export const ComplexControlTable = observer((props: Props) => {
   const tableWrapper = useRef<HTMLDivElement>(null);
   const [tableWidth, setTableWidth] = useState(780);
   const [isScrollBarVisible, setIsScrollBarVisible] = useState(false);

   const onParentResize = () => {
      var tableWidthVal: number | undefined;
      var parentElement = tableWrapper.current!.parentNode!.parentNode;
      if (parentElement !==  null) {
         tableWidthVal = (parentElement as any).offsetWidth - parseInt(styleVariables.paddingDefault);
         setTableWidth(tableWidthVal);
      }

      if (tableWrapper.current && tableWrapper.current.children.length > 0 && tableWidthVal) {
         var childElement = tableWrapper.current.children[0];
         setIsScrollBarVisible(childElement.scrollWidth > (tableWidthVal - 16)); // -16 for the board+padding
      }
   };

   useEffect(() => {
      window.addEventListener('resize', onParentResize);
      return () => {
         window.removeEventListener('resize', onParentResize);
      };
   }, []);

   useEffect(() => {
      onParentResize();
   });

   const getTableData = (): string[][] => {
      let valueObjects = props.values.filter(field => field.toValue !== null).map(field => JSON.parse(field.toValue || ''));
      let colCount = valueObjects.length;
      let valueObjectKeys = valueObjects.length > 0 ? Object.keys(valueObjects[0]) : [];
      let rowCount = props.pageLayoutItem.children!.length;

      let data: string[][] = new Array(rowCount);

      for (var r = 0; r < rowCount; r++) {
         var rowLayoutItem = props.pageLayoutItem.children![r];

         var fieldName = rowLayoutItem.tag ? rowLayoutItem.tag : rowLayoutItem.field;
         var [_, subFieldName] = (fieldName || '').split('_');
         if (!subFieldName) {
            subFieldName = fieldName || '';
         }
         subFieldName = subFieldName.toLowerCase();

         let valueSubFieldName = valueObjectKeys.find(x => x.toLowerCase() === subFieldName);

         data[r] = new Array(colCount + 1);

         if (rowLayoutItem.text) data[r][0] = rowLayoutItem.text;
         else if (rowLayoutItem.field) data[r][0] = referenceDataStore.getFieldInfo(rowLayoutItem.field)?.title || '';

         for (var c = 0; c < colCount; c++) {
            if (valueSubFieldName) {
               data[r][c + 1] = ChangeFieldHelper.getDisplayValue(rowLayoutItem.field!, valueObjects[c][valueSubFieldName]) || '';
            }
         }
      }

      return data;
   };

   const hasUpdate = (index: number): boolean => {      
      return index >= 0 && props.values[index] && props.values[index].updated && props.values[index].toValue !== null;
   }

   const units = props.pageLayoutItem.children!.map(c => c.extraText || '');

   const complexControlTableClass = clsx({
      "complex-control-table": true,
      "has-updates": props.hadUpdates,
      "scroll-bars-visible": isScrollBarVisible
   },
   props.pageLayoutItem.valueFormatType
   );

   return (
      <div className={complexControlTableClass} ref={tableWrapper} id={props.pageLayoutItem.field || ""} style={{ width: tableWidth }}>
         <div className="table-wrapper">
            <table className="table">
               <tbody>
                  {getTableData().map((row, rowIndex) => (
                     <tr key={rowIndex} className="row">
                        {row.map((cell, colIndex) => (
                           <td key={`${rowIndex},${colIndex}`} className={clsx({ "cell": true, "col-header": rowIndex === 0 })}>
                              {rowIndex === 0 && <div className={clsx({ 'has-updates-box': true, 'has-update': hasUpdate(colIndex - 1) })} />}
                              <div className='content'>
                                 {cell || ''}
                                 {colIndex === 0 ? <span className="units"> {units[rowIndex]}</span> : <></>}
                              </div>
                           </td>
                        ))}
                     </tr>
                  ))}
               </tbody>
            </table>
         </div>
      </div>
   );
});
