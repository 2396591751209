import moment, { Moment } from "moment";
import { parseBool } from "../utils/boolUtils";
import { SignalDispatcher } from "strongly-typed-events";

class CookieStore {
    private static readonly AllowNoneEssentialCookies = 'AllowNoneEssentialCookies';

    private static readonly EssentialCookies = [
        CookieStore.AllowNoneEssentialCookies,
        "ARRAffinitySameSite"
    ]

    private _onCookieStoreChange = new SignalDispatcher();

    public get onCookieStoreChange() {
        return this._onCookieStoreChange.asEvent();
    }

    public get allowNoneEssentialCookies(): boolean | undefined {
        return parseBool(this.getCookie(CookieStore.AllowNoneEssentialCookies)) ?? undefined;
    }

    public set allowNoneEssentialCookies(value: boolean | undefined) {
        var hasChanged = value !== this.allowNoneEssentialCookies;
        this.setCookie(CookieStore.AllowNoneEssentialCookies, value, moment().add(1, 'year'));
        if (hasChanged) {
            this._onCookieStoreChange.dispatchAsync();
        }
    }

    private setCookie(name: string, value: any, expires?: Moment): void {
        var cookie = `${name}=${value.toString()}`;
        if (expires) {
            cookie += ';expires=' + expires.utc().toDate().toUTCString();
        }
        document.cookie = cookie;
    }

    private getCookie(name: string): string | undefined {
        name += '=';
        var cookie = document.cookie.split(';').find(value => value.includes(name));
        return cookie?.split('=')[1];
    }

    public clearNoneEssentialCookies() {
        const pastStr = moment().startOf('day').utc().toDate().toUTCString();
        
        var cookies = document.cookie.split(';').map(value => value.split('=')[0].trimStart());
        cookies.filter(value => !CookieStore.EssentialCookies.includes(value)).forEach(cookie => {
            document.cookie = `${cookie}=;expires=${pastStr}`;
        });
    }
}

const cookieStore = new CookieStore();
export default cookieStore;
