import { createContext, useState } from "react";
import { AttachmentState } from "../types/AttachmentState";

export interface AttachmentContextInterface {
    attachments: AttachmentState[];
    UpdateUrl?: (resourceId: string, downloadMethod: string, url: string) => void;
    startingDownloading?: (resourceId: string, downloadMethod: string) => void;
}

const DefaultAttachmentContext : AttachmentContextInterface = {
    attachments: [ 
        {
            Id: "2aa9eed8-35fd-48ba-8280-90706f38f0b5",
            downloaded: false,
            loading: false,
            Url: undefined,
            downloadMethod: "sas"
        },
        {
            Id: "2aa9eed8-35fd-48ba-8280-90706f38f0b5",
            downloaded: false,
            loading: false,
            Url: undefined,
            downloadMethod: "api"      
        },
        {
            Id: "2aa9eed8-35fd-48ba-8280-90706f38f0b5",
            downloaded: false,
            loading: false,
            Url: undefined,
            downloadMethod: "copy"      
        }
    ]
}

export const AttachmentContext = createContext<AttachmentContextInterface>(DefaultAttachmentContext);

type PropsProvider = {
    children: React.ReactNode;
};

export const AttachmentProvider: React.FC<PropsProvider> = ({ children }) => {
    //const attachmentHub = new AttachmentHub();
    const [attachments, setAttachments] = useState(DefaultAttachmentContext.attachments)

    const UpdateUrl = (resourceId: string, downloadMethod: string, url: string): void => {
        var attachmentIndex = attachments.findIndex(c => c.Id === resourceId && c.downloadMethod === downloadMethod)
        if (attachmentIndex !== -1) {
            var item = attachments[attachmentIndex];
            item.Url = url;
            item.downloaded = !!url && url.length > 0;
            item.loading = false;            

            setAttachments([
                ...attachments.slice(0, attachmentIndex),
                item,
                ...attachments.slice(attachmentIndex+1)
            ]);
        }        
    }

    const startingDownloading = (resourceId: string, downloadMethod: string): void => {
        var attachmentIndex = attachments.findIndex(c => c.Id === resourceId && c.downloadMethod === downloadMethod)
        if (attachmentIndex !== -1) {
            var item = attachments[attachmentIndex];
            item.loading = true;
            setAttachments([
                ...attachments.slice(0, attachmentIndex),
                item,
                ...attachments.slice(attachmentIndex+1)
            ]);
        }        
    }
/*
    useEffect(() => {
        attachmentHub.on("received", (data) => {
            // handle the received message
            setData(data);
        });
        return () => {
            connection.off("received");
        }
    }, []);        
    });
*/    
    return (
        <AttachmentContext.Provider
          value={{ attachments, UpdateUrl, startingDownloading }}>
          {children}
        </AttachmentContext.Provider>
    );   
}
