import clsx from 'clsx';
import { ReactComponent as Warning } from "../../../assets/icons/warning.svg";
import { ValueFormatType } from "../../../enums/ValueFormatType";
import { PageItemModel } from '../../../stores/PageLayoutStore';
import { ChangeField } from "../../../types/ChangeField";

interface Props {
    pageLayoutItem: PageItemModel;
    fieldValue?: ChangeField;
}

export const InfoBlockControl = (props: Props) => {
    const getIcon = () => {
        switch (props.pageLayoutItem.valueFormatType) {
            case ValueFormatType.Error: return <Warning />;
        }
    }

    var infoBlockClass = clsx('info-block', { 'has-updates': props.fieldValue && props.fieldValue.updated });

    return <div className={infoBlockClass}>
        <div className="icon">{getIcon()}</div>
        <p>{props.pageLayoutItem.text}</p>
    </div>
}