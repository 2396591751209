import { baseApi } from "./baseApi";
import { UpdatePollerResponseModel } from "./Models/UpdatePollerResponseModel";

class ReferralPollUpdatesApi extends baseApi {
    public updatesPoller(referralId: string, txpChangeListId: string | undefined): Promise<UpdatePollerResponseModel> {
        var url = `${process.env.REACT_APP_TRANSPLANTPATH_API_BASEURL}/api/Referral/UpdatesPoller?referralId=${referralId}&txpChangeListId=${txpChangeListId}`;
        return super.get<UpdatePollerResponseModel>(url);
    }
}

const referralPollUpdatesApi = new ReferralPollUpdatesApi();
export default referralPollUpdatesApi;