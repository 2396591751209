import { ReactComponent as Paperclip } from "../../assets/icons/paperclip.svg";

export const AttachmentsNotAvailableMessage = () => {
    return <div className="attachments-empty-message">
    <div className="icon">
        <Paperclip />
    </div>
    <div className="records-list">
        <h3>Attachments are not available for donor records created prior to TransplantPath.</h3>
    </div>
</div>
}