export interface MenuItem {
   id: string;
   title: string;
   path: string;
   subMenu?: MenuItem[];
   showClinicallySignificantSwitch?: boolean;
   hasUpdates?: boolean;
}

export const menuItems: MenuItem[] = [
   {
      id: 'snod',
      path: 'SNOD Details',
      title: 'SN-OD Details'
   },
   {
      id: '0',
      path: 'Patient Demographics',
      title: 'Patient Demographics'
   },
   {
      id: '1',
      title: 'Patient Details',
      path: 'Patient Details',
      subMenu: [
         { id: '1', path: 'Admission', title: 'Admission' },
         { id: '2', path: 'Measurements', title: 'Measurements' },
         { id: '3', path: 'Trauma', title: 'Trauma' },
         { id: '4', path: 'Cardiac-Respiratory Arrest', title: 'Cardiac-Respiratory Arrest' },
         { id: '5', path: 'BSDT', title: 'BSDT' },
         { id: '6', path: 'DCD', title: 'DCD' }
      ]
   },
   {
      id: '2',
      path: 'Past Medical History',
      title: 'Past Medical History',
      subMenu: [
         { id: '1', path: 'Diabetes', title: 'Diabetes' },
         { id: '2', path: 'Alcohol', title: 'Alcohol' },
         { id: '3', path: 'PmhDrugs', title: 'Drugs' },
         { id: '4', path: 'Smoking', title: 'Smoking' },
         { id: '5', path: 'Allergies', title: 'Allergies' },
         { id: '6', path: 'Contraindications', title: 'Contraindications' }
      ]
   },
   {
      id: '3',
      path: 'MaSH',
      title: 'MaSH',
      showClinicallySignificantSwitch: true,
      subMenu: [
         { id: '1', path: 'Children & Pregnancy', title: 'Children & Pregnancy' },
         { id: '2', path: 'General Health Information', title: 'General Health Information' },
         { id: '3', path: 'Travel & Behaviour', title: 'Travel & Behaviour' }
      ]
   },
   {
      id: '4',
      path: 'Blood Testing',
      title: 'Blood Testing',
      subMenu: [
         { id: '1', path: 'U+Es', title: 'U+Es' },
         { id: '2', path: 'LFT', title: 'LFT' },
         { id: '3', path: 'Microbiology', title: 'Microbiology' },
         { id: '4', path: 'Haemodilution', title: 'Haemodilution' }
      ]
   },
   {
      id: '5',
      path: 'Haemodynamics',
      title: 'Haemodynamics',
      subMenu: [
         { id: '1', path: 'Observations', title: 'Observations' },
         { id: '2', path: 'Cardiac Monitoring', title: 'Cardiac Monitoring' }
      ]
   },
   {
      id: '6',
      path: 'Investigations',
      title: 'Investigations',
      subMenu: [
         { id: '1', path: 'Infections', title: 'Infections' },
         { id: '2', path: 'CXR_ECG', title: 'CXR/ECG' },
         { id: '3', path: 'Echocardiography', title: 'Echocardiography' },
         { id: '4', path: 'Urinalysis', title: 'Urinalysis' },
         { id: '5', path: 'Pregnancy', title: 'Pregnancy' }
      ]
   },
   {
      id: '7',
      path: 'Ventilation',
      title: 'Ventilation',
      subMenu: [{ id: '1', path: 'ABGs', title: 'ABGs' }]
   },
   {
      id: '8',
      path: 'Drugs',
      title: 'Drugs',
      subMenu: [{ id: '1', path: 'Other Drugs', title: 'Other Drugs' }]
   },
   {
      id: '9',
      path: 'Consents',
      title: 'Consents',
      subMenu: [
         { id: '1', path: 'Organ Consent', title: 'Organ Consent/Authorisation' },
         { id: '2', path: 'Research', title: 'Research' },
         { id: '3', path: 'Tissues', title: 'Tissues' }
      ]
   },
   {
      id: '10',
      path: 'Donor HLA',
      title: 'Donor HLA'
   },
   {
      id: '11',
      path: 'Retrieval Information',
      title: 'Retrieval Information',
      subMenu: [
         { id: '1', path: 'Retrieval Teams', title: 'Retrieval Teams' },
         { id: '2', path: 'DCD Observations', title: 'DCD Observations' },
         { id: '3', path: 'Retrieval Timings', title: 'Retrieval Timings' }
      ]
   },
   {
      id: '12',
      path: 'Kidney Anatomy',
      title: 'Kidney Anatomy',
      subMenu: [
         { id: '1', path: 'Left Kidney', title: 'Left Kidney' },
         { id: '2', path: 'Right Kidney', title: 'Right Kidney' }
      ]
   },
   {
      id: '13',
      path: 'Attachments',
      title: 'Attachments',      
   }
];

export const getMenuItemFromTitle = (title: string): MenuItem | undefined => menuItems.find(item => item.title === title || item.subMenu?.some(subMenu => subMenu.title === title))

export function getMenuItemFromPath(path?: string | null): MenuItem | undefined {
   var result = menuItems.find(item => item.path === path);
   if (result === undefined) {
      result = menuItems.filter(item => item.subMenu).flatMap(item => item.subMenu!).find(item => item.path === path);
   }
   return result;
} 

export const getParentMenu        = (path?: string | null):  MenuItem | undefined => menuItems.find(item => item.path === path || item.subMenu?.some(subMenu => subMenu.path === path))

export function scrollToPage(pageId: string) {
   const elem = document.getElementById(pageId);
   const elemDonorDetails = document.getElementById('sticky-header');
   const pageTitleElem = elem?.querySelector('.page-header');
   const pageTitleOffset = (pageTitleElem ? pageTitleElem.clientHeight : -15);
   if (elem != null && elemDonorDetails != null) {
      elem!.parentElement!.scrollTop = elem!.offsetTop - elemDonorDetails.offsetTop - elemDonorDetails.offsetHeight + pageTitleOffset;
   }
};
