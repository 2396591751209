import { baseApi } from './baseApi';

class AcknowledgeUpdateApi extends baseApi {
   public async updateAcknowledgedChanges(referralId: string, currentTxpChangeListId: string) {
      var url = `${process.env.REACT_APP_TRANSPLANTPATH_API_BASEURL}/api/account/acknowledgeUpdates`;
      try {
         const response = await super.post(url, { referralId, currentTxpChangeListId });
         return response;
      } catch (error) {
         return undefined;
      }
   }
}

const acknowledgeUpdateApi = new AcknowledgeUpdateApi();

export default acknowledgeUpdateApi;
