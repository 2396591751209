import { useEffect, useState, useRef } from 'react';
import referralStore from '../../stores/ReferralStore';
import { fieldNames } from '../../constants/fieldNameConstants';
import { Attachment } from '../../types/Attachment';
import { PdfViewer } from './Viewers/PdfViewer';
import { XlsxViewer } from './Viewers/XlsxViewer';
import { DocxViewer } from './Viewers/DocxViewer';
import { TxtViewer } from './Viewers/TxtViewer';
import { HtmlViewer } from './Viewers/HtmlViewer';
import { ImageViewer } from './Viewers/ImageViewer';
import { Pill, PillType } from '../Attachments/Pill';
import { Key } from 'ts-key-enum';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-white.svg';
import { VideoViewer } from './Viewers/VideoViewer';

interface Props {
    resourceBatchId: string,
    fileId: string | undefined,
    onClose: () => void
}

export const DocumentViewerSupportedFileTypes = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
    'text/html',
    'image/jpeg',
    'image/png',
    'video/mp4',
    'public.mpeg-4'
];

export const DocumentViewer = (props: Props) => {
    const [fileType, setFileType] = useState<string | undefined>(undefined);
    const [categoryCode, setCategoryCode] = useState<string | undefined>(undefined);
    const contentsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        var attachment = JSON.parse(referralStore.getField(fieldNames.RESOURCEMETADATAS + props.resourceBatchId)!.toValue!) as Attachment;
        setFileType(attachment.contentType);
        setCategoryCode(attachment.category);
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeydown);        
        return () => {
           document.removeEventListener('keydown', handleKeydown);
        }
    }, []);   
     
    const handleKeydown = (event: KeyboardEvent) => {
        switch (event.key) {
        case Key.Escape:
            props.onClose();
            break;
        case Key.PageDown:
            if (contentsRef.current) {
                contentsRef.current.scrollTop += contentsRef.current.clientHeight;
                event.stopPropagation();
            }
            break;
        case Key.PageUp:
            if (contentsRef.current) {
                contentsRef.current.scrollTop -= contentsRef.current.clientHeight;
                event.stopPropagation();
            }
            break;
        case Key.ArrowDown:
            if (contentsRef.current) {
                contentsRef.current.scrollTop += contentsRef.current.clientHeight * 0.2;
                event.stopPropagation();
            }
            break;
        case Key.ArrowUp:
            if (contentsRef.current) {
                contentsRef.current.scrollTop -= contentsRef.current.clientHeight * 0.2;
                event.stopPropagation();
            }
            break;        
        }
    }

    const handleOnMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.button === 2) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    const renderContent = () => {
        let content: JSX.Element;
        let justify = 'flex-start';
        let backgroundColor = 'var(--background-colour)'

        switch (fileType?.toLowerCase()) {
        case "application/pdf": 
            justify = "center";
            content = <PdfViewer {...props} />;
            break;
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": 
            content = <XlsxViewer {...props} />;
            break;
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            justify = "center";
            content = <DocxViewer {...props} />;
            break;
        case "text/plain":
            content = <TxtViewer {...props} />
            break;
        case "text/html":            
            content = <HtmlViewer {...props} />
            break;
        case "image/jpeg":
        case "image/png":
            justify = "center";
            backgroundColor = "transparent";
            content = <ImageViewer {...props} />
            break;
        case "video/mp4":
        case "public.mpeg-4":
            justify = "center";
            backgroundColor = "transparent";
            content = <VideoViewer {...props} />
            break;
        case undefined:
            content = <></> 
            break;
        default: 
            props.onClose();
            content = <></> 
            break;
        }

        return <div className='contents scroll-style-1' style={{ justifyContent: justify, backgroundColor: backgroundColor }} ref={contentsRef}>{content}</div>
    }

    
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
    return <div className="attachment-viewer-overlay" onMouseDown={handleOnMouseDown} onContextMenu={handleOnMouseDown}>
                <div className="attachment-viewer-gradient">
                    <div className='attachment-viewer-header'>
                        <Pill type={PillType.Category} code={categoryCode} />
                        <button type="button" className="btn-close-desktop" onClick={props.onClose} title='close'>Close preview</button>
                        <button type="button" className="btn-close-mobile" onClick={props.onClose}  title='close'><CloseIcon /></button>
                    </div>
                    <div className='container'>
                        {renderContent()}
                    </div>
                </div>
            </div>
}