import { ValueFormatType } from "../enums/ValueFormatType";
import { StringUtil } from "./stringUtil";

export class valueFormatUtils {
    public static formatValue(fieldName: string, value: string | undefined | null, formatType: ValueFormatType | null): string | undefined | null {
        if (!value || !formatType) {
            return value;            
        }        

        switch (formatType) {
        case ValueFormatType.UpperCase: return StringUtil.toUpperCaseIgnoreGreekChar(value);
        case ValueFormatType.CapitaliseEachWord: return StringUtil.capitalizeEachWord(value);
        case ValueFormatType.CapitaliseEachWordIfRequired: return StringUtil.capitalizeEachWordIfRequired(value);
        case ValueFormatType.NHSNumber: return StringUtil.formatNHSNumber(value);
        case ValueFormatType.PhoneNumber: return StringUtil.formatPhoneNumber(value);
        case ValueFormatType.MonthYear: return StringUtil.formatMonthYear(value);
        }

        return value;
    }

    public static formatSize(fileSizeKb?: number): string | undefined {
        if (fileSizeKb === null || fileSizeKb === undefined) {
            return fileSizeKb;
        }

        if (typeof fileSizeKb === 'string') {
            try {
                fileSizeKb = parseFloat(fileSizeKb);
            }
            catch (e) {}
        }

        if (fileSizeKb >= 1024)
            return (fileSizeKb / 1024.0).toFixed(1) + " MB";
        else if (fileSizeKb < 1)
            return fileSizeKb.toFixed(1) + " KB";
        else 
            return fileSizeKb.toFixed(0) + " KB";
    }    
}