import { baseApi } from "./baseApi";
import { ReferralFromSearch } from "./Models/ReferralFromSearch";

export class LastViewedApi extends baseApi {
    public getLastViewed = async (suppressEvents: boolean | undefined = undefined): Promise<ReferralFromSearch | undefined> => {
        const url = `${process.env.REACT_APP_TRANSPLANTPATH_API_BASEURL}/api/Account/LastViewed`;
        const response = await super.get<ReferralFromSearch>(url, suppressEvents);
        if (!response) {
            throw Error("Failed to last viewed referrals");
        }
        return response;
    }
}

const lastViewedApi = new LastViewedApi();
export default lastViewedApi;
