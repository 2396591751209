import { ConditionOperatorType } from "../enums/ConditionOperatorType";
import { LabelType } from "../enums/LabelType";
import { PageItemType } from "../enums/PageItemType";
import { PageItemValidationType } from "../enums/PageItemValidationType";
import { ValueFormatType } from "../enums/ValueFormatType";
import { action, computed, makeObservable, observable } from "mobx"
import { PageLayoutModel } from "../api/Models/referenceDataModel";
import { menuItems } from "../components/Navigation/menuItems";

interface Page {
    pageId: string;
    isInView?: boolean;
    isTopLevelPageInView?: boolean;
}

export interface PageItemModel {
    type: PageItemType;
    text: string | null;
    labelType: LabelType | null;
    field: string | null;
    orderIndex: number | null;
    leftPadding: number | null;
    children: PageItemModel[] | null;
    validation: PageItemValidationModel[] | null;
    extraText: string | null;
    valueFormatType: ValueFormatType | null;
    tag: string | null;
    sortBy: string;
    sortOrderAsc: boolean | null;
    calculationFields: string | null;
}

export interface PageItemValidationModel {
    type: PageItemValidationType;
    message: string | null;
    conditionsOperator: ConditionOperatorType;
    conditions: ValidationConditionModel[] | null;
    preventClear: boolean | null;
}

export interface ValidationConditionModel {
    type: string;
    notCondition: boolean;
    parameter1: string | null;
    parameter2: string | null;
    parameter3: string | null;
}

export class PageLayoutStore {
    pageLayoutsByPage: { [page: string]: PageItemModel[] } = {};
    pageOrder: Page[] = [];

    constructor() {
        makeObservable(this, {
            pageOrder: observable,
            pageLayoutsByPage: observable,
            updateFromServer: action,
            setPageInView: action,
            firstPageInView: computed,
            topLevelPageInView: computed,
        })
    }

    getPageLayoutByPage(page: string): PageItemModel[] | undefined {
        if (!page) {
            return undefined;
        }

        return this.pageLayoutsByPage[page];
    }

    getPageKeys(): string[] {
        return Object.keys(this.pageLayoutsByPage);
    }

    getOrderedPageKeys(): string[] {
        return this.pageOrder.map(c => c.pageId);
    }

    getFirstActivePageId(): string {
        return menuItems!.find(mi => mi.id === '0')!.path || '';
    }

    public getFieldNamesForPage(page: string): string[] {
        if (!page) {
            return [];
        }
  
        var topLevelPageItems = this.getPageLayoutByPage(page);
        if (!topLevelPageItems) {
            return [];
        }
  
        var pageItems: PageItemModel[] = [];
        topLevelPageItems?.forEach(pi => PageLayoutStore.flatten(pi, true, pageItems));  
        return pageItems.map(pi => (pi.field || pi.tag)!).filter(name => name);
    }    

    get firstPageInView(): string | null {
        var pageInView;
        if (this.pageOrder) {
            pageInView = this.pageOrder.find(p => p.isInView);
        }
        return pageInView ? pageInView.pageId : null;
    }

    get topLevelPageInView(): string | null {
        var firstPageInViewId = this.firstPageInView;
        var topLevelPageId = menuItems.find(mi => mi.path === firstPageInViewId || (mi.subMenu && mi.subMenu.some(smi => smi.path === firstPageInViewId)));
        return (topLevelPageId && topLevelPageId.path) ? topLevelPageId.path : null;
    }

    setPageInView(pageId: string, isInView: boolean) {
        var page = this.pageOrder ? this.pageOrder.find(p => p.pageId === pageId) : null;
        if (page) {
            page.isInView = isInView;
        }
    }

    // Returns the first page that contains the field name, else undefined
    getPageByFieldName(fieldName: string): Page | undefined {
        var [baseFieldName] = fieldName.split('|', 2);

        return this.pageOrder.find(p => {
                    return this.pageLayoutsByPage[p.pageId]
                            .some(pmi => PageLayoutStore.flatten(pmi, true, [])
                                                        .some(pmi => pmi.field === baseFieldName || 
                                                                     pmi.field === fieldName     ||
                                                                     pmi.tag   === baseFieldName || 
                                                                     pmi.tag   === fieldName     ||
                                                                     (pmi.calculationFields && pmi.calculationFields.includes(baseFieldName))));
                });
    }

    updateFromServer(pageItemModels: PageLayoutModel[]) {
        pageItemModels.forEach(p => this.pageLayoutsByPage[p.page] = p.items);

        // Order pages in same order as the menu
        var pages = Object.keys(this.pageLayoutsByPage);
        var originalPages = this.pageOrder;
        this.pageOrder = [];
        for (const menu of menuItems) {
            if (menu.path && pages.some(p => p === menu.path)) {
                var page = originalPages.find(p => p.pageId === menu.path);
                if (!page) {
                    page = { pageId: menu.path };;
                }
                this.pageOrder.push(page);
            }

            if (menu.subMenu) {
                for (const subMenu of menu.subMenu) {
                    if (subMenu.path && pages.some(p => p === subMenu.path)) {
                        var subPage = originalPages.find(p => p.pageId === subMenu.path);
                        if (!subPage) {
                            subPage = { pageId: subMenu.path };;
                        }
                        this.pageOrder.push(subPage);
                    }
                }
            }
        }
    }

    // Traverses the tree structure to returns the value of the first element in the where predicate is true, and undefined
    public static findPageItemModel(items: PageItemModel[], predicate: (value: PageItemModel, index: number) => boolean): PageItemModel | undefined {
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            if (predicate(item, i)) return item;

            if (!!item.children && item.children.length > 0) {
                var childItem = PageLayoutStore.findPageItemModel(item.children, predicate);
                if (childItem) return childItem;
            }
        }

        return undefined;
    }

    // Return PageItemModel that contains the specified field name or undefined if not found
    public static findPageItemModelByFieldName(items: PageItemModel[], fieldName: string): PageItemModel | undefined {
        return PageLayoutStore.findPageItemModel(items, i => i.field === fieldName);
    }

    // Returns the first parent control of fieldName, that is of specified type
    public static findParentPageItemModel(items: PageItemModel[], fieldName: string, type: PageItemType): PageItemModel | undefined {
        return PageLayoutStore.findPageItemModel(
            items,
            i => i.type === type && !!i.children && !!PageLayoutStore.findPageItemModelByFieldName(i.children, fieldName)
        );
    }

    // Returns a flattener list of all child controls, into list
    public static flatten(parent: PageItemModel, includeParent: boolean = false, list: PageItemModel[]): PageItemModel[] {
        if (!parent) {
            return [];
        }

        if (includeParent) {
            list.push(parent);
        }

        if (parent.children) {
            parent.children.forEach(child => PageLayoutStore.flatten(child, true, list));
        }

        return list;
    }
}
