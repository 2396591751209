import { useState, useEffect } from "react";
import referralStore from "../../stores/ReferralStore";
import { fieldNames } from "../../constants/fieldNameConstants";

interface Props {
    fileId: string | number;
}

export const RefNumber = (props: Props) => {
    var field = referralStore.getField(fieldNames.RESOURCEREFNUMBERS + props.fileId)
    return field?.toValue ? <span className="ref-number">{field?.toValue}</span> : <></>;
}