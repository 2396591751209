import clsx from 'clsx';
import { AttachmentFilter } from "../../enums/AttachmentFilter";
import attachmentFileStore from "../../stores/AttachmentFileStore";
import { ReactComponent as SwitchOn } from '../../assets/icons/switch-on.svg'
import { ReactComponent as SwitchOff } from '../../assets/icons/switch-off.svg'
import { observer } from 'mobx-react-lite';
import userStore from '../../stores/UserStore';

export const AttachmentFilterBar = observer(() => {
    const onFilterClick = (filter: AttachmentFilter) => {
        attachmentFileStore.setFilter(filter);
    }

    const onShowCommentsOnly = () => {
        attachmentFileStore.setShowImages(!attachmentFileStore.showImages);
    }

    if (!userStore.canViewMediaAttachments) {
        return <></>
    }
    
    return <div className='attachment-tab-bar'>
                <div className='filter'>
                    <button className={clsx(attachmentFileStore.filter === AttachmentFilter.Files && 'selected')} onClick={() => onFilterClick(AttachmentFilter.Files)}>Files</button>
                    <button className={clsx(attachmentFileStore.filter === AttachmentFilter.Media && 'selected')} onClick={() => onFilterClick(AttachmentFilter.Media)}>Media</button>
                </div>
                <div className={clsx('show-comments', { hide: attachmentFileStore.filter === AttachmentFilter.Files })}>
                    <span>Show comments only</span>
                    <button onClick={onShowCommentsOnly}>{!attachmentFileStore.showImages ? <SwitchOn /> : <SwitchOff />}</button>
                </div>
           </div>;
})