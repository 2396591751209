import { baseApi } from './baseApi';
import { ReferralFromSearch } from './Models/ReferralFromSearch';

export class SearchApi extends baseApi {
   public postDonorRecordSearch = async (ntxdDonorId: number, dob: string): Promise<ReferralFromSearch | undefined> => {
      const url = `${process.env.REACT_APP_TRANSPLANTPATH_API_BASEURL}/api/referral/search`;
      const requestBody = {
         ntxdDonorId,
         dob
      };
      const response = await super.post<ReferralFromSearch>(url, requestBody);
      if (!response) {
         return undefined;
      }
      return response;
   };
}
