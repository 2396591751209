import { useEffect, useRef, useState } from 'react';
import { PageItemModel } from '../../stores/PageLayoutStore';
import { ChangeField, ChangeFieldHelper } from '../../types/ChangeField';
import styleVariables from '../../styles/_global.scss';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

interface Props {
   pageLayoutItem: PageItemModel;
   values: ChangeField[];
   headers: string[];
   labelPageItems: PageItemModel[];
}

export const MicrobiologyTable = observer((props: Props) => {
   const tableWrapper = useRef<HTMLDivElement>(null);
   const [tableWidth, setTableWidth] = useState(0);

   const onParentResize = () => {
      var parentElement = tableWrapper.current!.parentNode!.parentNode;
      if (parentElement !==  null) {
         setTableWidth((parentElement as any).offsetWidth - parseInt(styleVariables.paddingDefault) * 2);
      }
   };

   useEffect(() => {
      window.addEventListener('resize', onParentResize);
      onParentResize();
      return () => {
         window.removeEventListener('resize', onParentResize);
      };
   }, [tableWrapper]);

   const getTableData = (): string[][] => {
      let valueObjects = props.values.map(field => (field && field.toValue !== null ? JSON.parse(field.toValue || '') : {}));
      let colCount = props.headers.length;
      let valueObjectItems = valueObjects.find(valueObject => Object.keys(valueObject).length > 0);
      let valueObjectKeys: string[] = valueObjectItems ? Object.keys(valueObjectItems) : [];
      let rowCount = valueObjectKeys.length;

      let data: string[][] = new Array(rowCount);

      for (var r = 0; r < props.labelPageItems.length; r++) {
         let valueSubFieldName = valueObjectKeys.find(x => x.toLowerCase() === props.labelPageItems[r].tag);

         data[r] = new Array(colCount + 1);
         data[r][0] = props.labelPageItems[r].text || '';

         for (var c = 0; c < colCount; c++) {
            if (valueObjects[c] && valueSubFieldName) {
               data[r][c + 1] = ChangeFieldHelper.getDisplayValue(props.labelPageItems[r].field!, valueObjects[c][valueSubFieldName]) || '';
            } else {
               data[r][c + 1] = '';
            }
         }
      }

      return data;
   };

   const isEmptyColumn = (index: number) => {
      const valueObjects = props.values.map(field => (field && field.toValue !== null ? JSON.parse(field.toValue || '') : {}));
      return Object.keys(valueObjects[index]).length === 0;
   }

   const hasUpdate = (index: number) => {
      return props.values[index] && props.values[index].updated;
   }

   const microbiologyTableClass = clsx({
      "microbiology-table": true,
      "has-updates": props.values.some(f => f && f.updated)
   });

   return (
      <div className={microbiologyTableClass} ref={tableWrapper} style={{ width: tableWidth }}>
         <div className="table-wrapper">
            <table className="table">
               <tbody>
                  <tr className="headerRow" >
                     <th></th>
                     {props.headers.map((header, colIndex) => (
                        <th className={clsx(isEmptyColumn(colIndex) && 'disabledColumn')} key={`'header,${colIndex + 1}`}>
                           <div className={clsx({ 'has-updates-box': true, 'has-update': hasUpdate(colIndex) })} />
                           <div className='header-text'>{header}</div>
                        </th>
                     ))}
                  </tr>
                  {getTableData().map((row, rowIndex) => (
                     <tr key={rowIndex} className="row">
                        {row.map((cell, colIndex) => (
                           <td key={`${rowIndex},${colIndex}`} className={clsx('cell', cell.toUpperCase() === 'POSITIVE' && 'positive')}>
                              <span>{cell || ''}</span>
                           </td>
                        ))}
                     </tr>
                  ))}
               </tbody>
            </table>
         </div>
      </div>
   );
});
