import clsx from "clsx";

interface Props {
    title: string;
    value: string | null;
    hideTitle?: boolean;
    valueSecondary?: string | null;
    valueTertiary?: string | null;
    block?: boolean;
}

export const DonorHeaderItem = ({ title, value, valueSecondary, valueTertiary, hideTitle, block }: Props) => {

    return <div className={clsx('donor-header-item', block ? 'block' : 'inline')}>
        {!hideTitle && <div className='title'>
            {title}
        </div>}
        <div className='value'>
            {value} {valueSecondary && <span className='secondary'>{valueSecondary}</span>} {valueTertiary && <span className='tertiary'>{valueTertiary}</span>}
        </div>
    </div>
}