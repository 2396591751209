import { ReactComponent as Clipboard } from "../../../assets/icons/contentPasteOff.svg";

interface Props {
    text: string | null;
}

export const EmptyState = ({ text }: Props) => {
    return <div className="empty-state">
        <div className="icon">
            <Clipboard />
        </div>
        <p>{text}</p>
    </div>
}