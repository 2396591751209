import { ReactComponent as Clipboard } from "../../assets/icons/contentPasteOff.svg";
export const NoRecordsMessage = () => {
    return <div className="no-records-message">
        <div className="icon">
            <Clipboard />
        </div>
        <div className="records-list">
            <h3>You haven't opened any donor records recently</h3>
            <p>When you've searched for a donor record and opened it, it will appear here for easy access</p>
        </div>
    </div>
}