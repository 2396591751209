import moment from 'moment';
import { baseApi } from './baseApi';
import { SystemEventType } from '../enums/SystemEventType';

class EventApi extends baseApi {
   public postEvent = async (referralId: string, eventType: SystemEventType, eventDetail: string): Promise<void> => {
      const url = `${process.env.REACT_APP_TRANSPLANTPATH_API_BASEURL}/api/Events`;
      const requestBody = {
            referralId,
            timestamp: moment.utc ().format ('YYYY-MM-DD HH:mm:ss.hhh'),
            eventId: eventType,
            eventDetail
      };
      
      await super.post(url, requestBody);
   };
}

const eventApi = new EventApi();
export default eventApi;