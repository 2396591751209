import { fieldNames } from "../../constants/fieldNameConstants";
import fieldValues from "../../constants/referralFieldValueConstants";
import referenceDataStore from "../../stores/ReferenceDataStore";

export enum PillType {
    Category,
    Organ,
    OrganLocation
}

interface Props {
    code?: string,
    type: PillType,
}

export const Pill = (props: Props) => {
    let backgroundColor = "#303030";
    let color = "white";
    let text = props.code;
    if (props.type === PillType.Category) {
        text = referenceDataStore.getCodeSetText(fieldNames.RESOURCEMETADATA_CATEGORY, props.code) || props.code;
        backgroundColor = "#303030";
    } else if (props.type === PillType.OrganLocation) {
        text = referenceDataStore.getCodeSetText(fieldNames.RESOURCEMETADATA_ORGANLOCATION, props.code) || props.code;
        backgroundColor = "#005385";
    } else if (props.type === PillType.Organ) {
        text = referenceDataStore.getCodeSetText(fieldNames.RESOURCEMETADATA_ORGANCATEGORY, props.code) || props.code;
        switch (props.code) {
        case fieldValues.ATTACHMENT.ORGAN.RIGHT_KIDNEY:backgroundColor = "#FF4747"; break;
        case fieldValues.ATTACHMENT.ORGAN.LEFT_KIDNEY: backgroundColor = "#E3B100"; color = "black"; break;
        case fieldValues.ATTACHMENT.ORGAN.PANCREAS:    backgroundColor = "#3F98CB"; break;
        case fieldValues.ATTACHMENT.ORGAN.HEART:       backgroundColor = "#FF3194"; break;
        case fieldValues.ATTACHMENT.ORGAN.LIVER:       backgroundColor = "#74BF46"; break;
        case fieldValues.ATTACHMENT.ORGAN.LUNGS:       backgroundColor = "#44257D"; break;
        case fieldValues.ATTACHMENT.ORGAN.LEFT_LUNG:   backgroundColor = "#44257D"; break;
        case fieldValues.ATTACHMENT.ORGAN.RIGHT_LUNG:  backgroundColor = "#44257D"; break;
        }
    } 

    return props.code ? <span className="pill" style={{backgroundColor: backgroundColor, color: color}}>{text}</span> : <></>;
}