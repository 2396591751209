import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ReferralFromSearch } from "../../api/Models/ReferralFromSearch";
import { complexDateUtil } from "../../utils/complexDateUtil";
import { StringUtil } from "../../utils/stringUtil";

interface Props {
    referral: ReferralFromSearch;
    setOpenDonorDetails: Function;
    setDonorDetails: Function;    
}

export const LastViewedReferral = observer((props: Props) => {
    const dob = complexDateUtil.convertStringToDate(props.referral.dob);
    const formattedDob: string = dob ? dob.format("DD/MM/YYYY") : "";

    const onLastViewedItemClick = () => {
        const dob = new Date(props.referral.dob);
        props.setDonorDetails({
            referralId: props.referral.recordId,
            odtNumber: props.referral.ntxdDonorId,
            dob: complexDateUtil.formatDobStringFromDate(dob, props.referral.ageText),
            hospital: props.referral.hospitalText,
            donorType: props.referral.donorTypeText,
            sex: props.referral.genderText,
            currentChangeListId: props.referral.currentChangeListId,
            createdDate: props.referral.createdDate
        });
        props.setOpenDonorDetails(true);
    };
    
    var lasViewedContainer = clsx({
        'last-viewed-container': true,
        'has-updates': props.referral.hasUpdates
    });

    return (<div className={lasViewedContainer} id={props.referral.recordId}>
        <button className="last-viewed-item" onClick={() => onLastViewedItemClick()}>
            <div className="lv-header">
                ODT {props.referral.ntxdDonorId} - {props.referral.donorTypeText} {StringUtil.capitalizeEachWord(props.referral.genderText)}                
            </div>
            <div className="lv-body">
                <div className="lv-hospital">
                    <div className="lv-title">Hospital</div>
                    <div className="lv-value">{props.referral.hospitalText}</div>
                </div>
                <div className="lv-bloodgroup">
                    <div className="lv-title">Blood Group</div>
                    <div className="lv-value">{props.referral.bloodTypeText}</div>
                </div>
                <div className="lv-dob">
                    <div className="lv-title">Date of Birth</div>
                    <div className="lv-value">{formattedDob}</div>
                </div>
                <div className="lv-age">
                    <div className="lv-title">Age</div>
                    <div className="lv-value">{props.referral.ageText}</div>
                </div>
            </div>
        </button>
    </div>);
});

