export enum UserType {
    TxPQaAdmin = 101,
    TxPRecipientCoordinator = 102,
    TxPSurgeonsPhysicians = 103,
    TxPResearcherWithImgs = 104,
    TxPTissueEstablishment = 105,
    TxPResearcherWithoutImgs = 106,
    TxPLaboratories = 107,
}

export const UsersNotAllowedToViewMedia: (UserType | undefined)[] = [ UserType.TxPTissueEstablishment, UserType.TxPResearcherWithoutImgs ];
