import { Component } from "react";

interface Props {
    onError: ((error?: Error, errorInfo?: React.ErrorInfo) => void) | undefined;
    children: JSX.Element[] | JSX.Element;
}

export class ErrorBoundary extends Component<Props> {
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error(error, errorInfo);

        if (this.props.onError) {
            this.props.onError(error, errorInfo);
        }
    }    

    render() {
        return this.props.children;
    }
}
