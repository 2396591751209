import clsx from 'clsx';
import { ReactElement } from 'react';
import Dialog from '../Dialog/Dialog';
import Button from '../Inputs/Button/Button';
import { ReactComponent as NotificationBell } from '../../assets/icons/bell.svg';
import { StringUtil } from '../../utils/stringUtil';


interface NotificationProperties {
   /** toggles the notification popup */
   show: boolean;
   bcMessage: {
      title: string;
      text: string;
   };

   openReadMessageDialog: boolean;
   readMessageHandler: () => void;
   hasBeenRead: boolean;
}


const NotificationPopup = ({ show = false, bcMessage, readMessageHandler, openReadMessageDialog, hasBeenRead }: NotificationProperties): ReactElement => {
   const handleClick = (e: { stopPropagation: () => void; }) => {
      readMessageHandler();
      e.stopPropagation();
   }

   return (
      <>
         <button className={clsx('notification-content', { hide: !show })} onClick={handleClick}>
            <div className={clsx("inner-content", (hasBeenRead ? "has-been-read" : ""))}>
               <span className="title">{StringUtil.truncate(bcMessage.title, 60)}</span>
               <span className="desc">{StringUtil.truncate(bcMessage.text, 300)}</span>
            </div>
         </button>
         {/**View full message dialog */}
         {openReadMessageDialog && <Dialog onClose={readMessageHandler} maxWidth="sm" className="read-message-dialog">
            <NotificationBell className="notification-icon" />
            <div>
               <div className="title">{bcMessage.title}</div>
               <div className="text">{bcMessage.text}</div>
            </div>
            <Button text="OK" className="btn dialog-btn" onClick={handleClick} />
         </Dialog>}
      </>
   );
};

export default NotificationPopup;
