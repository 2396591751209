import { useEffect, useRef, useState } from 'react';
import { Breadcrumb } from '../../components/Header/Breadcrumb/Breadcrumb';
import { BreadcrumbNav } from '../../components/Header/BreadcrumbNav/BreadcrumbNav';
import { Header } from '../../components/Header/Header';
import historyStore from '../../stores/HistoryStore';
import { Link } from 'react-router-dom';
import { Key } from 'ts-key-enum';

export const AccessibilityStatement = () => {
   const [previousLinkRoute, setPreviousLinkRoute] = useState('');
   const [previousLinkText, setPreviousLinkText] = useState('');
   const [activeTab, setActiveTab] = useState();
   const sectionRef = useRef<HTMLDivElement>(null);
   const keyboardScrollAmount = 30;

   const handleKeydown = (event: KeyboardEvent) => {
     if (event.key === Key.ArrowDown) {
        sectionRef.current?.scrollBy({top: keyboardScrollAmount});
     }else if (event.key === Key.ArrowUp) {
        sectionRef.current?.scrollBy({top: -keyboardScrollAmount});
     }
   }

   useEffect(() => {
     document.addEventListener('keydown', handleKeydown)
     return () => {
        document.removeEventListener('keydown', handleKeydown);
     }
   })

   useEffect(() => {
      var url = historyStore.getPrevious(true);

      setPreviousLinkRoute(url || '/');

      var previousLinkText = 'Log in';
      if (url && url.toLocaleLowerCase().indexOf('/referral') > -1) {
         previousLinkText = 'Donor Record';
      } else if (url && url.toLocaleLowerCase().indexOf('/search') > -1) {
         previousLinkText = 'Search';
      }
      setPreviousLinkText(previousLinkText);
   }, [historyStore.history]);

   const breadcrumb = <Breadcrumb previousLinkRoute={previousLinkRoute} previousLinkText={`Back to ${previousLinkText}`} />

   return (
      <>
         <Header leftContentMobile={breadcrumb} activeTab={activeTab} setActiveTab={setActiveTab} showAlerts/>
         <BreadcrumbNav hideOnMobile pageTitle={"Accessibility"} previousLinkRoute={previousLinkRoute} previousLinkText={previousLinkText} />
         <section className="accessibility" ref={sectionRef}>
            <div className="container">
                <h1>Accessibility</h1>
                <p>This accessibility statement applies to {process.env.REACT_APP_ADREDIRECTCREDENTIALS}</p>
                <p>This web-based application was designed by Apadmi
                and is run by NHS Blood and Transplant (NHSBT). It has been designed to meet
                the needs of a wide range of clinical and academic users.</p>
                <p>Training is mandated for medical clinicians / academic users to gain access to use this site. To request access / training
                please contact the NHSBT Service Desk on <a href="mailto:service.desk@nhsbt.nhs.uk">service.desk@nhsbt.nhs.uk</a></p>

                <h2>How accessible this website is</h2>
                <p>We want the transplant and academic community to be
                able to use our web-based application for reviewing and assessing the
                suitability of donated organs in a safe and timely manner.</p>

                <p>This means you should be able to:</p>
                <ul>
                    <li>change colours and contrast using browser functionality</li>
                    <li>navigate, find content and place in record easily</li>
                    <li>navigate most of the website using just a keyboard</li>
                </ul>
                <p>If you would like some support to adjust your browser, please visit&nbsp; 
                <a href="https://mcmw.abilitynet.org.uk" target="_blank" rel="noopener noreferrer">AbilityNet</a>
                &nbsp;for "how to" guides to make your device easier to use.</p>
                <p>TransplantPath has been designed with the clinical / academic user in mind. We aim to make the website as clear and as simple as
                possible to understand.</p>
                <p>We are constantly reviewing the TransplantPath website as we know it is not fully accessible.</p>

                <h2>Request content in an accessible format</h2>
                <p>The information within the donor file will be available to download as a PDF once the donation status is set to 'complete'.
                This will not include images or attachments.</p>

                <h2>Report accessibility problems with this website</h2>
                <p>If you find any problems that are not listed on this page or you think we're not meeting expected accessibility
                requirements, please contact us and let us know, so that we can discuss
                and review our design approach.</p>

                <h2>Contacting us</h2>
                <p>To get further advice please contact us: 
                <ul>
                    <li>By email <a href="mailto:Enquiries@nhsbt.nhs.uk">Enquiries@nhsbt.nhs.uk</a></li>
                    <li>By letter to: NHS Blood and Transplant 500, North Bristol Park, Filton, Bristol. BS34 7QH</li>
                </ul>
                </p>

                <h2>Enforcement procedure</h2>
                <p>If you contact us with a complaint and you are not happy with our response, you can&nbsp; 
                    <a href="https://www.equalityadvisoryservice.com/" target="_blank" rel="noopener noreferrer">contact the Equality Advisory and Support Service (EASS)</a>
                .</p>
                <p>The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public Sector Bodies (Websites and Mobile Applications) (No. 2) 
                    Accessibility Regulations 2018 (the 'accessibility regulations').</p>
                
                <h2>Technical information about this website's accessibility</h2>
                <p>NHS Blood and Transplant is committed to
                making our websites accessible, allowing for appropriate adjustments
                in accordance with the Public Sector Bodies (Websites and Mobile Applications)
                (No. 2) Accessibility Regulations 2018.</p>
                <p>This website is partially compliant with the&nbsp; 
                    <a href="https://www.w3.org/TR/WCAG21" target="_blank" rel="noopener noreferrer">Web Content Accessibility Guidelines version 2.1</a>
                &nbsp;AA standard, due to the non-compliances and exemptions as described in this statement.</p>

                <h2>Non-accessible content</h2>
                <p>The content that is not accessible is outlined in this section with details of:</p>
                <ul>
                    <li>how it fails the success criteria</li>
                    <li>When we plan to fix issues.</li>
                </ul>

                <h2>Non-compliance and exemptions to accessible content</h2>
                <p>This website's accessibility will be reviewed on a regular basis during improvement releases and this statement updated as required.</p>
                <p>We know that the following accessibility requirements are not being met:</p>
                <ul>
                    <li>There are no alternatives for time-based media.</li>
                    <li>There will be an auto session log out at 15 minutes of idol time.</li>
                    <li>Attachments cannot be monitored for accessibility compliance or content that may cause seizures or physical reactions.</li>
                    <li>This site does not have adaptive assistive technologies.</li>
                    <li>The content of this site is not available in other formats.</li>
                </ul>

                <h2>Disproportionate burden</h2>
                <p>Not Applicable</p>

                <h2>How we tested this website</h2>
                <p>Our developers, Apadmi, used the Website Accessibility Conformance Evaluation Methodology (WCAG-EM) approach during the
                design process and compliance was tested by the developers with the Web Content Accessibility Guidelines (WCAG) V2.1 level A and level AA in July 2023.</p>
                <p>This web-based application will undergo regular improvement releases and accessibility requirements will be reviewed during each release.</p>

                <h2>Accessibility of NHS Blood and Transplant services</h2>
                <p>Each NHSBT service has its own accessibility page, with details of how accessible the service is, how to
                report problems and where available, how to request information in an alternative format. You can access these pages from the footer of each website.</p>

                <h2>Preparation of this accessibility statement</h2>
                <p>This statement was prepared on 24<sup>th</sup> July 2023.</p>
            </div>
         </section>
      </>
   );
};
