import { AttachmentsEmptyMessage } from "./AttachmentsEmptyMessage";
import { AttachmentDocumentControl } from "./AttachmentDocumentControl";
import fieldValues from "../../constants/referralFieldValueConstants";
import { Attachment } from "../../types/Attachment";
import { AttachmentMediaControl } from "./AttachmentMediaControl";
import { ChangeField } from "../../types/ChangeField";
import { AttachmentFilterBar } from "./AttachmentFilterBar";
import attachmentFileStore from "../../stores/AttachmentFileStore";
import { AttachmentFilter, AttachmentFilterToType } from "../../enums/AttachmentFilter";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import referenceDataStore from "../../stores/ReferenceDataStore";
import referralStore from "../../stores/ReferralStore";
import { SettingConstants } from "../../constants/settingConstants";
import { fieldNames } from "../../constants/fieldNameConstants";
import moment from "moment";
import { AttachmentsNotAvailableMessage } from "./AttachmentsNotAvailableMessage";

interface Props {
    attachments: ChangeField[]
}

interface AttachmentInfo {
    fieldName: string,
    attachment: Attachment,
}

const OrganSortList = [
    fieldValues.ATTACHMENT.ORGAN.HEART,
    fieldValues.ATTACHMENT.ORGAN.LEFT_LUNG,
    fieldValues.ATTACHMENT.ORGAN.RIGHT_LUNG,
    fieldValues.ATTACHMENT.ORGAN.LUNGS,
    fieldValues.ATTACHMENT.ORGAN.LIVER,
    fieldValues.ATTACHMENT.ORGAN.PANCREAS,
    fieldValues.ATTACHMENT.ORGAN.LEFT_KIDNEY,
    fieldValues.ATTACHMENT.ORGAN.RIGHT_KIDNEY,
    fieldValues.ATTACHMENT.ORGAN.OTHER
]

export const AttachmentsList = observer((props: Props) => {
    const types = AttachmentFilterToType[attachmentFileStore.filter];
    const [sortedList, setSortedList] = useState<AttachmentInfo[]>([]);
    const [showFilterBar, setShowFilterBar] = useState(true);
    const [isOldReferral, setIsOldReferral] = useState(false);

    useEffect(() => {
        setSortedList(getSortedList());
    }, [attachmentFileStore.filter, referralStore.latestChangeListId]);

    useEffect(() => {
        setSortedList(getSortedList());
        setShowFilterBar(referenceDataStore.pageLayout.topLevelPageInView !== 'Attachments');

        var cutOffDate = referenceDataStore.settings.getSettingAsDate(SettingConstants.CUTOFF_DATE_ATTACHMENTS)!;
        setIsOldReferral(!referralStore.createdDateSameOrAfter(cutOffDate))
    }, []);    

    useEffect(() => {
        setShowFilterBar(referenceDataStore.pageLayout.topLevelPageInView !== 'Attachments');
     }, [referenceDataStore.pageLayout.topLevelPageInView]);

    const getSortedList = () => { 
        var filteredList = props.attachments
                                .map(f => {
                                    return { 
                                        fieldName:  f.fieldName,
                                        attachment: JSON.parse(f.toValue!) as Attachment
                                    }
                                })
                                .filter(c => c.attachment.timestamp && types.includes(c.attachment.type))
                                .sort((a,b) => b.attachment.timestamp!.localeCompare(a.attachment.timestamp!));

        if (attachmentFileStore.filter === AttachmentFilter.Media) {                                
            filteredList = filteredList.sort((a, b) => {
                var sortIndexA = OrganSortList.indexOf(a.attachment.organCategory);
                var sortIndexB = OrganSortList.indexOf(b.attachment.organCategory);
                return sortIndexA - sortIndexB;
            });
        }
                                
        return filteredList;
    };

    return  <>
                <div className="attachment-filter-bar-container">
                    {showFilterBar && <AttachmentFilterBar />}
                </div>
                <div id="attachments-list">
                    {sortedList.length === 0 ? (isOldReferral ? <AttachmentsNotAvailableMessage /> : <AttachmentsEmptyMessage />) 
                                             : sortedList.map(f => {
                                                                        if (f.attachment.type === fieldValues.ATTACHMENT.TYPE.IMAGE || f.attachment.type === fieldValues.ATTACHMENT.TYPE.VIDEO) {
                                                                            return <AttachmentMediaControl {...f} />
                                                                        } else {
                                                                            return <AttachmentDocumentControl {...f} />
                                                                    }})}
                </div>
            </>;
});