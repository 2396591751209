/* eslint-disable jsx-a11y/tabindex-no-positive */
import { MouseEventHandler, useEffect, useRef, useState } from "react";
import referralStore from "../../stores/ReferralStore";
import { fieldNames } from "../../constants/fieldNameConstants";
import { Attachment, AttachmentFileDetails, AttachmentHelper } from "../../types/Attachment";
import { Key } from "ts-key-enum";
import { Pill, PillType } from "../Attachments/Pill";
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-white-1.svg';
import { ReactComponent as FileDownloadIcon } from '../../assets/icons/fileDownload.svg';
import { ReactComponent as InfoWarning } from '../../assets/icons/warning.svg';
import { ReactComponent as RotateIcon } from '../../assets/icons/rotate.svg';
import CloseIcon from '../../assets/icons/close-black-white.png';
import Spinner from '../../assets/icons/spinner-white.png';
import { ImageViewer } from "./Viewers/ImageViewer";
import clsx from "clsx";
import { Thumbnail } from "./Viewers/Thumbnail";
import { RefNumber } from "../Attachments/RefNumber";
import attachmentFileStore from "../../stores/AttachmentFileStore";
import { AttachmentLoadState } from "../../enums/AttachmentLoadState";
import { valueFormatUtils } from "../../utils/valueFormatUtils";
import attachmentUrlApi from "../../api/attachmentUrlApi";
import { AttachmentErrorType } from "../../enums/AttachmentErrorType";
import Dialog from "../Dialog/Dialog";
import Button from "../Inputs/Button/Button";
import { OrganDamagePill } from "../Attachments/OrganDamagePill";
import React from "react";
import { parseBool } from "../../utils/boolUtils";
import fieldValues from "../../constants/referralFieldValueConstants";

interface Props {
    resourceBatchId: string,
    onClose: () => void,
    firstSelectedFieldId?: string
}

export const DocumentImageViewer = (props: Props) => {
    const [attachment, setAttachment] = useState<Attachment>();
    const [fileDetails, setFileDetails] = useState<AttachmentFileDetails[]>();
    const [currentFileIndex, setCurrentFileIndex] = useState(0);
    const [showClutter, setShowClutter] = useState(true);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const contentsRef = useRef<HTMLDivElement>(null);    
    const navigationButtonRef = useRef<HTMLButtonElement>(null);
    const closeButtonRef = useRef<HTMLButtonElement>(null);
    const rotateButtonRef = useRef<HTMLButtonElement>(null);
    
    useEffect(() => {
        const fieldValue = referralStore.getField(fieldNames.RESOURCEMETADATAS + props.resourceBatchId)!.toValue;
        const attachment = JSON.parse(fieldValue!) as Attachment
        setAttachment(attachment);

        const fieldDetails = AttachmentHelper.getFileDetails(attachment).filter(fileInfo => fileInfo.fileId);
        setFileDetails(fieldDetails);

        let index = -1;
        if (props.firstSelectedFieldId) {
            index = fieldDetails.findIndex(f => f.fileId === props.firstSelectedFieldId);
        }
        setCurrentFileIndex(index === -1 ? 0 : index);
        
        if (rotateButtonRef.current) {
            rotateButtonRef.current.focus();
        } else if (closeButtonRef.current) {
            closeButtonRef.current.focus();
        }   
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeydown);
        return () => {
           document.removeEventListener('keydown', handleKeydown);
        }
    });  

    const handleKeydown = (event: KeyboardEvent) => {
        switch (event.key) {
        case Key.Escape:
            props.onClose();
            break;
        case Key.ArrowLeft:
            selectPage(currentFileIndex - 1);
            event.stopPropagation();
            break;        
        case Key.ArrowRight:
            selectPage(currentFileIndex + 1);
            event.stopPropagation();
            break;     
        case Key.Control:
            setShowClutter(pre => !pre);
            event.stopPropagation();
            break;     
        }
    }

    const handleOnMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.button === 2) {
            event.preventDefault();
            event.stopPropagation(); 
        } else if (event.button === 0) {
            setShowClutter(prev => !prev);
            event.preventDefault();
            event.stopPropagation();
        }
    }

    const selectPage = (page: number, event?: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (fileDetails && page < fileDetails.length && page >= 0) {
            setCurrentFileIndex(page);
        }
    }

    const handleOnClickDownload = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const fileId = fileDetails![currentFileIndex].fileId;
        attachmentFileStore.setState(props.resourceBatchId, fileId, AttachmentLoadState.Loading);
        navigationButtonRef.current?.focus();        

        try {
            const urls = await attachmentUrlApi.getUrls(referralStore.referralId!, props.resourceBatchId, [fileId!]); 
            const url  = urls!.find(u => u.resourceId === fileId);
            await attachmentFileStore.download(props.resourceBatchId, fileId, url!.url, url!.filename, url!.paramsAsRequestHeaders);
        } catch (error: any) {
            attachmentFileStore.setState(props.resourceBatchId, fileId, AttachmentLoadState.Error, AttachmentErrorType.DownloadFailed);
            setShowErrorDialog(true);
            console.error('Error:', error.message);
        }    
    }
    
    function handleOnRotate(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        event.stopPropagation();
        if (fileDetails) {            
            const fileId = fileDetails![currentFileIndex].fileId
            const fileInfo = attachmentFileStore.getFileInfo(props.resourceBatchId, fileId);
            attachmentFileStore.setRotation(props.resourceBatchId, fileId, fileInfo.rotation + 90);
        }
    }

    const renderIcon = (state: AttachmentLoadState, fileId: string) => {
        switch (state) {
        case AttachmentLoadState.None:
        case AttachmentLoadState.Error: 
            return <FileDownloadIcon />
        case AttachmentLoadState.Loading: 
            return <img src={Spinner} className="spin" alt="spinner" />;
        }
    }   
    
    const renderSize = (state: AttachmentLoadState, fileSizeKb: number | undefined) => {
        if (state === AttachmentLoadState.None || state === AttachmentLoadState.Error) {
            return <div className="size">{valueFormatUtils.formatSize(fileSizeKb)}</div>;
        } else {
            return <></>;
        }  
    }        

    const renderHeaderLeft = () => {        
        return <div className='image-viewer-header-left-corner'>
                    <div>
                        {showClutter && fileDetails && <RefNumber fileId={fileDetails[currentFileIndex].fileId!} />}
                        {showClutter && attachment?.category ? <Pill type={PillType.Category} code={attachment?.category} /> : <div />}
                    </div>
                    {showClutter && attachment?.type === fieldValues.ATTACHMENT.TYPE.DOCUMENT_IMAGE && 
                        <button ref={rotateButtonRef} className="rotate-btn" type="button" onClick={handleOnRotate} title='rotate' tabIndex={1}><RotateIcon /></button>}
                </div>
    }

    const renderHeaderRight = () => {        
        return <div className='image-viewer-header-right-corner'>
                   <button ref={closeButtonRef} type="button" className="btn-close-desktop" onClick={props.onClose} title='close' tabIndex={2}>Close preview</button>
                   <button type="button" className="btn-close-mobile" onClick={props.onClose}  title='close' tabIndex={2}>
                        <img src={CloseIcon} alt="close" />
                   </button>
               </div>
    }

    const renderContent = () => {
        var fileInfo = fileDetails && attachmentFileStore.getFileInfo(props.resourceBatchId, fileDetails[currentFileIndex].fileId);
        if (!fileDetails) {
            return <></>;
        } else if (!fileInfo?.data) {
            return <div className="empty">
                        <button onClick={handleOnClickDownload} tabIndex={(fileInfo?.state === AttachmentLoadState.Loading ? -1 : 8)}>
                            {renderIcon(fileInfo!.state, fileDetails[currentFileIndex].fileId!)}
                            {renderSize(fileInfo!.state, fileDetails[currentFileIndex].fileSizeKb)}
                        </button>
                    </div>;
        } else {
            return <ImageViewer key={fileDetails![currentFileIndex].fileId} resourceBatchId={props.resourceBatchId} fileId={fileDetails![currentFileIndex].fileId} />;
        }
    }

    const renderFooter = () => {  
        if (showClutter && attachment?.organCategory) {
            return <div className='image-viewer-footer'>
                {!attachment?.description ? <div></div> :
                    <>
                        <div className='title'>Comments</div>
                        <div className='description'>{attachment?.description}</div>
                    </>}
                <div className="pills">
                    <Pill type={PillType.Organ}         code={attachment?.organCategory} />&nbsp;
                    <Pill type={PillType.OrganLocation} code={attachment?.organLocation} />&nbsp;
                    {parseBool(attachment.hasDamage) && <OrganDamagePill />}
                </div>
            </div>
        } else {
            return <></>
        }
    }
    
    const renderNavigationArrow = (arror: string, show: boolean, tabIndex: number, onClick: MouseEventHandler<HTMLButtonElement>) => {
        if (!show) {
            return <div></div>
        }

        var titleText = `${arror === 'left' ? 'previous' : 'next'} image`;
        return <button className={clsx("navigation-arrow", arror)} onClick={onClick} title={titleText} tabIndex={tabIndex} ref={navigationButtonRef}>
                    <ArrowIcon />
               </button>
    }

    const renderThumbnails = () => {
        if (fileDetails) {
            return fileDetails.map((f, index) => <Thumbnail resourceBatchId={props.resourceBatchId} 
                                                            fileId={f.fileId!} 
                                                            onClick={() => selectPage(index)} 
                                                            selected={index === currentFileIndex}
                                                            tabIndex={index + 2} />);
        } else {
            return <></>;
        }
    }

    const fileInfo = fileDetails ? attachmentFileStore.getFileInfo(props.resourceBatchId, fileDetails[currentFileIndex].fileId) : undefined;
    const containerClass = clsx({
        'container': true,
        'vertical': fileInfo && (fileInfo.rotation === 90 || fileInfo.rotation === 270),
        'horizontal': fileInfo && !(fileInfo.rotation === 90 || fileInfo.rotation === 270),
    });
    
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
    return <div className="image-viewer-overlay" onClick={handleOnMouseDown} onContextMenu={handleOnMouseDown}>
                <div className="image-viewer-gradient">
                    {renderHeaderLeft()}
                    {showClutter && <div className='thumbnail-container'>{renderThumbnails()}</div>}
                    {renderHeaderRight()}
                    <div className='navigation'>
                            {renderNavigationArrow("left", showClutter && !!fileDetails && currentFileIndex > 0, 6, (event) => selectPage(currentFileIndex-1, event))}
                            {renderNavigationArrow("right",showClutter && !!fileDetails && currentFileIndex < fileDetails.length-1, 8, (event) => selectPage(currentFileIndex+1, event))}
                    </div>
                    <div className={containerClass}>
                        <div className='contents scroll-style-1' style={{ justifyContent: "center", backgroundColor: "transparent" }} ref={contentsRef}>
                            {renderContent()}
                        </div>
                    </div>
                    {renderFooter()}
                </div>
                {showErrorDialog &&
                <Dialog maxWidth="sm" className="download-error-dialog" onClose={() => setShowErrorDialog(false)}>
                    <InfoWarning />
                    <div>
                        <h2>Error downloading image</h2>
                        <div className="dialog-text">Please try again</div>
                    </div>
                    <Button text="OK" onClick={(event) =>  { event.stopPropagation(); setShowErrorDialog(false) }} />
                </Dialog>}
            </div>    
}