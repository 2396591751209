import moment from "moment";
import { complexDateUtil } from "./complexDateUtil";

export class StringUtil {
    static readonly greekCharacters = /[α-ωΑ-Ω]/gi;

    static truncate(text: string, len: number = 20) {
        return text?.length > len ? `${text.substring(0, len)}...` : text;
    }

    static toLowerCaseFirstLetter(value: string | undefined | null): string | undefined | null {
        return !value ? value : value.replace(/^\w/, c => c.toLowerCase());
    }

    static toUpperCaseIgnoreGreekChar(value: string): string {
        const greekCharInValue = value.match(StringUtil.greekCharacters);
        if (!!greekCharInValue) {
            let updatedValue = "";
            value.split("").forEach(char => {
                updatedValue += greekCharInValue.includes(char) ? char : char.toUpperCase();
            });
            return updatedValue;
        } else {
            return value.toUpperCase();
        }
    };

    static capitalizeEachWord(value: string | null): string {
        if (!value) {
            return '';
        }

        var words = value.split(" ");
        var result = "";
        for (var i = 0; i < words.length; i++) {
            var word = words[i];
            const greekCharInValue = word.charAt(0).match(StringUtil.greekCharacters);
            if (!greekCharInValue) {
                word = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }

            result += word + " ";
        }
        return result.trimEnd();
    }

    /* Only capitalize the each letter of the string if all letters are upper or lower case  */
    static capitalizeEachWordIfRequired(value: string | null): string {
        if (!value) {
            return '';
        }

        const uppercaseMatches = value.match(/[A-Z]/g) || [];
        const lowercaseMatches = value.match(/[a-z]/g) || [];
    
        if (uppercaseMatches.length > 0 && lowercaseMatches.length === 0 ||
            lowercaseMatches.length > 0 && uppercaseMatches.length === 0) {
            return this.capitalizeEachWord(value);
        } else {
            return value;
        }
    }

    static formatNHSNumber(value: string): string | null | undefined {
        const groups = [];
        for (let i = value.length; i > 0; i -= 3) {
            groups.push(value.substring(i - Math.min(3, i), i));
        }
        return groups.reverse().join(" ");
    }

    static formatPhoneNumber(value: string): string | null | undefined {
        value = value.trim();
        switch (value.length)
        {
        case 10: return value.substring(0, 3) + " " + value.substring(3, 6) + " " + value.substring(6, 10);
        case 11: return value.substring(0, 4) + " " + value.substring(4, 7) + " " + value.substring(7, 11);
        case 12: return value.substring(0, 4) + " " + value.substring(4, 8) + " " + value.substring(8, 12);
        }
        return value;
    }

    static formatMonthYear(value: string): string | null | undefined {
        try{
            const date = complexDateUtil.convertStringToDate(value);
            if(!date){
                throw Error();
            }
            return date.format("MM/YYYY");
        }catch(e) {
            console.error('Could not format date to MM/YYYY');
            return value;
        }       
    }    
}
