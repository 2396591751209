import lastViewedApi from "../api/lastViewedApi";
import { ReferralFromSearch } from "../api/Models/ReferralFromSearch";
import { SettingConstants } from "../constants/settingConstants";
import BaseHub, { HubMethodMap } from "./baseHub";

class LastViewedHub extends BaseHub {
    public constructor() {
        super(`${process.env.REACT_APP_TRANSPLANTPATH_API_BASEURL}/Hub/LastViewed`, 
              SettingConstants.WEB_CLIENT_LANDINGPAGE_UPDATE_POLL_INTERVAL,
              LastViewedHub.manuallyPollEndPoint)
    }

    public onNewUpdates(newMethod: (newUpdates: ReferralFromSearch[]) => void) {
        this.on("NewUpdates", newMethod);
    }

    public offNewUpdates(newMethod: (newUpdates: ReferralFromSearch[]) => void) {
        this.off("NewUpdates", newMethod);
    }    

    protected static async manuallyPollEndPoint(methodMap: HubMethodMap) {
       var lastViewed = await lastViewedApi.getLastViewed(true);
       methodMap["NewUpdates"](lastViewed as ReferralFromSearch);
    }
}

export const lastViewedHub = new LastViewedHub();
