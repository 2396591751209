import moment from "moment";
import { SettingConstants } from "../../constants/settingConstants";
import referenceDataStore from "../../stores/ReferenceDataStore";

interface Props {
    odtNumber: string;
    dob: string;
    hospital: string;
    donorType: string;
    sex: string;
    createdDate?: string
}

export const DonorDetailsContent = ({ odtNumber, dob, hospital, donorType, sex, createdDate }: Props) => {
    var setting = referenceDataStore.settings.getSettingAsDate(SettingConstants.UPDATEDATE_GENDERCHANGEPHASE1);
    var sexLabel = setting && createdDate && moment(createdDate) >= setting ? "Sex registered at birth": "Sex";

    return <div className="donor-details">
        <p className="name">ODT Number</p>
        <p className="value">{odtNumber}</p>

        <p className="name">Date of Birth</p>
        <p className="value">{dob}</p>

        <p className="name">Hospital</p>
        <p className="value">{hospital}</p>

        <p className="name">Donor Type</p>
        <p className="value">{donorType}</p>

        <p className="name">{sexLabel}</p>
        <p className="value">{sex}</p>
    </div>
}