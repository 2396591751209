import moment from "moment";
import { RefCodeToCodeSet } from "../stores/CodeSetStore";
import { ChangeField } from "../types/ChangeField";
import { complexDateUtil } from "./complexDateUtil";

export interface ComplexSortOptions {
    isAscending?: boolean,
    sortField: string,
    isDateTime: boolean,
    codeSetLookup?: RefCodeToCodeSet
}

interface ParsedSortField  {
    fieldData: ChangeField,
    value: any
}

export class complexCollectionSortUtil {
    private static codeSetParser(value: any, codeSetLookup?: RefCodeToCodeSet) {
        return !value ? null : codeSetLookup![value];
    }

    private static dateTimeParser(value: any, codeSetLookup?: RefCodeToCodeSet) {
        return !value ? null : moment(value).valueOf();
    }

    private static defaultParser(value: any, codeSetLookup?: RefCodeToCodeSet) {
        return value;
    }

    private static sortFieldsByParameter(parsedValues: ParsedSortField[], isAscending: boolean): ParsedSortField[] {
        return parsedValues.sort((a, b) => {
            if (a.value === null) {
                return 1;
            }
            if (b.value === null) {
                return -1;
            }
    
            if (isAscending) {
                return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
            } else {
                return a.value > b.value ? -1 : a.value < b.value ? 1 : 0;
            }
        });
    }

    /**
     * Sorts a set of slots by a specificed property and ignore null values
     * @param fieldData - An object containing each of the fields value
     * @param options
     * @returns {Array} - A sorted list of field names. Does not contain the field data
     */
    public static sortUnboundedSlotsByField(fieldData: ChangeField[], options: ComplexSortOptions): ChangeField[] {
        fieldData = fieldData.filter(c => c.toValue !== null && c.toValue !== undefined);
        
        if (fieldData.length <= 1) {
            return fieldData;
        }

        var parsedValues = fieldData.map<ParsedSortField>(c => {
            var complexObj = JSON.parse(c.toValue!);
            var value      = complexObj[options.sortField];
            return {
                fieldData: c,
                value: value
            }
        });

        if (options.codeSetLookup) {
            parsedValues.forEach(c => c.value = !c.value ? null : options.codeSetLookup![c.value]);
        } else if (options.isDateTime) {
            parsedValues.forEach(c => c.value = complexDateUtil.convertStringToDate(c.value));
        }

        return complexCollectionSortUtil.sortFieldsByParameter(parsedValues, options.isAscending || false).map(c => c.fieldData);
    }
}
