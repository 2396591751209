/**
 * Used to convert a C# TimeSpan to a numeric value
 */
export class ConvertTimeSpan {
   // Converts timeSpan string to minutes
   static toMinutes(span?: string | null): number | undefined | null {
      if (!span) {
         return <undefined | null>span;
      }

      var result = 0;
      var index = 0;
      var splitSpan = span.split(':');
      if (splitSpan.length === 4) {
         result += parseInt(splitSpan[index++]) * 24 * 60;
      }
      result += parseInt(splitSpan[index++]) * 60;
      result += parseInt(splitSpan[index++]);
      result += parseInt(splitSpan[index++]) / 60;
      return result;
   }
}
