import { StoreLoadingStateTypes } from '../enums/StoreLoadingStateTypes';
import { CodeSetStore, RefCodeToCodeSet } from './CodeSetStore';
import { FieldInformationStore, FieldInformation } from './FieldInformationStore';
import { PageLayoutStore } from './PageLayoutStore';
import { SettingStore } from './SettingStore';
import { makeObservable, observable, action, computed } from 'mobx';
import { ReferenceDataModel } from '../api/Models/referenceDataModel';
import { SignalDispatcher } from 'strongly-typed-events';
import referenceDataApi from '../api/referenceDataApi';
import { BcMessageStore } from './BcMessageStore';

export class ReferenceDataStore {
   private _onReferenceDataInitialised = new SignalDispatcher();
   private _onReferenceDataChanged = new SignalDispatcher();

   fields: FieldInformationStore;
   codeSets: CodeSetStore;
   settings: SettingStore;
   pageLayout: PageLayoutStore;
   bcMessage: BcMessageStore;
   state: StoreLoadingStateTypes = StoreLoadingStateTypes.Empty;
   lastFieldVersion = 0;
   lastCodeSetVersion = 0;
   lastPageLayoutVersion = 0;

   constructor() {
      makeObservable(this, {
         pageLayout: observable,
         state: observable,
         setLoading: action,
         updateFromServer: action,
         bcMessage: observable,
         isLoaded: computed,
         isLoading: computed
      });
      this.fields = new FieldInformationStore();
      this.codeSets = new CodeSetStore();
      this.settings = new SettingStore();
      this.pageLayout = new PageLayoutStore();
      this.bcMessage = new BcMessageStore();

      referenceDataApi.onResponseSuccess.subscribe((sender, args) => this.updateFromServer(args));
   }

   public clear() {
      this.fields    = new FieldInformationStore();
      this.codeSets  = new CodeSetStore();
      this.settings  = new SettingStore();
      this.pageLayout= new PageLayoutStore();
      this.bcMessage = new BcMessageStore();
      this.state     = StoreLoadingStateTypes.Empty;
      this.lastFieldVersion = 0;
      this.lastCodeSetVersion = 0;
      this.lastPageLayoutVersion = 0;
   }   

   setLoading() {
      if (this.state === StoreLoadingStateTypes.Empty) {
         this.state = StoreLoadingStateTypes.Loading;
      }
   }

   public get isLoaded(): boolean {
      return this.state === StoreLoadingStateTypes.Ready;
   }

   public get isLoading(): boolean {
      return this.state === StoreLoadingStateTypes.Loading;
   }

   public get onReferenceDataInitialised() {
      return this._onReferenceDataInitialised.asEvent();
   }

   public get onReferenceDataChanged() {
      return this._onReferenceDataChanged.asEvent();
   }

   public getFieldInfo(fieldName: string | undefined): FieldInformation | undefined {
      return this.fields.getFieldInfo(fieldName);
   }

   public getCodeSetsByFieldName(fieldKey: string): RefCodeToCodeSet | undefined {
      var fieldInfo = this.fields.getFieldInfo(fieldKey);
      if (!fieldInfo || !fieldInfo.codeSetType) {
         return undefined;
      }
      return this.codeSets.getCodeSet(fieldInfo.codeSetType);
   }

   public getCodeSetText(fieldKey: string, refCode: string | undefined | null): string | undefined | null {
      if (!refCode) {
         return refCode;
      }

      var fieldInfo = this.fields.getFieldInfo(fieldKey);
      if (!fieldInfo || !fieldInfo.codeSetType) {
         return undefined;
      }
      
      return this.codeSets.getCodeSetText(fieldInfo.codeSetType, refCode);
   }

   updateFromServer(model: ReferenceDataModel) {
      this.fields.updateFromServer(model.fields);
      this.codeSets.updateFromServer(model.codeSets);
      this.pageLayout.updateFromServer(model.pageLayouts);
      this.settings.updateFromServer(model.settings);
      this.bcMessage.updateFromServer(model.message);
      if (
         this.lastFieldVersion !== model.fieldVersion ||
         this.lastCodeSetVersion !== model.codeSetVersion ||
         this.lastPageLayoutVersion !== model.pageLayoutVersion
      ) {
         this.lastFieldVersion      = model.fieldVersion;
         this.lastCodeSetVersion    = model.codeSetVersion;
         this.lastPageLayoutVersion = model.pageLayoutVersion;

         if (this.state !== StoreLoadingStateTypes.Ready) {
            this.state = StoreLoadingStateTypes.Ready;
            this._onReferenceDataInitialised.dispatch();
         }
         this._onReferenceDataChanged.dispatch();
      }
   }
}

const referenceDataStore = new ReferenceDataStore();
export default referenceDataStore;
