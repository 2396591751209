import { baseApi } from "./baseApi";

export class AttachmentDownload extends baseApi {
    public download = async (url: string, filename: string): Promise<void> => {
        return await super.download(url, filename);
    }

    public downloadToArrayBuffer = async (url: string, paramsAsRequestHeaders: boolean, suppressEvents?: boolean, callbackId?: string) => {
        return await super.downloadToArrayBuffer(url, paramsAsRequestHeaders, false, callbackId);
    }
}

const attachmentDownload = new AttachmentDownload();
export default attachmentDownload;