import { useEffect, useRef, useState } from 'react';
import { Breadcrumb } from '../../components/Header/Breadcrumb/Breadcrumb';
import { BreadcrumbNav } from '../../components/Header/BreadcrumbNav/BreadcrumbNav';
import { Header } from '../../components/Header/Header';
import historyStore from '../../stores/HistoryStore';
import RadioButton from '../../components/RadioButton/RadioButton';
import cookieStore from '../../stores/CookieStore';
import { Key } from 'ts-key-enum';

const CookiePrivacy = () => {
   const [previousLinkRoute, setPreviousLinkRoute] = useState('');
   const [previousLinkText, setPreviousLinkText] = useState('');
   const [activeTab, setActiveTab] = useState();
   const [allowNoneEssentialCookies, setAllowNoneEssentialCookies] = useState(cookieStore.allowNoneEssentialCookies);
   const sectionRef = useRef<HTMLDivElement>(null);
   const keyboardScrollAmount = 30;

   useEffect(() => {
      var url = historyStore.getPrevious(true);

      setPreviousLinkRoute(url || '/');

      var previousLinkText = 'Log in';
      if (url && url.toLocaleLowerCase().indexOf('/referral') > -1) {
         previousLinkText = 'Donor Record';
      } else if (url && url.toLocaleLowerCase().indexOf('/search') > -1) {
         previousLinkText = 'Search';
      }
      setPreviousLinkText(previousLinkText);
   }, [historyStore.history]);

   useEffect(() => {
     document.addEventListener('keydown', handleKeydown)
     return () => {
        document.removeEventListener('keydown', handleKeydown);
     }
   })

   const breadcrumb = <Breadcrumb previousLinkRoute={previousLinkRoute} previousLinkText={`Back to ${previousLinkText}`} />

   const handleKeydown = (event: KeyboardEvent) => {
     if (event.key === Key.ArrowDown) {
        sectionRef.current?.scrollBy({top: keyboardScrollAmount});
     }else if (event.key === Key.ArrowUp) {
        sectionRef.current?.scrollBy({top: -keyboardScrollAmount});
     }
   }
   
   const onChangeCookieOption = (value: string) => {
      if (value === 'Accept') {
         cookieStore.allowNoneEssentialCookies = true;
      } else if (value === 'Reject') {
         cookieStore.allowNoneEssentialCookies = false;
         cookieStore.clearNoneEssentialCookies();
      }
      setAllowNoneEssentialCookies(cookieStore.allowNoneEssentialCookies);
   }

   return (
      <>
         <Header leftContentMobile={breadcrumb} activeTab={activeTab} setActiveTab={setActiveTab} showAlerts/>
         <BreadcrumbNav hideOnMobile pageTitle={"Privacy and cookies"} previousLinkRoute={previousLinkRoute} previousLinkText={previousLinkText} />
         <section className="privacy" ref={sectionRef}>
            <div className="container">
               <h1>Privacy and cookies</h1>
               <p>
                  <h2>What are cookies?</h2>
                  <div>
                     Cookies are files saved on your phone, tablet or computer when you visit a website. They store information about how you use the website, 
                     such as the pages you visit. Cookies are not viruses or computer programs. They are very small so do not take up much space. To find out 
                     more about your personal data visit our privacy page
                  </div>
               </p>

               <p>
                  <h2>Essential Cookies we use</h2>
                  <div className="cookies-we-use">
                     <p>
                        We use essential cookies to provide you with a seamless and secure authentication experience, and to audit your activities for 
                        compliance and security purposes. Access to TransplantPath requires using a federated authentication cookie on your device, to 
                        validate your access against a local health authority.
                     </p>

                     <p>
                        We will use this session information, to track which records you view, and images\attachments that you download, this information 
                        will be stored along with your email and health authority details, in order to comply with our legal and regulatory obligations, 
                        and to maintain the security, patient safety, and integrity of our web app and your data. These essential cookies are not optional.
                     </p>
                  </div>

                  <h2>Analytic Cookies we use</h2>
                  <div className="cookies-we-use">
                     <p>
                        Analytics cookies are used to better understand how users interact with the application, so we can make suitable improvements, and help 
                        resolve technical issues
                     </p>

                     <p>
                        The tracking activities are carried out in a lawful, fair, and transparent manner, and are subject to appropriate technical and organisational 
                        measures to ensure the security and confidentiality of your details. Beyond the details stated above we don’t store any other sensitive or personal 
                        information about you, and we do not share your details with third parties, except as required by law, for technical support, or with your explicit 
                        consent.
                     </p>

                     <p>
                        You can manage or delete cookies at any time using your web browser settings. However, please note that disabling or deleting cookies may affect the 
                        functionality and performance of our web app. If you do not wish to be tracked, you can opt out adjusting your cookie settings.
                     </p>

                     <p>
                        By using our web app and accepting our cookie policy, you consent to the use of cookies and the tracking activities as described in this policy. If you 
                        have any questions or concerns about our use of cookies and tracking activities, please contact us.
                     </p>
                  </div>

                  <h2>Optional cookies</h2>
                  <div className="cookies-we-use">
                     <p>Analytic cookies are optional, though they are used to help us make improvements, and resolve technical issues</p>
                  </div>
                  
                  <div className='cookie-button-container'>
                     <RadioButton value='Accept' label='I’m OK with analytics cookies' checked={allowNoneEssentialCookies === true}  onChange={onChangeCookieOption}></RadioButton>
                     <RadioButton value='Reject' label='Do not use analytics cookies'  checked={allowNoneEssentialCookies === false} onChange={onChangeCookieOption}></RadioButton>
                  </div>

               </p>
            </div>
         </section>
      </>
   );
};

export default CookiePrivacy;
