import { action, makeObservable, observable } from "mobx";
import { AttachmentFilter, AttachmentFilterToType } from "../enums/AttachmentFilter";
import { AttachmentLoadState } from "../enums/AttachmentLoadState";
import { EventDispatcher } from "strongly-typed-events";
import { AttachmentErrorType } from "../enums/AttachmentErrorType";
import attachmentDownload from "../api/attachmentDownload";
import referralStore from "./ReferralStore";
import { fieldNames } from "../constants/fieldNameConstants";
import { Attachment } from "../types/Attachment";
import eventApi from "../api/eventApi";
import { SystemEventType } from "../enums/SystemEventType";
import fieldValues from "../constants/referralFieldValueConstants";

interface AttachmentInfo {
    url?: string, 
    filename?: string,
    data?: ArrayBuffer,
    state: AttachmentLoadState,
    error: AttachmentErrorType,
    blobUrl?: string,
    rotation: number,
}

const TypeToText  = {
    [ fieldValues.ATTACHMENT.TYPE.FILE           ]: "file",
    [ fieldValues.ATTACHMENT.TYPE.DOCUMENT_IMAGE ]: "file",
    [ fieldValues.ATTACHMENT.TYPE.VIDEO          ]: "video",
    [ fieldValues.ATTACHMENT.TYPE.IMAGE          ]: "image",
}

class AttachmentStore {
    filter = AttachmentFilter.Files;
    showImages = true;
    protected showImagesPerBatch = new Set<string>();
    protected cachedFileInfo = new Map<string,AttachmentInfo>();    

    private _onChange = new EventDispatcher<AttachmentStore,string | undefined>();   

    constructor() {
        makeObservable(this, {
            filter: observable,
            showImages: observable,
            setFilter: action,
            setShowImages: action,
            setRotation: action,
        });        
    }

    public get onChange() {
        return this._onChange.asEvent();
    }

    public getState(resourceBatchId: string, resourceId: string | undefined): AttachmentLoadState {
        return this.getFileInfo(resourceBatchId, resourceId).state;
    }

    public setState(resourceBatchId: string, resourceId: string | undefined, state: AttachmentLoadState, error: AttachmentErrorType = AttachmentErrorType.None) {
        var fileInfo = this.getFileInfo(resourceBatchId, resourceId);
        var updated = fileInfo.state !== state || fileInfo.error !== error;

        fileInfo.state = state;
        fileInfo.error = error;

        if (updated) {
            this._onChange.dispatch(this, resourceBatchId);
        }
    }

    public getError(resourceBatchId: string, resourceId: string | undefined): AttachmentErrorType {
        return this.getFileInfo(resourceBatchId, resourceId).error;
    }

    public getErrorMessage(errorType: AttachmentErrorType, type: string | undefined | null): string | undefined {
        var typeText = type ? TypeToText[type] : 'file';

        switch (errorType) {
        case AttachmentErrorType.DownloadFailed:  return `Cannot load ${typeText}, please try again`;
        case AttachmentErrorType.UnsupportedType: return `Unsupported ${typeText} type`;
        case AttachmentErrorType.RenderError:     return `Failed displaying ${typeText}`;
        }

        return undefined;
    }

    public setFilter(filter: AttachmentFilter): void {
        this.filter = filter;
    }

    public getFileInfo(resourceBatchId: string, resourceId: string | undefined): AttachmentInfo {
        var fileInfo = this.cachedFileInfo.get(this.createCacheKey(resourceBatchId, resourceId));
        if (!fileInfo) {
            fileInfo = { state: AttachmentLoadState.None, error: AttachmentErrorType.None, rotation: 0 };
            this.cachedFileInfo.set(this.createCacheKey(resourceBatchId, resourceId), fileInfo);
        }
        
        return fileInfo;
    }

    public setShowImages(showCommentsOnly: boolean): void {
        this.showImages = showCommentsOnly;
        this.showImagesPerBatch.clear();
        this._onChange.dispatch(this, undefined);
    }

    public setShowBatchImages(resourceBatchId: string): void {
        this.showImagesPerBatch.add(resourceBatchId);

        // check if all images are shown if so then disable the show comments switch
        var mediaTypes = AttachmentFilterToType[AttachmentFilter.Media];
        var mediaFieldCount = referralStore.getFieldCollection(fieldNames.RESOURCEMETADATAS)
                                           .filter(f => f.toValue)
                                           .map(f => JSON.parse(f.toValue!) as Attachment)
                                           .filter(a => mediaTypes.includes(a.type))
                                           .length;
                                       
        if (mediaFieldCount === this.showImagesPerBatch.size) {
            this.showImages= true;
        }                                           

        this._onChange.dispatch(this, resourceBatchId);
    }

    public setRotation(resourceBatchId: string, fileId: string | undefined, rotation: number): void {
        var fileInfo = this.cachedFileInfo.get(this.createCacheKey(resourceBatchId, fileId));
        if (rotation >= 360) {
            rotation = 0;
        }
        if (fileInfo && fileInfo.rotation !== rotation) {
            fileInfo.rotation = rotation;
            this._onChange.dispatch(this, undefined);
        }
    }

    public ifShowImages(resourceBatchId: string): boolean {
        return this.showImages || this.showImagesPerBatch.has(resourceBatchId);
    }

    public async download(resourceBatchId: string, resourceId: string | undefined, url: string, filename: string, paramsAsRequestHeaders: boolean): Promise<void> {
        this.setState(resourceBatchId, resourceId, AttachmentLoadState.Loading);

        var fileInfo = this.getFileInfo(resourceBatchId, resourceId);
        fileInfo.url = url;
        fileInfo.filename = filename;
            
        var loaded = false;
        try {
            const response = await attachmentDownload.downloadToArrayBuffer(url, paramsAsRequestHeaders);
            fileInfo.data = response!.data;
            fileInfo.blobUrl = URL.createObjectURL(new Blob([fileInfo.data!]));

            this.setState(resourceBatchId, resourceId, AttachmentLoadState.Loaded);

            loaded = true;
        } catch (error: any) {
            this.setState(resourceBatchId, resourceId, AttachmentLoadState.Error, AttachmentErrorType.DownloadFailed);
            console.error('Error:', error.message);
        }

        try {
            if (loaded) {
                var referralStatus = referralStore.getField(fieldNames.REFERRALSTATUS_STATUS);
                var eventMessage = `Download attachment ${resourceBatchId} - ${resourceId || resourceBatchId} - ${referralStatus?.toValue || ''}`;
                eventApi.postEvent(referralStore.referralId!, SystemEventType.OpenAttachment, eventMessage);
            }
        }
        catch (error: any) {
        }
    }

    public clear() {
        this.cachedFileInfo.forEach(fileInfo => {
            if (fileInfo.blobUrl) {
              URL.revokeObjectURL(fileInfo.blobUrl!);
            }
        });        
        this.filter = AttachmentFilter.Files;
        this.showImages = true;
        this.showImagesPerBatch.clear();
        this.cachedFileInfo.clear();
    }

    private createCacheKey(resourceBatchId: string, resourceId: string | undefined): string {
        return `${resourceId || resourceBatchId}-${resourceBatchId}`
    }
}

const attachmentFileStore = new AttachmentStore();
export default attachmentFileStore;
