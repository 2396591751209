import clsx from 'clsx';
import { DrugTypes } from '../../enums/drugType';

interface Props {
   currentRateInfusion?: string;
   maxRateInfusion?: string;
   drugType: string;
   dateCommenced?: string;
   dateOfMaxDosage?: string;
   name: string | null;
   administered?: boolean;
   quantity?: string;
   diluent?: string;
   infusionAdministered?: boolean;
   loadingDoseAdministered?: boolean;
   dose?: string;
   drug?: string;
   currentBloodGlucoseLevel?: string;
   dateOfMaxRate?: string;
   currentDosageUgKgMinCalc?: string;
   maxDosageUgKgMinCalc?: string;
   detail?: string;
   hasUpdates?: boolean;
}

const Drug = ({
   currentRateInfusion,
   maxRateInfusion,
   drugType,
   dateCommenced,
   dateOfMaxDosage,
   name,
   administered,
   quantity,
   diluent,
   infusionAdministered,
   loadingDoseAdministered,
   dose,
   dateOfMaxRate,
   drug,
   currentBloodGlucoseLevel,
   currentDosageUgKgMinCalc,
   maxDosageUgKgMinCalc,
   detail,
   hasUpdates
}: Props) => {
   const [_dateCommenced, _timeCommenced] = dateCommenced?.split(' ') || [];
   const [dateMaxDosage, timeMaxDosage] = dateOfMaxDosage?.split(' ') || [];
   const [_dateOfMaxRate, _timeOfMaxRate] = dateOfMaxRate?.split(' ') || [];

   const drugContent = () => {
      switch (drugType) {
         case DrugTypes.Type1:
            return (
               <div className="drug-body">
                  <div className="type-one">
                     <div className="administered-value">
                        <span className="key">Administered</span>
                        <span className="value">
                           <span style={{ marginRight: '12px' }}>{_dateCommenced}</span>
                           <span>{_timeCommenced}</span>
                        </span>
                     </div>
                     <div className="dosage">
                        <span className="key">
                           Dosage <span className="unit">(mg)</span>
                        </span>
                        <span className="value">{quantity}</span>
                     </div>
                  </div>
               </div>
            )
         case DrugTypes.Type2:
            return (
               (
                  <div className="drug-body">
                     <div className="type-two">
                        <div className="dates">
                           <div className="commenced">
                              <span className="key">Date/Time Commenced</span>
                              <span className="value">
                                 <span style={{ marginRight: '12px' }}>{_dateCommenced}</span>
                                 <span>{_timeCommenced}</span>
                              </span>
                           </div>
                           <div className="max-dosage">
                              <span className="key">Date/Time of Max Dosage</span>
                              <span className="value">
                                 <span style={{ marginRight: '12px' }}>{dateMaxDosage}</span>
                                 <span>{timeMaxDosage}</span>
                              </span>
                           </div>
                        </div>
                        <div className="right-parent">
                           <div className="key">
                              Dosage <span className="unit">(μg/kg/min)</span>
                           </div>
                           <div className="right-inner">
                              <div className="current">
                                 <div className="key">Current</div>
                                 <span className="value">{currentDosageUgKgMinCalc}</span>
                              </div>
                              <div className="max">
                                 <div className="key">Max</div>
                                 <span className="value">{maxDosageUgKgMinCalc}</span>
                              </div>
                           </div>
                        </div>
                     </div>

                     <hr />

                     <div className="type-two">
                        <div className="dates">
                           <div className="commenced">
                              <span className="key">Current rate of infusion <span className="unit">(ml/hr)</span></span>
                              <span className="value">
                                 <span style={{ marginRight: '12px' }}>{currentRateInfusion}</span>
                              </span>
                           </div>
                           <div className="max-dosage">
                              <span className="key">Max rate of infusion <span className="unit">(ml/hr)</span></span>
                              <span className="value">
                                 <span style={{ marginRight: '12px' }}>{maxRateInfusion}</span>
                              </span>
                           </div>
                        </div>
                        <div className="right-parent right-inner" style={{ marginTop: '0px' }}>
                           <div className="current">
                              <div className="key">Drug <span className="unit">(mg)</span></div>
                              <span className="value">{quantity}</span>
                           </div>
                           <div className="max">
                              <div className="key">Diluent <span className="unit">(ml)</span></div>
                              <span className="value">{diluent}</span>
                           </div>
                        </div>
                     </div>

                  </div>
               )
            );
         case DrugTypes.Type3:
         case DrugTypes.Type5:
            return (
               <div className="drug-body">
                  <div className="loading-dose">
                     <div className="parent first">
                        <span className="key">Loading dose administered?</span>
                        <span className="value">{loadingDoseAdministered === undefined ? "" : (loadingDoseAdministered ? 'Yes' : 'No')}</span>
                     </div>
                     <div className="parent second">
                        <span className="key">Dose (units)</span>
                        <span className="value">{dose}</span>
                     </div>
                  </div>

                  <div className="type-two">
                     <div className="dates">
                        <div className="commenced">
                           <span className="key">Infusion administered</span>
                           <span className="value">
                              <span style={{ marginRight: '12px' }}>{infusionAdministered === undefined ? "" : (infusionAdministered ? 'Yes' : 'No')}</span>
                           </span>
                        </div>
                        <div className="commenced">
                           <span className="key">Date/Time Commenced</span>
                           <span className="value">
                              <span style={{ marginRight: '12px' }}>{_dateCommenced}</span>
                              <span>{_timeCommenced}</span>
                           </span>
                        </div>
                        <div className="drug-diluent">
                           <div className="commenced first">
                              <span className="key">Drug (units)</span>
                              <span className="value">
                                 <span style={{ marginRight: '12px' }}>{drug}</span>
                              </span>
                           </div>
                           <div className="max-dosage">
                              <span className="key">Diluent(ml)</span>
                              <span className="value">
                                 <span style={{ marginRight: '12px' }}>{diluent}</span>
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="diluent">
                     <div className="parent third">
                        <span className="key">Max rate of infusion (ml/hr)</span>
                        <span className="value">
                           <span style={{ marginRight: '12px' }}>{maxRateInfusion}</span>
                        </span>
                     </div>
                     <div className="parent first">
                        <span className="key">Date/Time of Max Rate</span>
                        <span className="value">
                           <span style={{ marginRight: '12px' }}>{_dateOfMaxRate}</span>
                           <span>{_timeOfMaxRate}</span>
                        </span>
                     </div>
                  </div>

                  <div className="glucose">
                     <div className="parent">
                        <span className="key">Current rate of infusion (ml/hr)</span>
                        <span className="value">
                           <span style={{ marginRight: '12px' }}>{currentRateInfusion}</span>
                        </span>
                     </div>
                     {name === 'Insulin' && (
                        <div className="parent">
                           <span className="key">Current blood glucose level (mmol/l)</span>
                           <span className="value">
                              <span style={{ marginRight: '12px' }}>{currentBloodGlucoseLevel}</span>
                           </span>
                        </div>
                     )}
                  </div>
               </div>
            )
      }
      return <></>;
   }

   const drugViewWrapperClass = clsx({
      "drug-view-wrapper": true,
      "has-updates":hasUpdates === true,
   });

   return (
      <div className={drugViewWrapperClass}>
         <div className="drug-view">
            <div className={clsx(detail ? 'drug-header large' : 'drug-header')}>
               <div className="name">
                  <span className="text">{name}</span>
                  {detail && <span className="detail">{detail}</span>}
               </div>
               <div className="administered">{administered === undefined ? '' : administered ? 'Yes' : 'No'}</div>
            </div>
            {drugContent()}
         </div>
      </div>
   );
};

export default Drug;
