import attachmentFileStore from "../../../stores/AttachmentFileStore";

interface Props {
    resourceBatchId: string,
    fileId?: string,
}

/**
 * Uses an imageUrl to render the image in full screen mode.
 */
export const ImageViewer = (props: Props) => {
    const fileInfo = attachmentFileStore.getFileInfo(props.resourceBatchId, props.fileId);

    const handleOnClick = (event: React.MouseEvent<HTMLImageElement>) => {
        if (event.button === 2) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const style = fileInfo.rotation > 0 ? { transform: 'rotate(' + fileInfo.rotation + 'deg)' } : undefined;

    return  <img src={fileInfo.blobUrl} 
                 alt={fileInfo?.filename} 
                 className='image-viewer-container' 
                 onContextMenu={handleOnClick} 
                 style={style} />;
}