import { action, computed, makeObservable, observable } from "mobx";
import { SignalDispatcher } from "strongly-typed-events";
import { BcMessageModel } from "../api/Models/referenceDataModel";

export class BcMessageStore {
    messageId?: number;
    title: string = "";
    text: string = "";
    expiryDate?: moment.Moment;
    cachedMessageIdLevel1?: number;
    cachedMessageIdLevel2?: number;

    private _onNotificationChanged = new SignalDispatcher();

    constructor() {
        makeObservable(this, {
            cachedMessageIdLevel1: observable,
            cachedMessageIdLevel2: observable,
            messageId: observable,
            isNotificationNewLevel1: computed,
            acknowledgeNotificationLevel1: action,
            isNotificationNewLevel2: computed,
            acknowledgeNotificationLevel2: action,
        });
        this.readFromStorage();
    }

    isNotificationActive(): boolean {
        return !!this.messageId;
    }

    public get onNotificationChanged() {
        return this._onNotificationChanged.asEvent();
    }

    get isNotificationNewLevel1(): boolean {
        return this.isNotificationActive() && this.cachedMessageIdLevel1 !== this.messageId;
    }

    acknowledgeNotificationLevel1(): void {
        if (typeof window !== "undefined" && sessionStorage && this.messageId) {
            sessionStorage.lastBcMessageIdLevel1 = this.messageId;
            this.cachedMessageIdLevel1 = this.messageId;
        }
        this._onNotificationChanged.dispatch();
    }

    get isNotificationNewLevel2(): boolean {
        return this.isNotificationActive() && this.cachedMessageIdLevel2 !== this.messageId;;
    }

    acknowledgeNotificationLevel2(): void {
        if (typeof window !== "undefined" && sessionStorage && this.messageId) {
            sessionStorage.lastBcMessageIdLevel2 = this.messageId;
            this.cachedMessageIdLevel2 = this.messageId;
        }
        this._onNotificationChanged.dispatch();
    }

    private readFromStorage(): void {
        this.cachedMessageIdLevel1 = undefined;
        this.cachedMessageIdLevel2 = undefined;
        if (typeof window !== "undefined" && sessionStorage) {
            if (typeof sessionStorage.lastBcMessageIdLevel1 !== 'undefined')
                this.cachedMessageIdLevel1 = parseInt(sessionStorage.lastBcMessageIdLevel1);
            if (typeof sessionStorage.lastBcMessageIdLevel2 !== 'undefined')
                this.cachedMessageIdLevel2 = parseInt(sessionStorage.lastBcMessageIdLevel2);
        }
    }

    updateFromServer(message: BcMessageModel): void {
        if (this.messageId !== (message ? message.messageId : undefined)) {
            this._onNotificationChanged.dispatch();
        }
        this.messageId = message ? message.messageId : undefined;
        this.title = (message ? message.title : null) || "";
        this.text = (message ? message.text : null) || "";
        this.expiryDate = message ? message.expiryDate : undefined;
    }
}
