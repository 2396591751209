import clsx from "clsx";
import { ControlTypes } from "../../../enums/ControlTypes";
import { ValueFormatType } from "../../../enums/ValueFormatType";
import { FieldInformation } from "../../../stores/FieldInformationStore";
import { PageItemModel } from "../../../stores/PageLayoutStore";
import { ChangeField, ChangeFieldHelper } from "../../../types/ChangeField";
import { valueFormatUtils } from "../../../utils/valueFormatUtils";
import { observer } from "mobx-react-lite";
import { PageLayoutToFieldInfoConverter } from "../../../utils/pageLayoutToFieldInfoConverter";

interface Props {
    key: string;
    fieldValue: ChangeField | undefined;
    field?: FieldInformation | undefined;
    pageItem: PageItemModel;
}

export const ReviewControl = observer((props: Props) => {
    const getFieldTitle = () => {
        if (props.pageItem.text && props.pageItem.text !=="") {
            return props.pageItem.text;
        }

        if (!props.field) {
            return "";
        }

        return props.field.getFieldTitle() || "";
    }

    const getFieldText = () => {
        if (!props.fieldValue) {
            return "";
        }

        if (props.fieldValue.disabled) {
            return "-";
        }

        var valueFormat = props.pageItem.valueFormatType;
        if (props.field && !valueFormat && props.field.controlType === ControlTypes.Switch) {
            valueFormat = ValueFormatType.CapitaliseEachWord;
        }

        var text = ChangeFieldHelper.getDisplayValue(props.fieldValue.fieldName, props.fieldValue.toValue) || "";               
        return valueFormatUtils.formatValue(props.fieldValue.fieldName, text, valueFormat);
    }

    const reviewFieldData = clsx({
        'review-field-data': true,
        'review-textarea': props.field && props.field.controlType === ControlTypes.TextArea
    });

    const leftPadding = (8 + (props.pageItem.leftPadding || 0)) + "px";

    return <div id={props.field?.name} className={clsx('review-field', props.fieldValue?.updated && 'has-updates')} style={{paddingLeft: leftPadding}}>
        <div className="review-field-title">{getFieldTitle()}</div>
        <div className={reviewFieldData}>{getFieldText()}</div>
    </div>
});