import clsx from 'clsx';
import { ReactElement, useEffect } from 'react';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import { Key } from 'ts-key-enum';


interface MobileAccountMenuProperties {
   /** toggles the account menu drop down */
   show: boolean;
   handler: () => void;
   cookiePrivacyHandler: () => void;
   conditionsHandler:    () => void;
   accessibilityHandler: () => void;
   onClose: () => void;
}

const MobileAccountMenu = ({ show = false, handler, cookiePrivacyHandler, conditionsHandler, accessibilityHandler, onClose }: MobileAccountMenuProperties): ReactElement => {

   const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === Key.Escape) {
         onClose();
      }
   }

   useEffect(() => {
      document.addEventListener('keydown', handleKeydown)
      return () => {
         document.removeEventListener('keydown', handleKeydown);
      }
   })

   return (
      <>
         <button className={clsx('mobile-account-menu-overlay', { hide: !show })} onClick={onClose} />
         <div className={clsx('mobile-account-menu-parent', { hide: !show })}>
            <div className={clsx('mobile-account-menu')}>
               <ul>
                  <li>
                     <button id="account-menu-cookie" onClick={cookiePrivacyHandler}>Cookies & Privacy</button>
                  </li>
                  <li>
                     <button id="account-menu-conditions" onClick={conditionsHandler}>Terms and Conditions</button>
                  </li>                  
                  <li>
                     <button id="account-menu-accessibility" onClick={accessibilityHandler}>Accessibility Statement</button>
                  </li>
                  <li>
                     <button id="account-menu-logout" className="logout" onClick={handler}>
                        <span className="logout-text">Logout</span>
                        <LogoutIcon />
                     </button>
                  </li>
               </ul>
               <div id="account-menu-version" className="version">
                  Version {process.env.REACT_APP_VERSION || '1.0.0.0'}
               </div>
            </div>
         </div>
      </>
   );
};

export default MobileAccountMenu;
