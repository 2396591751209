import clsx from "clsx";
import { PageItemModel } from "../../../stores/PageLayoutStore";

interface Props {
    pageItem: PageItemModel;
    children: JSX.Element[];
}

export const RowControl = (props: Props) => {
    var valueFormatType = (props.pageItem.valueFormatType || '').toLowerCase();

    const reviewFieldData = clsx({
        'group-row': true,
        'mobile-1-col': valueFormatType === 'mobile-1-col',
        'mobile-2-col': valueFormatType === 'mobile-2-col' || valueFormatType === '',
        'mobile-3-col': valueFormatType === 'mobile-3-col',
    });

    if (!props.children || props.children.length === 0) {
        return <div className="group-row">Row has no child items</div>;
    } else {
        return (<div className={reviewFieldData}>{props.children}</div>);
    }
}