import { useEffect, useState } from 'react';
import attachmentFileStore from '../../../stores/AttachmentFileStore';
import { WorkBook, WorkSheet, read, utils } from 'xlsx';
import moment from 'moment';

interface Props {
    resourceBatchId: string,
    fileId?: string,
    onClose: () => void
}

const secondsInDay = 24 * 60 * 60;
const missingLeapYearDay = secondsInDay * 1000;
const epochExcelDelta = (new Date(1899, 11, 31).getTime()) - missingLeapYearDay;
const epochExcelDeltaMac = (new Date(1904, 1, 1).getTime()) - missingLeapYearDay;

/**
 * Uses 3rd party library xlsx to read the Excel Document
 * and renders it in a standard html table
 * The column and row number are manually added.
 * Special handling is required of date\tims as they are stored as decimal numbers in Excel.
 */
export const XlsxViewer = (props: Props) => {
    const [workbook, setWorkbook] = useState<WorkBook>();
    const [selectedSheet, setSelectedSheet] = useState<string>();
    const [sheetData, setSheetData] = useState<string[][]>();
    const [extraClass, setExtraClass] = useState<string[][]>();

    useEffect(() => {
        const data = attachmentFileStore.getFileInfo(props.resourceBatchId, props.fileId)?.data;
        var newWorkbook = read(data);
        setWorkbook(newWorkbook);

        var firstWorksheetName = newWorkbook.SheetNames[0];

        setSelectedSheet(firstWorksheetName);    
        convertSetSheetData(newWorkbook.Sheets[firstWorksheetName]);
    }, []);


    const handleOnSheetClick = (sheet: string) => {
        setSelectedSheet(sheet);
        convertSetSheetData(workbook!.Sheets[sheet]);
    }

    const isDateTimeString = (value: string): boolean => {
        if (value.includes('/') || value.includes('-')) {
            const parsedDate = new Date(value);
            return parsedDate.toString() !== 'Invalid Date';
        }

        return false;
    }

    const indexToAlpha = (num: number) => {
        let result = '';
      
        while (num >= 0) {
          const remainder = num % 26;
          result = String.fromCharCode(65 + remainder) + result;
          num = Math.floor(num / 26) - 1;
        }
      
        return result;
    }

    const convertSetSheetData = (worksheet: WorkSheet) => {
        let csv = utils.sheet_to_csv(worksheet, { skipHidden: true, blankrows: true, FS: String.fromCharCode(2) })
                    .split('\n')
                    .map(r => r.split(String.fromCharCode(2)));

        let format: string[][] = Array.from({ length: csv.length }, (r:string[]) => r ? Array.from({ length: r.length }) : []); 

        const excelDelta = workbook?.Workbook?.WBProps?.date1904 || false ? epochExcelDeltaMac : epochExcelDelta;                    

        for (var r = 0; r < csv.length; r++) {
            for (var c = 0; c < csv[r].length; c++) {
                var cellAddress = utils.encode_cell({ r: r, c: c });
                var cell = worksheet[cellAddress];
                if (cell && cell.t === 'n' && isDateTimeString(cell.w)) {                    
                    var datetime = moment((cell.v * secondsInDay * 1000) + excelDelta).utc();
                    var hasTime = cell.w.includes(':');
                    csv[r][c] = datetime.format(hasTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY');

                    if (hasTime) {
                        format[r][c] = 'datetime';
                    }
                }
            }
        }                    
             
        setSheetData(csv);
        setExtraClass(format);
    }

    const renderTable = () => {
        if (!sheetData) {
            return <></>;
        }

        var lengths = sheetData.map(r => r.length);
        var maxCols = Math.max(...lengths);
        var emptyRows = Array.from<string>({ length: maxCols });

        return <table>
                  <tbody>                    
                    <tr><th/>{emptyRows.map((c, cIndex) => <th key={`h1-${cIndex}`}>{indexToAlpha(cIndex)}</th>)}</tr>
                    {sheetData.map((r:any, rIndex:number) =>
                        <tr key={`${rIndex}`}>
                            <td className='heading'>{rIndex+1}</td>
                            {r.map((c:any, cIndex:number) =>                 
                            <td key={`${rIndex}-${cIndex}`} className={extraClass![rIndex][cIndex]}>
                                {c}
                            </td>)}
                        </tr>
                    )}

                    
                    <tr><td className='heading'>{sheetData.length+1}</td>{emptyRows.map((c, cIndex) => <td key={`e1-${cIndex}`}>&nbsp;</td>)}</tr>
                    <tr><td className='heading'>{sheetData.length+2}</td>{emptyRows.map((c, cIndex) => <td key={`e2-${cIndex}`}>&nbsp;</td>)}</tr>
                    <tr><td className='heading'>{sheetData.length+3}</td>{emptyRows.map((c, cIndex) => <td key={`e3-${cIndex}`}>&nbsp;</td>)}</tr>
                    <tr><td className='heading'>{sheetData.length+4}</td>{emptyRows.map((c, cIndex) => <td key={`e4-${cIndex}`}>&nbsp;</td>)}</tr>
                    <tr><td className='heading'>{sheetData.length+5}</td>{emptyRows.map((c, cIndex) => <td key={`e5-${cIndex}`}>&nbsp;</td>)}</tr>
                  </tbody>
                </table>
    }

    const renderSheetButtons = (sheetNames: string[]) => {
        return <div className='sheets scroll-style-1'>
            {sheetNames.map(s => 
                <button id={s} onClick={() => handleOnSheetClick(s)} className={(s === selectedSheet ? 'selected' : '')}>
                    {s}
                </button>)
            }
        </div>;
    }

    return <div className="xlsx-viewer-container">
        <div className="table-container">{renderTable()}</div>
        {workbook && renderSheetButtons(workbook.SheetNames)}
    </div>;
}