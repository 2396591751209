import { MouseEventHandler, useContext, useEffect, useRef, useState } from "react";
import { DonorHeaderDetails } from "../../types/DonorHeaderDetails"
import { DonorHeaderItem } from "./DonorHeaderItem/DonorHeaderItem";
import clsx from "clsx";
import { BreakpointContext } from "../../context/BreakpointContext";
import { useElementSize } from 'use-element-size';
import { Key } from "ts-key-enum";
import referenceDataStore from "../../stores/ReferenceDataStore";
import { fieldNames } from "../../constants/fieldNameConstants";


interface Props {
    donorDetails: DonorHeaderDetails;
    isCondensed: boolean;
    setIsCondensed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DonorDetailsHeader = ({ donorDetails, isCondensed, setIsCondensed }: Props) => {

    const formatWeightAccuracy = (weightAccuracy: string | null) => weightAccuracy?.toUpperCase() === 'ACTUAL' ? '(Act.)' : '(Est.)';

    const isMobile = useContext(BreakpointContext);
    const ref = useRef<HTMLButtonElement>(null);
    const sexLabel = referenceDataStore.getFieldInfo(fieldNames.PATIENTDEMOGRAPHIC_GENDER)?.getFieldTitle() || "Sex";

    const mobileHeaderTopLine = <div className="content">
        <DonorHeaderItem title='Sex' value={donorDetails.sex} hideTitle />
        <DonorHeaderItem title='DOB' value={donorDetails.dob} hideTitle valueSecondary={`(${donorDetails.age})`} />
        <DonorHeaderItem title='Donor type' value={donorDetails.donorType} hideTitle />
        <DonorHeaderItem title='Blood group' value={donorDetails.bloodGroup} hideTitle />
        <DonorHeaderItem title='Weight' value={donorDetails.weightKg} hideTitle valueSecondary={'kg'} valueTertiary={formatWeightAccuracy(donorDetails.weightAcc)} />
    </div>

    const mobileContent = <div className="content-parent  mobileOnly">
        {mobileHeaderTopLine}
        <hr />
        <div className="content">
            <DonorHeaderItem title='ODT No.' value={donorDetails.odtNumber} />
            <DonorHeaderItem title='Donor status' value={donorDetails.donorStatus} />
        </div>
        <hr />
        <div className="content">
            <DonorHeaderItem block title='Cause of death' value={donorDetails.causeOfDeath} />
            <DonorHeaderItem block title='Hospital' value={donorDetails.hospital} />
        </div>
    </div>;

    const mobileContentCondensed = <div className="content-parent">
        <div className="content">
            <DonorHeaderItem title='ODT No.' value={donorDetails.odtNumber} />
            <DonorHeaderItem title='Donor type' value={donorDetails.donorType} hideTitle />
            <DonorHeaderItem block title='Sex' value={donorDetails.sex} hideTitle />
            <DonorHeaderItem block title='DOB' value='' valueSecondary={`(${donorDetails.age})`} hideTitle />
            <DonorHeaderItem block title='Blood group' value={donorDetails.bloodGroup} hideTitle />
        </div>
    </div>;

    const desktopContent = <div className="content-parents desktopOnly">
        <div className="content">
            <DonorHeaderItem block title={sexLabel} value={donorDetails.sex} />
            <DonorHeaderItem block title='DOB' value={donorDetails.dob} valueSecondary={`(${donorDetails.age})`} />
            <DonorHeaderItem block title='Donor type' value={donorDetails.donorType} />
            <DonorHeaderItem block title='Blood group' value={donorDetails.bloodGroup} />
            <DonorHeaderItem block title='ODT No.' value={donorDetails.odtNumber} />
            <DonorHeaderItem block title='Weight' value={donorDetails.weightKg} valueSecondary={'kg'} valueTertiary={formatWeightAccuracy(donorDetails.weightAcc)} />
        </div>
        <hr />
        <div className="content">
            <DonorHeaderItem block title='Hospital' value={donorDetails.hospital} />
            <DonorHeaderItem block title='Cause of death' value={donorDetails.causeOfDeath} />
            <DonorHeaderItem block title='Donor status' value={donorDetails.donorStatus} />
        </div>
    </div>

    const [showDivider, setShowDivider] = useState(false);

    // Used to hide the divider when contents wrap to new line
    const desktopContentCondensedRef = useElementSize((size, prevSize, elem) => {
        if (size) {
            setShowDivider(size.height < 30);
        }
    })

    const desktopContentCondensed = <div ref={desktopContentCondensedRef} className="content-parent desktopOnly">
        <span className="content">
            <DonorHeaderItem title='Sex' value={donorDetails.sex} />
            <DonorHeaderItem title='DOB' value={donorDetails.dob} valueSecondary={`(${donorDetails.age})`} />
            <DonorHeaderItem title='Donor type' value={donorDetails.donorType} />
            <DonorHeaderItem title='ODT No.' value={donorDetails.odtNumber} />
            {showDivider && <span className='divider' />}
            <DonorHeaderItem title='Hospital' value={donorDetails.hospital} />
        </span>
    </div>

    const mobile = isCondensed ? mobileContentCondensed : mobileContent;
    const desktop = isCondensed ? desktopContentCondensed : desktopContent;

    const toggleCondensed = () => {
        setIsCondensed((prev) => !prev);       
    }

    const handleKeydown = (event: KeyboardEvent) => {
        if (event.key === Key.Tab) {
            ref.current?.classList.add('focus-highlight');
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeydown)
        return () => {
           document.removeEventListener('keydown', handleKeydown)
        }
    }, [])

    return <button id='donor-details-header' className={clsx('donor-details-header', isCondensed && 'condensed')} onClick={toggleCondensed} ref={ref}>
        {isMobile ? mobile : desktop}
    </button>
}
