import { ReactComponent as ArrowIcon } from '../../../assets/icons/percentArrow.svg';

interface Props {
    value: string;
}
export const PercentArrow = ({ value }: Props) => {
    return <div className='percent-arrow'>
        <span>{value + '%'}</span>
        <ArrowIcon />
    </div>
}