import React from 'react';
import { ReactComponent as OnIcon } from '../../assets/icons/radio-on-bw.svg';
import { ReactComponent as OffIcon } from '../../assets/icons/radio-off-bw.svg';

interface Props {
  value: string;
  label: string;
  checked: boolean;
  onChange: (value: string) => void;
}

const RadioButton: React.FC<Props> = ({
  value,
  label,
  checked,
  onChange,
}) => {
  const handleChange = () => {
    onChange(value);
  };

  return (
    <div className="radio-button">
      <button onClick={handleChange}>{checked ? <OnIcon /> : <OffIcon />}</button>
      <span className='text'>{label}</span>
    </div>
  );
};

export default RadioButton;