import { ReactComponent as Person } from '../../assets/icons/person.svg';
import { ReactComponent as LogoSmallLight } from '../../assets/logoSmallLight.svg';
import { ReactComponent as LogoSmallDark } from '../../assets/logoSmallDark.svg';
import { HeaderButton } from './HeaderButton/headerButton';
import { ReactComponent as MagnifyingGlass } from '../../assets/icons/magnifyingGlass.svg';
import { ReactComponent as TimedOutIcon } from '../../assets/icons/timedOut.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/infoOutline.svg';
import clsx from 'clsx';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { observable } from 'mobx';
import userStore from '../../stores/UserStore';
import referenceDataStore from '../../stores/ReferenceDataStore';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountMenu from '../AccountMenu/AccountMenu';
import Dialog from '../Dialog/Dialog';
import Button from '../Inputs/Button/Button';
import { useIdleTimer } from 'react-idle-timer';
import { SettingConstants } from '../../constants/settingConstants';
import { observer } from 'mobx-react-lite';
import { Alerts } from './Alerts/Alerts';
import { ActivityLog } from './ActivityLog/ActivityLog';
import historyStore from '../../stores/HistoryStore';
import { HeaderTab } from '../../enums/HeaderTab';
import MobileAccountMenu from '../AccountMenu/MobileAccountMenu';
import { BreakpointContext } from '../../context/BreakpointContext';
import { ActivityLogDialog } from '../ActivityLogDialog/ActivityLogDialog';
import { PrintButton } from './PrintButton/printButton';
import referralStore from '../../stores/ReferralStore';
import { baseApi } from '../../api/baseApi';

interface Props {
   hideOnMobile?: boolean;
   showAlerts?: boolean;
   showDownloadIcon?: boolean;
   showActivityLogIcon?: boolean;
   previousLink?: ReactNode;
   leftContentMobile?: ReactNode;
   leftContentDesktop?: ReactNode;
   mobileTabs?: boolean;
   activeTab: HeaderTab | undefined;
   setActiveTab: Function;
   searchButtonHandler?: Function;
}

export const Header = observer(
   ({
      hideOnMobile,
      showAlerts,
      showDownloadIcon,
      showActivityLogIcon,
      leftContentDesktop,
      leftContentMobile,
      mobileTabs,
      activeTab,
      setActiveTab,
      searchButtonHandler
   }: Props) => {
      const navigate = useNavigate();
      const location = useLocation();

      const [idleTimeoutInMills, setIdleTimeoutInMills] = useState(15 * 60 * 1000);
      const [userActivity, setUserActivity] = useState<'Active' | 'Idle' | 'Unauthorised'>('Active');
      const [showAccountMenu, setShowAccountMenu] = useState(false);
      const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
      const [showNotificationPopup, setShowNotificationPopup] = useState(false);
      const [showActivityLogDialog, setShowActivityLogDialog] = useState(false);
      const refActivityLogButton = useRef<HTMLDivElement>(null);

      const isMobile = useContext(BreakpointContext);

      const [userInfo] = useState(() => observable({ userStore }));
      const {
         userStore: { isLoggedIn }
      } = userInfo;

      const onReferenceDataChanged = () => {
         var timeoutInMills = referenceDataStore.settings.getSettingAsMinsFromTimeStamp(SettingConstants.WEB_CLIENT_IDLE_TIMEOUT);
         if (idleTimeoutInMills && timeoutInMills && timeoutInMills > 0) {
            setIdleTimeoutInMills(timeoutInMills * 60 * 1000);
         }
      };

      useEffect(() => {
         historyStore.push(location.pathname);
      }, [location]);

      useEffect(() => {
         referenceDataStore.onReferenceDataChanged.subscribe(onReferenceDataChanged);
         baseApi.onUnauthorized.subscribe(handleUnauthorised);
         onReferenceDataChanged();

         //cleanup
         return () => {
            referenceDataStore.onReferenceDataChanged.unsubscribe(onReferenceDataChanged);
            baseApi.onUnauthorized.unsubscribe(handleUnauthorised);
         };
      }, []);

      const onIdle = () => {
         setUserActivity('Idle');
      };

      const {getRemainingTime} =  useIdleTimer({
         onIdle,
         timeout: idleTimeoutInMills,
         throttle: 500
      });

      const closeAllMenus = (menuFunctionToIgnore?: Function) => {
         [setShowAccountMenu, setShowNotificationPopup].filter(func => func !== menuFunctionToIgnore).map(func => func(false))
      }

      const clickOutsideHandler = (functionToIgnore?: Function) => {
         closeAllMenus(functionToIgnore);
      }

      const handleAccountButton = (e: MouseEvent) => {
         clickOutsideHandler(setShowAccountMenu);
         setShowAccountMenu(prev => !prev);
         setActiveTab(HeaderTab.account);
         e.stopPropagation();
      };

      const handleSearchButton = () => {
         setActiveTab(HeaderTab.search);
         if (searchButtonHandler) {
            searchButtonHandler();
         }
      };

      const handleOpenLogoutDialog = () => {
         closeAllMenus();
         setOpenLogoutDialog(true);
      };

      const handleCloseLogoutDialog = () => {
         setOpenLogoutDialog(false);
      };

      const handleCookiePrivacy = () => {
         navigate('/privacy-cookie');
         closeAccountMenu();
      };

      const handleConditions = () => {
         navigate('/terms-conditions');
         closeAccountMenu();
      };

      const handleAccessibility = () => {
         navigate('/accessibility');
         closeAccountMenu();
      };

      const handleLogout = () => {
         userStore.logout();
         historyStore.clear();
         referralStore.clear();
         if (!referenceDataStore.isLoaded) {
            referenceDataStore.clear()
         }
         navigate('/login');
      };

      const handleUnauthorised = () => {
         setUserActivity('Unauthorised');
      }

      const closeAccountMenu = () => {
         setShowAccountMenu(false);
         setActiveTab(HeaderTab.search);
      };

      const handleActivityLog = () => {
         if (showActivityLogDialog || referralStore.latestChangeListId !== referralStore.firstViewedTxpChangeListId) {
            setShowActivityLogDialog(!showActivityLogDialog)
         }
      }

      const closeActivityLogDialog = (target: Node | undefined) => {
         if (!target || (refActivityLogButton.current && !refActivityLogButton.current.contains(target))) {
            setShowActivityLogDialog(false);
         }
      };

      const leftContent = (
         <>
            <span className="mobileOnly">{leftContentMobile || <LogoSmallLight />}</span>
            <span className="desktopOnly">{leftContentDesktop || <LogoSmallDark />}</span>
         </>
      );

      return (
         <>
            <div className={clsx('header env-bg-colour', hideOnMobile && 'desktopOnly', mobileTabs && 'mobileTabs', process.env.REACT_APP_ENV?.toLowerCase())}>
               <div className={clsx("content env-bg-colour", process.env.REACT_APP_ENV?.toLowerCase())}>
                  <span className="left-content">{leftContent}</span>
                  <div className='dot'>{'.'}</div>
                  {isLoggedIn && (
                     <div className="buttons">
                        {!showActivityLogIcon && 
                           <HeaderButton
                           text="Search"
                           handler={handleSearchButton}
                           icon={<MagnifyingGlass />}
                           active={activeTab === HeaderTab.search}
                           mobileOnly={true}
                           tab={mobileTabs}
                        />}
                        {showDownloadIcon && <PrintButton />}
                        {showActivityLogIcon && <ActivityLog ref={refActivityLogButton} tab={mobileTabs} onClick={handleActivityLog} />}
                        {showActivityLogDialog && <ActivityLogDialog onClose={closeActivityLogDialog} />}
                        {showAlerts && <Alerts tab={mobileTabs} showNotificationPopup={showNotificationPopup} setShowNotificationPopup={setShowNotificationPopup} clickOutsideHandler={clickOutsideHandler} />}
                        <HeaderButton text="Account" handler={handleAccountButton} icon={<Person />} tab={mobileTabs} />
                        {!isMobile && <AccountMenu show={showAccountMenu} 
                                                   handler={handleOpenLogoutDialog} 
                                                   cookiePrivacyHandler={handleCookiePrivacy} 
                                                   conditionsHandler={handleConditions}
                                                   accessibilityHandler={handleAccessibility} 
                                                   clickOutsideHandler={clickOutsideHandler} />}
                     </div>
                  )}
               </div>
            </div>
            {isMobile && <MobileAccountMenu
               show={showAccountMenu}
               handler={handleOpenLogoutDialog}
               cookiePrivacyHandler={handleCookiePrivacy}
               conditionsHandler={handleConditions}
               accessibilityHandler={handleAccessibility}
               onClose={closeAccountMenu}
            />}
            {openLogoutDialog && <Dialog onClose={handleCloseLogoutDialog} maxWidth="sm" className="logout-dialog">
               <div className="dialog-question">Are you sure you want to log out?</div>
               <div className="dialog-text">You will be returned to the login screen.</div>
               <div className="logout-dialog-btns">
                  <Button text="Cancel" className="btn" onClick={handleCloseLogoutDialog} />
                  <Button text="Yes, log out" className="" onClick={handleLogout} />
               </div>
            </Dialog> }

            {/** Inactivity dialog */}
            { userActivity === 'Idle' && <Dialog onClose={() => undefined} maxWidth="sm" className="inactivity-dialog" hasOpaqueOverlay>
               <TimedOutIcon />
               <div className="dialog-timeout">Timed out</div>
               <div className="dialog-text">You have been logged out due to 15 minutes of inactivity. You will be returned to the Login screen.</div>
               <Button text="OK" className="btn dialog-btn" onClick={handleLogout} />
            </Dialog> }

            {/** Unexpected Logged out dialog */}
            { userActivity === 'Unauthorised' && <Dialog onClose={() => undefined} maxWidth="sm" className="inactivity-dialog" hasOpaqueOverlay>
               <InfoIcon />
               <div className="dialog-timeout">Logout</div>
               <div className="dialog-text">You have been logged out TransplantPath. You will be returned to the Login screen.</div>
               <Button text="OK" className="btn dialog-btn" onClick={handleLogout} />
            </Dialog> }            
         </>
      );
   }
);
