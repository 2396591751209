import { useState, useEffect } from 'react';
import attachmentFileStore from "../../../stores/AttachmentFileStore";
import clsx from 'clsx';

interface Props {
    resourceBatchId: string,
    fileId?: string,
    onClick: () => void;
    selected: boolean;
    tabIndex?: number;
}

export const Thumbnail = (props: Props) => {
    const [,forceUpdate] = useState(0);

    const fileInfo = attachmentFileStore.getFileInfo(props.resourceBatchId, props.fileId);

    useEffect(() => {
        attachmentFileStore.onChange.subscribe(handleAttachmentStoreChange);
    }, []);    

    const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onClick();
    }

    const handleAttachmentStoreChange = (sender: any, id: string | undefined) => {
        if (props.resourceBatchId === id) {
            forceUpdate(prev => prev+1);
        }
    }

    const style = fileInfo?.rotation > 0 ? { transform: 'rotate(' + fileInfo.rotation + 'deg)' } : undefined;

    return <button className={clsx('thumbnail-view', {'selected': props.selected})} tabIndex={props.tabIndex} onClick={handleOnClick} style={style}>
                <div>{fileInfo?.blobUrl ? <img src={fileInfo?.blobUrl} alt='thumbnail' /> : <div className='empty' />}</div>
           </button>
}
