export interface Attachment {
    contentType?: string,
    category?: string,
    title?: string,
    timestamp?: string,
    description?: string,
    systemType?: number,
    type?: string,
    organCategory?: any,
    organLocation?: any,
    fileId1?: string,
    fileName1?: string,
    fileSizeKb1?: number,
    fileId2?: string,
    fileName2?: string,
    fileSizeKb2?: number,
    fileId3?: string,
    fileName3?: string,
    fileSizeKb3?: number,
    fileId4?: string,
    fileName4?: string,
    fileSizeKb4?: number,
    fileId5?: string,
    fileName5?: string,
    fileSizeKb5?: number,
    hasDamage?: string
}

export interface AttachmentFileDetails {
    fileId?: string,
    fileName?: string,
    fileSizeKb?: number,
}

export const MaxAttachmentCount: number = 5;

export class AttachmentHelper {
    public static getFileDetail(attachment?: Attachment, indexZeoBased?: number) : AttachmentFileDetails | undefined {
        if (!attachment) {
            return undefined;
        }

        indexZeoBased ||= 0;
        indexZeoBased++;

        const fileDetail = {
                            fileId:     attachment[`fileId${indexZeoBased}`       as keyof Attachment],  
                            fileName:   attachment[`fileName${indexZeoBased}`     as keyof Attachment],  
                            fileSizeKb: attachment[`fileSizeKb${indexZeoBased}`   as keyof Attachment],  
                           } as AttachmentFileDetails

        return fileDetail;                          
    }

    public static getFileDetails(attachment?: Attachment) : AttachmentFileDetails[] {
        var result: AttachmentFileDetails[] = [];

        for (var i = 0; i <= MaxAttachmentCount; i++) {
            var fileDetails = AttachmentHelper.getFileDetail(attachment, i);
            if (fileDetails && fileDetails.fileId) {
                result.push(fileDetails);
            }
        }

        return result;
    }
}