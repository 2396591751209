import { ControlTypes } from "../enums/ControlTypes";
import { FieldConditionRule } from "../types/FieldConditionRule";
import { FieldValidationRule } from "../types/FieldValidationRule";
import { makeObservable, observable } from "mobx"
import { FieldAlternativeTextModel, ReferenceFieldModel } from "../api/Models/referenceDataModel";
import { StringUtil } from "../utils/stringUtil";
import { PageLayoutToFieldInfoConverter } from "../utils/pageLayoutToFieldInfoConverter";

export class FieldInformation {
    name: string = "";
    controlType: ControlTypes = ControlTypes.Text;
    title: string | undefined;
    titleConditional: FieldValidationRule[] | undefined;
    important: boolean = false;
    codeSetType: string | undefined;
    alternativeTexts: FieldAlternativeTextModel[] | undefined;

    constructor() {
        makeObservable(this, {
            controlType: observable,
            title: observable,
            important: observable
        })
    }

    get subFieldName(): string | undefined {
        var [_, subFieldName] = this.name.split("_", 2);
        subFieldName ||= this.name;
        if (subFieldName) {
            subFieldName = StringUtil.toLowerCaseFirstLetter(subFieldName)!;
        }
        return subFieldName;
    }

    // Gets the correct title used either the alternativeText or title
    // Also needs a referral to be loaded
    // Note does not take into account PageItem.Text
    getFieldTitle(): string | undefined {
        if (this.alternativeTexts && this.alternativeTexts.length > 0) {
            const matchingCondition = this.alternativeTexts.find(a => {
                var conditionMethod = PageLayoutToFieldInfoConverter.ConvertPageItemValidation(a, false);
                return conditionMethod(undefined);
            });

            if (matchingCondition && matchingCondition.text) {
                return matchingCondition.text;
            }
        }     
        
        return this.title;
    }

    updateFromServer(fieldInfo: ReferenceFieldModel) {
        this.name = fieldInfo.name;
        this.controlType = fieldInfo.controlType;
        this.codeSetType = fieldInfo.codeSetType || undefined;
        this.title = fieldInfo.text || undefined;
        this.important = fieldInfo.important || false;    
        
        if (fieldInfo.alternativeTexts && fieldInfo.alternativeTexts.length > 0) {
            this.alternativeTexts = fieldInfo.alternativeTexts;
        }
    }
}

export class FieldInformationStore {
    fieldSets: { [fieldSetKey: string]: FieldInformation } = {};    

    constructor() {
        makeObservable(this, {
            fieldSets: observable
        })
    }

    getFieldInfo(fieldKey: string | undefined): FieldInformation | undefined {
        if (!fieldKey) {
            return undefined;
        }

        var unboundFieldKey = fieldKey.split("|", 1)[0];
        return this.fieldSets[unboundFieldKey];
    }

    updateFromServer(fieldInfo: ReferenceFieldModel[]) {
        fieldInfo.forEach(f => {
            var field = this.fieldSets[f.name];
            if (!field) {
                field = new FieldInformation();
                this.fieldSets[f.name] = field;
            }
            field.updateFromServer(f);
        });
    }
}
