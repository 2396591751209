import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { StoreLoadingStateTypes } from "../../../enums/StoreLoadingStateTypes";
import { PageItemModel } from "../../../stores/PageLayoutStore";
import referenceDataStore from "../../../stores/ReferenceDataStore";
import { BreakpointContext } from "../../../context/BreakpointContext";

interface Props {
    pageLayoutItem: PageItemModel;
}

export const HeaderControl = observer((props: Props) => {
    const [activeId, setActiveId] = useState<string | null>();
    const isMobile = useContext(BreakpointContext);

    useEffect(() => {
        if (referenceDataStore.state === StoreLoadingStateTypes.Ready) {
            setActiveId(referenceDataStore.pageLayout.topLevelPageInView);
        }
    }, [referenceDataStore.pageLayout.topLevelPageInView])

    const getDesktopText = (): string | null => {
        return (activeId === props.pageLayoutItem.text || activeId === props.pageLayoutItem.tag) ? null : props.pageLayoutItem.text;
    }

    const getMobileText = (): string | null => {
        return (activeId === props.pageLayoutItem.text || activeId === props.pageLayoutItem.tag) ? null : props.pageLayoutItem.extraText || props.pageLayoutItem.text;
    }

    return <h1 className="page-header">{(isMobile ? getMobileText() : getDesktopText()) || <>&nbsp;</>}</h1>
});