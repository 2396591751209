import clsx from "clsx"
import { PercentArrow } from "./PercentArrow/PercentArrow"

interface Props {
    priorToSampling: Record<string, string>,
    percentages: number[],
    volumeRetained: Record<string, string>,
    title: string,
    totalCalcText: string,
    totalCalc: number,
    hasUpdates: boolean
}

export const HaemodilutionTable = ({ priorToSampling, percentages, volumeRetained, title, totalCalcText, totalCalc, hasUpdates }: Props) => {

    return <div className={clsx('haemodilution-table', hasUpdates && 'has-updates')}>
        <p className='title'>{title}</p>
        <table>
            <tr>
                <td className='column-header'>Interval prior to sampling</td>
                <td />
                <td className='column-header column-right'>Volume retained</td>
            </tr>
            {percentages.map((percent, index) =>
                <tr>
                    <td className='column-left'>{Object.keys(priorToSampling).length > index ? (<div>
                        <p className='label'>{Object.keys(priorToSampling)[index]}                        </p>
                        <p className='value'>{priorToSampling[Object.keys(priorToSampling)[index]]}</p>
                    </div>) : ''}</td>
                    <td className='column-middle'>
                        <div className='arrow-parent'>
                            <PercentArrow value={'' + percent} />
                        </div>
                    </td>
                    <td className='column-right'>{Object.keys(volumeRetained).length > index ? (<div>
                        <p className='label'>{Object.keys(volumeRetained)[index]}</p>
                        <p className='value'>{volumeRetained[Object.keys(volumeRetained)[index]]}</p>
                    </div>) : ''}</td>
                </tr>
            )}
            <tr>
                <td colSpan={3}>
                    <hr />
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                </td>
                <td className='total-box'>
                    <p className='label'>{totalCalcText}</p>
                    <p className='value'>{totalCalc}</p>
                </td>
            </tr>
        </table>

    </div>
}