import { useEffect, useState } from "react";
import NotificationPopup from "../../Notification/Notification";
import { HeaderButton } from "../HeaderButton/headerButton";
import { ReactComponent as NotificationBell } from '../../../assets/icons/bell.svg';
import { ReactComponent as NotificationBellDot } from '../../../assets/icons/bell_dot.svg';
import referenceDataStore from "../../../stores/ReferenceDataStore";


interface Props {
   tab?: boolean;
   showNotificationPopup: boolean;
   setShowNotificationPopup: React.Dispatch<React.SetStateAction<boolean>>;
   clickOutsideHandler?: Function;
}

export const Alerts = ({ tab, showNotificationPopup, setShowNotificationPopup, clickOutsideHandler }: Props) => {

   const [openReadMessageDialog, setOpenReadMessageDialog] = useState(false);
   const [notificationBubble, setNotificationBubble] = useState(referenceDataStore.bcMessage.isNotificationNewLevel1);

   const bellIcon: JSX.Element = notificationBubble ? (
      <NotificationBellDot />
   ) : (
      <NotificationBell className="notification-icon-sm" />
   );

   const updateNotificationBubble = () => setNotificationBubble(referenceDataStore.bcMessage.isNotificationNewLevel1);


   useEffect(() => {
      referenceDataStore.bcMessage.onNotificationChanged.subscribe(updateNotificationBubble);
      referenceDataStore.onReferenceDataChanged.subscribe(updateNotificationBubble);

      //cleanup
      return () => {
         referenceDataStore.bcMessage.onNotificationChanged.unsubscribe(updateNotificationBubble);
         referenceDataStore.onReferenceDataChanged.unsubscribe(updateNotificationBubble);
      };
   }, []);


   useEffect(() => {
      if (clickOutsideHandler) {
         document.addEventListener('click', clickOutsideHandler as EventListener);
         return () => {
            document.removeEventListener('click', clickOutsideHandler as EventListener)
         };
      }
   }, [])

   const handleAlertsButton = (e: MouseEvent) => {
      if (clickOutsideHandler) {
         clickOutsideHandler(setShowNotificationPopup);
      }
      if (referenceDataStore && referenceDataStore.bcMessage && referenceDataStore.bcMessage.isNotificationActive()) {
         setShowNotificationPopup(prev => !prev);
         referenceDataStore.bcMessage.acknowledgeNotificationLevel1();
      }
      e.stopPropagation();
   };

   const handleReadMessage = () => {
      setShowNotificationPopup(false);
      setOpenReadMessageDialog(prev => !prev);
      referenceDataStore.bcMessage.acknowledgeNotificationLevel2();
   };

   return <div className="alert-wrapper">
      <HeaderButton text="Alerts" handler={handleAlertsButton} icon={bellIcon} active={false} tab={tab} />
      <NotificationPopup
         show={showNotificationPopup}
         bcMessage={referenceDataStore.bcMessage}
         readMessageHandler={handleReadMessage}
         openReadMessageDialog={openReadMessageDialog}
         hasBeenRead={!referenceDataStore.bcMessage.isNotificationNewLevel2}
      />
   </div>
}