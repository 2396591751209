import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { ReactComponent as ChevrenIcon } from '../../../assets/icons/chevron-left.svg';
import { ReactComponent as PointerIcon } from '../../../assets/icons/pointer.svg';
import { useSideMenu } from '../../../context/SideMenuContext';
import { SubMenu } from './SubMenu';
import { MenuItem, scrollToPage } from '../menuItems';
import { InView } from "react-intersection-observer";

interface MenuProperties {
   id: string;
   path: string;
   title: string;
   subMenu: MenuItem[];
   isActiveHandler: (isActiveId: string) => void;
   closeMobileMenu: () => void;
   isActiveId: string | null;
   hasUpdates?: boolean;
}

const Menu = ({ id, path, title, isActiveId, isActiveHandler, subMenu, closeMobileMenu, hasUpdates }: MenuProperties) => {
   const [openSubMenu, setOpenSubMenu] = useState(false);
   const { openMenuIds, handleOpenMenuIds, handleMenuIdsOutsideSideMenuViewPort } = useSideMenu();

   useEffect(() => {
      if (!openSubMenu && openMenuIds.includes(id)) {
         setOpenSubMenu(true);
      }
   }, [openMenuIds]);

   const onViewChange = (inView: boolean, entry: any) => {
      const { id } = entry.target.dataset;
      const location = entry.boundingClientRect.bottom > entry.rootBounds.bottom ? 'bottom' : 'top'
      handleMenuIdsOutsideSideMenuViewPort(id, inView, location);
   }

   const isActive = isActiveId === path || (!openSubMenu && subMenu.some(c => c.path === isActiveId));

   const textClass = clsx('text-parent', {
      active: isActiveId === id,
      "has-updates": hasUpdates || subMenu.some(s => s.hasUpdates)
   });

   const handleMenuTitleClicked = () => {
      isActiveHandler(path);
      closeMobileMenu();
      setOpenSubMenu(prev => !prev);
      handleOpenMenuIds(id);
      scrollToPage(path);
   }

   return (
      <div className="menu-item">
         <div className="title">

            <InView
               className={textClass}
               data-id={path}
               onChange={onViewChange}
            >
               <PointerIcon className={clsx('pointer', { active: isActive })} />
               <button onClick={handleMenuTitleClicked}>{title}</button>
            </InView>
            {
               subMenu.length > 0 &&
               <button
                  className="icon-span"
                  onClick={() => {
                     setOpenSubMenu(prev => !prev);
                     handleOpenMenuIds(id);
                  }}>
                  <ChevrenIcon className={clsx('icon', { open: openSubMenu || openMenuIds.includes(id) })} />
               </button>
            }
         </div>

         <SubMenu
            subMenuItem={subMenu}
            parentId={id}
            isactiveId={isActiveId}
            open={openSubMenu}
            handleClickNavigation={(path: string) => {
               isActiveHandler(path);
               closeMobileMenu();
               scrollToPage(path);
            }}
         />
      </div>
   );
};

export default Menu;