import { useContext, useState, ReactNode, createContext } from 'react';
import { menuItems } from '../components/Navigation/menuItems';
import referralStore from '../stores/ReferralStore';
import referenceDataStore from '../stores/ReferenceDataStore';

interface SideMenuContextType {
   openMenuIds: string[];
   handleOpenMenuIds: (menuId: string) => void;
   menuIdsAboveViewPort: string[];
   menuIdsBelowViewPort: string[];
   handleMenuIdsOutsideSideMenuViewPort: (menuId: string, visible: boolean, location: 'top' | 'bottom' | undefined) => void;
   calcIfMenuItemHasUpdates: () => void
   isOpening: boolean;
}

export const SideMenuContext = createContext<SideMenuContextType>({} as SideMenuContextType);

const SideMenuProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
   const [openMenuIds, setOpenMenuIds] = useState<string[]>([]);
   const [initialised, setInitialised] = useState(false);
   const [menuIdsAboveViewPort, setMenuIdsAboveViewPort] = useState<string[]>([]);
   const [menuIdsBelowViewPort, setMenuIdsBelowViewPort] = useState<string[]>([]);
   const [isOpening, setIsOpening] = useState(false);

   if (!initialised) {
      setInitialised(true);
      const menuIds: string[] = JSON.parse(sessionStorage.getItem('openMenuIds') || '[]');
      setOpenMenuIds(menuIds);

      // If an open sub menu is hidden then won't get the event to add to menuIdsBelowViewPort
      // So assume all open sub menus are hidden, then visible event will remove as needed
      var openSubMenuIds = menuItems.filter(x => x.subMenu && menuIds.includes(x.id)).flatMap(x => x.subMenu!);
      setMenuIdsBelowViewPort(openSubMenuIds.map(x => x.path));
   }

   const openMenuIdsHandler = (menuId: string) => {
      if (openMenuIds.includes(menuId)) {
         const newOpenIds = openMenuIds.filter(id => id !== menuId);
         sessionStorage.setItem('openMenuIds', JSON.stringify(newOpenIds));
         setOpenMenuIds(newOpenIds);

         // If closing menu, then if sub menu items was below scroll, the InView won't trigger
         // So remove all the sub menus
         var subMenu = menuItems.find(x => x.id === menuId)?.subMenu;
         if (subMenu !== null) {
            subMenu?.forEach(x => outsideSideMenuViewPortHandler(x.path, true, undefined));
         }         
      } else {
         setIsOpening(true);
         const newOpenIds = [...openMenuIds, menuId];
         sessionStorage.setItem('openMenuIds', JSON.stringify(newOpenIds));
         setOpenMenuIds(newOpenIds);

         // If opening menu, then if sub menu items ends up below scroll, the InView won't trigger
         // So assume sub menus are below bottom of screen, and the InView will fire to say if they are visible.
         var subMenu = menuItems.find(x => x.id === menuId)?.subMenu;
         if (subMenu !== null) {
            subMenu?.forEach(x => outsideSideMenuViewPortHandler(x.path, false, 'bottom'));

            // To factor in small period of time when all subMenus are set to outside the Viewport
            // And when the InView event fires, set a flag indicating the menu is opening
            setTimeout(() => setIsOpening(false), 250); 
         }
      }
   };

   const outsideSideMenuViewPortHandler = (menuId: string, visible: boolean, location: 'top' | 'bottom' | undefined) => {
      if (visible) {
         setMenuIdsAboveViewPort((prevMenuIds) => [...prevMenuIds.filter(id => id !== menuId)]);
         setMenuIdsBelowViewPort((prevMenuIds) => [...prevMenuIds.filter(id => id !== menuId)]);
      } else if (location === 'top') {
         setMenuIdsAboveViewPort((prevMenuIds) => [...prevMenuIds.filter(id => id !== menuId), menuId]);
      } else if (location === 'bottom') {
         setMenuIdsBelowViewPort((prevMenuIds) => [...prevMenuIds.filter(id => id !== menuId), menuId]);
      }
   }

   const calcIfMenuItemHasUpdates = () => {
      var updatedFieldNames = referralStore.updatedFieldNames;

      // Update the menu items hasUpdates field
      menuItems.forEach(item => {
         var fieldsOnPage = referenceDataStore.pageLayout.getFieldNamesForPage(item.path);
         item.hasUpdates = fieldsOnPage.some(fieldName => updatedFieldNames.some(f => f === fieldName || (f.startsWith(fieldName) && f.startsWith(fieldName + '|'))))

         // Update the sub menu items hasUpdates field
         if (item.subMenu) {
            item.subMenu.forEach(subItem => {
               var fieldsOnPage = referenceDataStore.pageLayout.getFieldNamesForPage(subItem.path);
               subItem.hasUpdates = fieldsOnPage.some(fieldName => updatedFieldNames.some(f => f === fieldName || (f.startsWith(fieldName) && f.startsWith(fieldName + '|'))))
            });
         }
      })      
   }   

   const context = {
      openMenuIds,
      handleOpenMenuIds: openMenuIdsHandler,
      menuIdsAboveViewPort,
      menuIdsBelowViewPort,
      handleMenuIdsOutsideSideMenuViewPort: outsideSideMenuViewPortHandler,
      calcIfMenuItemHasUpdates: calcIfMenuItemHasUpdates,
      isOpening,
   };

   return <SideMenuContext.Provider value={context}>{children}</SideMenuContext.Provider>;
};
const useSideMenu = (): SideMenuContextType => useContext(SideMenuContext);
export { SideMenuProvider, useSideMenu };
