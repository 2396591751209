var fieldValues = {
    CHECKED: "1",
    YES: "2",
    NO: "1",
    NOT_TRANSFUSED: "3",
    NOT_SET: "8",
    UNKNOWN: "9",
    NA: "7",
    MICROBIOLOGY: {
        NEGATIVE: "1",
        POSITIVE: "2",
        INDETERMINATE: "3",
        AWAITING_RESULTS: "6",
        UNKNOWN: "9",
        NOT_TESTED: "7"
    },
    ATTACHMENT: {
        ORGAN: {
            LEFT_KIDNEY: "10",
            RIGHT_KIDNEY: "20",
            LIVER: "30",
            PANCREAS: "40",
            HEART: "50",
            LEFT_LUNG: "60",
            RIGHT_LUNG: "70",
            LUNGS: "80",
            OTHER: "99"
        },
        TYPE: {
            FILE: "10",
            IMAGE: "20",
            VIDEO: "30",
            DOCUMENT_IMAGE: "40",
            HLA_FILE: "50",
        }
    }
};

export default fieldValues;
