import referralFieldValueConstants from "../../../constants/referralFieldValueConstants";
import { ControlTypes } from "../../../enums/ControlTypes";
import { PageItemType } from "../../../enums/PageItemType";
import clinicallySignificantStore from "../../../stores/ClinicallySignificantStore";
import { PageItemModel } from "../../../stores/PageLayoutStore";
import referenceDataStore from "../../../stores/ReferenceDataStore";
import referralStore from "../../../stores/ReferralStore";

export class DisclosurePanelClinicallySignificantUtils {
    /**
     * Returns if the MaSH control is displayed or not (does not factor in clinically significant ony switch)
     * @param pageItem - list of item to check 
     * @returns if displayed
     */
    static ifDisplayed(pageItem: PageItemModel): boolean {
        var field = referenceDataStore.getFieldInfo(pageItem.field || undefined);
        if (field && (field.controlType === ControlTypes.Text || field.controlType === ControlTypes.TextArea)) {
            const fieldValue = referralStore.getField(pageItem.field!);
            return !fieldValue || !fieldValue.disabled;
        }

        return true;
    }

    /**
     * If only clinically significant shown and fields is NO or N/A or empty then set has hidden field
     * @param pageItem - list of item to check 
     * @returns converted list of items
     */
    static convertToHiddenSection(pageItem: PageItemModel): PageItemModel {
        var field = referenceDataStore.getFieldInfo(pageItem.field || undefined);

        if (field && field.controlType === ControlTypes.Switch) {
            // If switch is NO, N/A or empty then set has hidden
            const fieldValue = referralStore.getField(pageItem.field!);
            if (!!fieldValue && !clinicallySignificantStore.isFieldShown(pageItem.field!) &&
                (fieldValue.toValue === referralFieldValueConstants.NO || fieldValue.toValue === referralFieldValueConstants.NA || fieldValue.disabled)) {
                return {...pageItem, type: PageItemType.HiddenSection, field: pageItem.field! }
            }
        } else if (field && !clinicallySignificantStore.isFieldShown(pageItem.field!) &&
                   (field.controlType === ControlTypes.Text || field.controlType === ControlTypes.TextArea)) {
            // If text area is empty then set has hidden (but if disabled not shown so no need to hide)
            const fieldValue = referralStore.getField(pageItem.field!);
            if (fieldValue && !fieldValue.disabled && !fieldValue.toValue) {
                return {...pageItem, type: PageItemType.HiddenSection, field: pageItem.field! }
            }
        }

        // Create copy so don't alter original list (and convert children)
        pageItem = {...pageItem};
        if (pageItem.children) {
            pageItem.children = pageItem.children.map(DisclosurePanelClinicallySignificantUtils.convertToHiddenSection);
        }
        
        return pageItem;
    } 

    /**
     * Groups up hidden fields
     * @param pageItems       List of items  
     * @param hiddenSection   Current hidden fields passed as object as need references type
     * @returns Group of hidden fields
     */
    static hiddenSectionReducer(pageItems: PageItemModel[], hiddenSection: { current: PageItemModel | undefined } = { current: undefined }): PageItemModel[] {
        let newItems: PageItemModel[] = [];

        for(let pageItem of pageItems) {
            // If item has children determine if they are all hidden
            if (pageItem.children && pageItem.children.length > 0) {
                pageItem.children = DisclosurePanelClinicallySignificantUtils.hiddenSectionReducer(pageItem.children, hiddenSection);
                if (pageItem.children.every(c => c.type === PageItemType.HiddenSection)) {
                    // All items are hidden so make parent hidden
                    let fieldNames = pageItem.children.map(c => c.field).join(',');
                    pageItem = {...pageItem, type: PageItemType.HiddenSection, field: fieldNames };

                    // if a child was the current hidden item then make parent the hidden item
                    if (hiddenSection.current && pageItem.children!.includes(hiddenSection.current)) {
                        hiddenSection.current = pageItem
                    }
                }
            }

            // If there is a current hidden section, and this item is to be hidden, then don't add to array
            // extra check hiddenSection.current !== pageItem ensures if first item hidden (due to fact all children  hidden) then gets added to list (else missed out)
            if (hiddenSection.current && hiddenSection.current !== pageItem  && pageItem.type === PageItemType.HiddenSection) {
                hiddenSection.current!.field += ',' + pageItem.field;
            } else {
                newItems.push(pageItem);
            }

            // set or clear the current hidden section
            if (!hiddenSection.current && pageItem.type === PageItemType.HiddenSection) {
                hiddenSection.current = pageItem; 
            } else if (hiddenSection.current && pageItem.type !== PageItemType.HiddenSection && pageItem.type !== PageItemType.ReviewBlockGroup) {
                hiddenSection.current = undefined;
            }
        }

        return newItems;
    }

    /**
     * Returns all the fieldNames that are in hidden controls
     * @param pageItem 
     */
    static getAllHiddenFields(pageItem: PageItemModel): string[] {
        var fieldNames: Set<string> = new Set();

        if (pageItem.type === PageItemType.HiddenSection && pageItem.field && pageItem.field !== '') {
            pageItem.field.split(',').forEach(f => fieldNames.add(f));
        }

        if (pageItem.children && pageItem.children.length > 0) {
            pageItem.children
                    .map(DisclosurePanelClinicallySignificantUtils.getAllHiddenFields)
                    .forEach(f => { 
                        f.forEach(i => fieldNames.add(i))
                    });
        }

        return Array.from(fieldNames.keys());
    }
}