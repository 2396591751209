import clsx from 'clsx';
import { Breadcrumb } from '../Breadcrumb/Breadcrumb';

interface Props {
    pageTitle?: string;
    previousLinkRoute: string;
    previousLinkText: string;
    hideOnMobile?: boolean;
}

export const BreadcrumbNav = ({ pageTitle, previousLinkRoute, previousLinkText, hideOnMobile }: Props) => {
    return <nav className={clsx('breadcrumb-nav', hideOnMobile && 'desktopOnly')}>
        <span className="content">
            <Breadcrumb previousLinkRoute={previousLinkRoute} previousLinkText={previousLinkText} pageTitle={pageTitle} />
        </span>
    </nav>
}