import moment from "moment";
import { ComplexPartialDate } from "../types/ComplexPartialDate";

export class complexDateUtil {
    public static convertStringToDate(dateValue: string | moment.Moment | ComplexPartialDate | null | undefined, monthOnly?: boolean): moment.Moment | null {
        if (!dateValue) {
            return null;
        }

        if (moment.isMoment(dateValue)) {
            // Already a date/moment
            return dateValue;
        }

        if (typeof dateValue === "string") {
            if (dateValue.indexOf("T") !== -1 && dateValue.indexOf("-") !== -1) {
                // ISO string date
                var dateObject = moment(dateValue, "YYYY-MM-DDTHH:mm:ssZ");
                if (!dateObject.isValid()) {
                    throw new Error("Error parsing to date");
                }

                return dateObject;
            }
            if(dateValue.indexOf("/") !== -1){
                // DD/MM/YYYY string date
                const dateObject = new Date();
                const dateParts = dateValue.split('/');
                if(dateParts.length !== 3 || +dateParts[1] <= 0){
                    throw new Error("Error parsing to date");
                }
                dateObject.setDate(+dateParts[0]);
                dateObject.setMonth(+dateParts[1]-1);
                dateObject.setFullYear(+dateParts[2]);
                return moment(dateObject);
            } 
            else {
                // JSON string - Complex date
                const complexDate = JSON.parse(dateValue) as ComplexPartialDate;
                return this.parseToDate(complexDate, monthOnly);
            }
        } else {
            return this.parseToDate(dateValue, monthOnly);
        }
    }

    public static parseToDate(complexTypeObj: ComplexPartialDate, monthOnly?: boolean): moment.Moment | null {
        if (!complexTypeObj || (monthOnly === false && !complexTypeObj.day) || !complexTypeObj.month || !complexTypeObj.year) {
            // Invalid date so cannot parse;
            return null;
        }
        if (monthOnly !== false && !complexTypeObj.day) {
            complexTypeObj.day = "01";
        }
        var hour = !complexTypeObj.hour ? "00" : ("00" + complexTypeObj.hour).slice(-2);
        var min = !complexTypeObj.minute ? "00" : ("00" + complexTypeObj.minute).slice(-2);

        var dateString =
            ("0000" + complexTypeObj.year).slice(-4) +
            "-" +
            ("00" + complexTypeObj.month).slice(-2) +
            "-" +
            ("00" + complexTypeObj.day).slice(-2) +
            "T" +
            hour +
            ":" +
            min +
            "+00:00";

        var date = moment(dateString, "YYYY-MM-DDTHH:mm+00:00");

        if (date.isValid()) {
            return date;
        }

        return null;
    }

    public static parseFromDate(dateObj: moment.Moment | null | undefined): ComplexPartialDate | null {
        if (!dateObj) {
            return null;
        }

        var dateMoment = moment(dateObj);

        if (!dateMoment.isValid()) {
            throw new Error("Unable to parse date to complex type object - date object param is not a valid date");
        }

        return {
            day: ("00" + String(dateMoment.date())).slice(-2),
            month: ("00" + String(dateMoment.month() + 1)).slice(-2),
            year: ("0000" + String(dateMoment.year())).slice(-4),
            hour: ("00" + String(dateMoment.hour())).slice(-2),
            minute: ("00" + String(dateMoment.minute())).slice(-2)
        };
    }

    public static isDateTime(dateValue: string | moment.Moment | ComplexPartialDate | null | undefined): boolean {
        if (!dateValue) {
            return false;
        }

        if (moment.isMoment(dateValue)) {
            return false;
        }

        if (typeof dateValue === "string") {
            if (dateValue.indexOf("T") !== -1 && dateValue.indexOf("-") !== -1) {
                return true;
            } else {
                const complexDate = JSON.parse(dateValue) as ComplexPartialDate;
                if (complexDate.day !== null || complexDate.month !== null || complexDate.year !== null || complexDate.hour !== null || complexDate.minute !== null) {
                    return true;
                }
            }
        } else {
            return true; // ComplexPartialDate
        }

        return false;
    }

    public static formatDateStringForApi = (input: string): string => {
        const dateParts = input.split('/');
        if (dateParts.length !== 3) {
            return input;
        }
        return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    }

    public static formatDobStringFromDate = (dob: Date, age: string): string => `${moment(dob).format('DD/MM/YYYY')} (${age})`;
};
