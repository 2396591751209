export enum PageItemType {
   InputControl = 'InputControl',
   Group = 'Group',
   DisclosurePanel = 'DisclosurePanel',
   Row = 'Row',
   Label = 'Label',
   InfoIcon = 'InfoIcon',
   BlueBox = 'BlueBox',
   PatientInfoPanel = 'PatientInfoPanel',
   ReadOnlyControl = 'ReadOnlyControl',
   MandatoryBox = 'MandatoryBox',
   ComplexControl = 'ComplexControl',
   ComplexControlTable = 'ComplexControlTable',
   ComplexControlVertTable = 'ComplexControlVertTable',
   Button = 'Button',
   BodyMap = 'BodyMap',
   CalculatedReviewField = 'CalculatedReviewField',
   ReviewField = 'ReviewField',
   ReviewBlockField = 'ReviewBlockField',
   ReviewBlockGroup = 'ReviewBlockGroup',
   EmptyState = 'EmptyState',
   HiddenSection = 'HiddenSection',
   MicrobiologyTable = 'MicrobiologyTableStart',
   HaemodilutionTable = 'HaemodilutionTableStart',
   Drug = 'Drug',
   InfoBlock = 'InfoBlock',
   Attachments = 'Attachments'
}
