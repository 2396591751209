import userStore from '../../stores/UserStore';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as InfoOutlineIcon } from '../../assets/icons/infoOutline.svg';
import CookieBanner from '../CookieBanner/CookieBanner';
import { useEffect, useState } from 'react';
import Button from '../Inputs/Button/Button';
import { Link, Navigate } from 'react-router-dom';
import { observable } from 'mobx';
import Dialog from '../Dialog/Dialog';
import { observer } from 'mobx-react-lite';
import FullPageLoadingSpinner from '../Loader/FullPageLoadingSpinner';
import { StoreLoadingStateTypes } from '../../enums/StoreLoadingStateTypes';
import cookieStore from '../../stores/CookieStore';
import clsx from 'clsx';

export const Login = observer(() => {
   const [isCookieSet, setIsCookieSet] = useState(cookieStore.allowNoneEssentialCookies !== undefined);
   const [isLoading, setIsLoading] = useState(false);
   const [installingNewVersion, setInstallingNewVersion] = useState(false);

   const [userInfo] = useState(() => observable({ userStore }));
   const {
      userStore: { isLoggedIn, onLoginAttemptFail, state }
   } = userInfo;

   const [openDialog, setOpenDialog] = useState(onLoginAttemptFail);

   useEffect(() => {
      setOpenDialog(onLoginAttemptFail);
   }, [onLoginAttemptFail]);

   useEffect(() => {
      setIsCookieSet(cookieStore.allowNoneEssentialCookies !== undefined);
   }, [isCookieSet]);

   const setCookie = (accept: boolean) => {
      cookieStore.allowNoneEssentialCookies = accept;
      if (!accept) {
         cookieStore.clearNoneEssentialCookies();
      }
      setIsCookieSet(true);
   };

   const onHandleLogin = () => {
      userStore.login();
      setIsLoading(true);
   };

   useEffect(() => {
      // Check if the site is updating in which case disable the login button
      if (navigator && navigator.serviceWorker) {
         navigator.serviceWorker.ready.then(registration => {
            registration.addEventListener('updatefound', () => {
               setInstallingNewVersion(true);
               setTimeout(() => setInstallingNewVersion(false), 60000); // fail safe incase site does not load
            });
         });
      }
    }, []);

   const handleCloseDialog = () => setOpenDialog(false);

   if (isLoggedIn) return <Navigate to="/search" />;

   return (
      <>
         <div className="login">
            <Logo className="logo" />
            <div className={clsx("env-text env-colour", process.env.REACT_APP_ENV?.toLowerCase())}>{process.env.REACT_APP_ENV}</div>
            <div className="cta-container">
               <Button text={installingNewVersion ? "Updating..." : "Log In"}
                       isLoading={isLoading} 
                       isDisabled={installingNewVersion} 
                       onClick={onHandleLogin} 
                       className={clsx("btn login-btn env-bg-colour", process.env.REACT_APP_ENV?.toLowerCase())} />
               <div className="login-question">Problems logging in?</div>
               <div className="contact">
                  <span>Contact IT Service Desk Support on</span>
                  <span>0300 200 0173</span>
               </div>
               <hr />
               <div className='links'>
                  <Link to="/privacy-cookie">Cookies & Privacy</Link>
                  <Link to="/accessibility">Accessibility</Link>
               </div>
               <br />
               <div className='links'>
                  <Link to="/terms-conditions">Terms and Conditions</Link>
               </div>
            </div>
         </div>
         {!isCookieSet ? <CookieBanner onAcceptCookie={() => setCookie(true)} onRejectCookie={() => setCookie(false)} /> : undefined}
         {openDialog && <Dialog onClose={handleCloseDialog} hasOpaqueOverlay maxWidth="lg">
            <div className="dialog-content">
               <InfoOutlineIcon />
               <div>
                  <p className='username'>{userStore.userData.username}</p>
                  <h2>You don't seem to have access</h2>
                  <p>Sorry, only authorised users are able to access Transplant Path.</p>
               </div>
               <Button text="Try to log in again" className=" btn dialog-btn" onClick={handleCloseDialog} />
               <div>
                  <p>Think you should have authority?</p>
                  <div className="contact">
                     <span>Contact IT Service Desk Support on</span>
                     <span>0300 200 0173</span>
                  </div>
               </div>
            </div>
         </Dialog>}
         <FullPageLoadingSpinner forLogin loading={state === StoreLoadingStateTypes.Loading} />
      </>
   );
});
