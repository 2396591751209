import clsx from 'clsx';
import { ReactElement, useEffect, useRef } from 'react';
import { Key } from 'ts-key-enum';

interface DialogProperties {
   /** The function that closes the dialog. Leave undefined to block closure of the dialog. */
   onClose: () => void;
   /** All element wrapped inside and to be rendered in the dialog */
   children: ReactElement | ReactElement[];
   /** determines the maximum width of the dialog */
   maxWidth?: 'lg' | 'md' | 'sm';
   /** Allows for extra classes (styles) to be added */
   className?: string;
   /** determines whether the background is visible */
   hasOpaqueOverlay?: boolean;
   activeElement?: HTMLElement;
}

const Dialog = ({ onClose, children, maxWidth = 'sm', className, hasOpaqueOverlay = false }: DialogProperties): ReactElement => {

   const ref = useRef<HTMLDivElement>(null);

   const activeElement = document.activeElement;
   let activeIndex = -1;
   let focusableElementsInDialog: NodeListOf<Element>;

   useEffect(() => {
      const focusableElementSelector = 'a, button, textarea, input, select';
      const focusableElements = document.querySelectorAll(focusableElementSelector);
      focusableElements.forEach(elem => elem.ariaHidden = "true");
      if (ref.current) {
         // Select all focusable elements within ref
         focusableElementsInDialog = ref.current?.querySelectorAll(focusableElementSelector);
         focusableElementsInDialog.forEach(elem => elem.ariaHidden = "false");
      }
      return () => {
         const focusableElement = document.querySelectorAll(focusableElementSelector);
         focusableElement.forEach(elem => elem.ariaHidden = "false");
         if (activeElement) {
            (activeElement as HTMLElement).focus();
         }
      }
   }, [ref]);

   const handleEscape = (event: KeyboardEvent) => onClose();

   const handleTab = (event: KeyboardEvent) => {
      if (!focusableElementsInDialog) {
         return;
      }

      if (event.shiftKey) {
         activeIndex - 1 < 0 ? activeIndex = focusableElementsInDialog.length - 1 : activeIndex -= 1;
      } else {
         activeIndex + 1 === focusableElementsInDialog.length ? activeIndex = 0 : activeIndex += 1;
      }

      (focusableElementsInDialog[activeIndex] as HTMLElement).focus();
      event.preventDefault()
      return false;
   }

   const keyListenersMap = new Map([
      [Key.Escape, handleEscape],
      [Key.Tab, handleTab],
   ]);

   const handleKeydown = (event: KeyboardEvent) => {
      const listener = keyListenersMap.get(event.key as Key)
      if (listener) {
         listener(event);
      }
   }

   useEffect(() => {
      document.addEventListener('keydown', handleKeydown)
      return () => {
         document.removeEventListener('keydown', handleKeydown)
      }
   }, [])

   return (
      <>
         {hasOpaqueOverlay && <div className="opaque-overlay" />}
         <div className="dialog-overlay" ref={ref}>
            <div
               className={clsx(
                  'dialog-content',
                  { small: maxWidth === 'sm' },
                  { medium: maxWidth === 'md' },
                  { large: maxWidth === 'lg' },
                  className
               )}
            >
               {children}
            </div>
         </div>
      </>
   );
};

export default Dialog;
