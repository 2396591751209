import clsx from 'clsx';

interface Props {
    name: string;
    hasUpdates?: boolean;
}

export const DrugNegative = (props: Props) => {
    const drugViewWrapperClass = clsx({
        "drug-view-wrapper": true,
        "has-updates": props.hasUpdates === true,
     });

    return <div className={drugViewWrapperClass}>
        <div className="not-drug-view">
            <div className="not-drug-header">
                <div className="not-name">
                    <span className="text">{props.name}</span>
                </div>
                <div className="not-administered">{'No'}</div>
            </div>
        </div>
    </div>
}