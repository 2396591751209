import fieldValues from "../constants/referralFieldValueConstants";

export enum AttachmentFilter {
    Files,
    Media
}

export const AttachmentFilterToType: { [key: string]: (string | undefined | null) [] } = {
    [ AttachmentFilter.Files ]: [ undefined, null, fieldValues.ATTACHMENT.TYPE.FILE, fieldValues.ATTACHMENT.TYPE.DOCUMENT_IMAGE, fieldValues.ATTACHMENT.TYPE.HLA_FILE ],
    [ AttachmentFilter.Media ]: [ fieldValues.ATTACHMENT.TYPE.IMAGE, fieldValues.ATTACHMENT.TYPE.VIDEO ]
};
