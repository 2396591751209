export const parseDonorStatus = (donorStatus: string | number | null) => {
    if (donorStatus === null) {
        return null
    }
    switch (+donorStatus) {
        case (1): return 'Pending';
        case (2): return 'Assessing';
        case (3): return 'Assessing On Site';
        case (4): return 'Proceeding';
        case (5): return 'Offering';
        case (6): return 'Retrieval';
        case (7): return 'Non Proceeding';
        case (8): return 'Complete';
        default: return null;
    }
}