import React from 'react';
import { LabelType } from '../../enums/LabelType';
import { PageItemType } from '../../enums/PageItemType';
import { PageItemValidationType } from '../../enums/PageItemValidationType';
import { ValueFormatType } from '../../enums/ValueFormatType';
import { PageItemModel } from '../../stores/PageLayoutStore';
import referenceDataStore from '../../stores/ReferenceDataStore';
import referralStore from '../../stores/ReferralStore';
import { DisclosurePanel } from './DisclosurePanel/DisclosurePanel';
import { InfoIcon } from '../InfoIcon/InfoIcon';
import { ReviewControl } from './ReviewControl/ReviewControl';
import { ComplexControlTable } from '../ComplexControlTable/ComplexControlTable';
import { complexCollectionSortUtil } from '../../utils/complexCollectionSortUtil';
import { ControlTypes } from '../../enums/ControlTypes';
import { GroupControl } from './GroupControl/GroupControl';
import { RowControl } from './RowControl/RowControl';
import { HeaderControl } from './HeaderControl/HeaderControl';
import { ReviewBlockControl } from './ReviewBlockControl/reviewBlockControl';
import { PageLayoutToFieldInfoConverter } from '../../utils/pageLayoutToFieldInfoConverter';
import { EmptyState } from './EmptyState/EmptyState';
import { HiddenSection } from './HiddenSection/HiddenSection';
import clinicallySignificantStore from '../../stores/ClinicallySignificantStore';
import { DisclosurePanelClinicallySignificantUtils } from './DisclosurePanel/DisclosurePanelClinicallySignificantUtils';
import { MicrobiologyTable } from '../MicrobiologyTable/MicrobiologyTable';
import { ChangeField, ChangeFieldHelper } from '../../types/ChangeField';
import { HaemodilutionTable } from '../HaemodilutionTable/HaemodilutionTable';
import Drug from '../Drug/Drug';
import { fieldNames } from '../../constants/fieldNameConstants';
import OtherDrug from '../OtherDrug/OtherDrug';
import { DrugTypes } from '../../enums/drugType';
import { DrugNegative } from '../Drug/DrugNegative';
import { recursivelyGetPageFields } from '../../utils/recursivelyGetPageFields';
import { InfoBlockControl } from './InfoBlockControl/InfoBlockControl';
import { AttachmentsList } from '../Attachments/AttachmentsList';

interface Props {
   pageLayoutItem: PageItemModel;
   pageKey: string;
   key: number | undefined;
}

export const ControlFactory = (props: Props) => {
   const renderLabel = () => {
      if (isItemHiddenOrDisabled(PageItemValidationType.Show)) {
         return null;
      }

      switch (props.pageLayoutItem.labelType) {
         case LabelType.Header:
            return <HeaderControl pageLayoutItem={props.pageLayoutItem} />;
         case LabelType.SubHeading:
            return <h2>{props.pageLayoutItem.text}</h2>;
         case LabelType.Bold:
            return <h3 className={'label-bold'}>{props.pageLayoutItem.text}</h3>;
         case LabelType.Small:
            return (
               <div className="form-group-label">
                  <label className="control-label small">{props.pageLayoutItem.text}</label>
               </div>
            );
         default:
            return (
               <div className="form-group">
                  <label className="control-label">{props.pageLayoutItem.text}</label>
               </div>
            );
      }
   };

   const renderGroupControl = () => {
      if (isItemHiddenOrDisabled(PageItemValidationType.Show)) {
         return null; // Should only use this if the control is in a review screen, use normal disclosure panels for standard input screen
      }

      return (
         <GroupControl pageItem={props.pageLayoutItem}>
            {props.pageLayoutItem!.children!.map(c => (
               <ControlFactory key={c.orderIndex === null ? undefined : c.orderIndex} pageLayoutItem={c} pageKey={props.pageKey} />
            ))}
         </GroupControl>
      );
   };

   const renderReviewField = () => {
      if (isItemHiddenOrDisabled(PageItemValidationType.Show)) {
         return null;
      }

      let fieldName: string | undefined;
      if (props.pageLayoutItem.valueFormatType === ValueFormatType.CollectionItem) {
         fieldName = `${props.pageLayoutItem.field}|${props.pageLayoutItem.tag}`;
      } else {
         fieldName = props.pageLayoutItem.field!;
      }

      if (!fieldName) {
         return null;
      }

      return (
         <ReviewControl
            key={fieldName}
            fieldValue={referralStore.getField(fieldName)}
            field={referenceDataStore.getFieldInfo(fieldName)}
            pageItem={props.pageLayoutItem}
         />
      );
   };

   const renderReviewBlockField = () => {
      if (isItemHiddenOrDisabled(PageItemValidationType.Show)) {
         return null;
      }

      let fieldName: string | undefined;
      if (props.pageLayoutItem.valueFormatType === ValueFormatType.CollectionItem) {
         fieldName = `${props.pageLayoutItem.field}|${props.pageLayoutItem.tag}`;
      } else {
         fieldName = props.pageLayoutItem.field!;
      }

      if (!fieldName) {
         return null;
      }

      return (
         <ReviewBlockControl
            key={fieldName}
            fieldValue={referralStore.getField(fieldName)}
            field={referenceDataStore.getFieldInfo(fieldName)}
            pageItem={props.pageLayoutItem}
         />
      );
   };

   const renderReviewBlockGroup = (children?: JSX.Element[]) => {
      return (
         <div className="review-block-group">
            {children
               ? children
               : props.pageLayoutItem!.children!.map(c => (
                  <ControlFactory key={c.orderIndex === null ? undefined : c.orderIndex} pageLayoutItem={c} pageKey={props.pageKey} />
               ))}
         </div>
      );
   };

   const renderCalculatedReviewField = () => {
      if (isItemHiddenOrDisabled(PageItemValidationType.Show)) {
         return null;
      }

      let fieldName = props.pageLayoutItem.tag!;

      if (!fieldName) {
         return null;
      }

      return <ReviewControl key={fieldName} fieldValue={referralStore.getField(fieldName)} pageItem={props.pageLayoutItem} />;
   };

   const renderDisclosurePanelControl = () => {
      if (!props.pageLayoutItem!.children) {
         return <div>disclosure panel {props.pageLayoutItem.text || ''} has no child items</div>;
      }

      // If clinically significant only then create copy (so don't affect original list), and hide items
      var pageItemCopy = { ...props.pageLayoutItem };
      if (clinicallySignificantStore.clinicallySignificantOnly) {
         pageItemCopy.children = pageItemCopy.children!.filter(DisclosurePanelClinicallySignificantUtils.ifDisplayed).map(f => {
            if (f.children) {
               f.children = f.children!.filter(DisclosurePanelClinicallySignificantUtils.ifDisplayed);
            }
            return f;
         });
         pageItemCopy.children = pageItemCopy
            .children!.map(DisclosurePanelClinicallySignificantUtils.convertToHiddenSection)
            .filter(p => !!p)
            .map(p => p!);
         pageItemCopy.children = DisclosurePanelClinicallySignificantUtils.hiddenSectionReducer(pageItemCopy.children);

         clinicallySignificantStore.addToHiddenFields(DisclosurePanelClinicallySignificantUtils.getAllHiddenFields(pageItemCopy));
      }

      return (
         <DisclosurePanel>
            {pageItemCopy.children!.map(c => (
               <ControlFactory key={c.orderIndex === null ? undefined : c.orderIndex} pageLayoutItem={c} pageKey={props.pageKey} />
            ))}
         </DisclosurePanel>
      );
   };

   const renderRowControl = () => {
      return (
         <RowControl pageItem={props.pageLayoutItem}>
            {props.pageLayoutItem!.children!.map(c => (
               <ControlFactory key={c.orderIndex === null ? undefined : c.orderIndex} pageLayoutItem={c} pageKey={props.pageKey} />
            ))}
         </RowControl>
      );
   };

   const renderInfoIcon = () => {
      if (isItemHiddenOrDisabled(PageItemValidationType.Show)) {
         return null;
      }

      return (
         <div className="info-content icon">
            <InfoIcon bubbleContents={<p>{props.pageLayoutItem.text}</p>} />
         </div>
      );
   };

   const ComplexControlVertTable = () => {
      const fieldName = props.pageLayoutItem.field || '';
      const hadUpdates = referralStore.getFieldCollection(fieldName).some(f => f && f.updated); // calculated here as line below filters out deleted items
      const fieldDataList = getUnboundFieldCollection(fieldName, props.pageLayoutItem.sortBy, props.pageLayoutItem.sortOrderAsc);
      return <ComplexControlTable pageLayoutItem={props.pageLayoutItem} values={fieldDataList} hadUpdates={hadUpdates} />;
   };

   const renderEmptyState = () => {
      if (isItemHiddenOrDisabled(PageItemValidationType.Show)) {
         return null;
      }

      return <fieldset><EmptyState text={props.pageLayoutItem.text} /></fieldset>;
   };

   const renderInfoBlock = () => {
      if (isItemHiddenOrDisabled(PageItemValidationType.Show)) {
         return null;
      }      

      var fieldName = props.pageLayoutItem.field;

      return (
            <InfoBlockControl 
               pageLayoutItem={props.pageLayoutItem} 
               fieldValue={(fieldName ? referralStore.getField(fieldName) : undefined)} 
            />
      );
   };   

   const renderMicrobiologyTable = () => {
      let headers: string[] = [];
      let fields;
      let labelPageItems: PageItemModel[] = [];
      const children = props.pageLayoutItem.children;
      if (children) {
         const dataChildren = children.filter(child => child.type === PageItemType.ReviewField);
         const labelChildren = children.filter(child => child.type === PageItemType.Label);
         labelPageItems = labelChildren;
         fields = dataChildren.map(child => (child.field ? referralStore.getField(child.field) : undefined));
         headers = dataChildren.map(child => (child.text ? child.text : ''));
      }

      return (
         <MicrobiologyTable
            pageLayoutItem={props.pageLayoutItem}
            values={fields as ChangeField[]}
            headers={headers}
            labelPageItems={labelPageItems}
         />
      );
   };

   const renderCrystalloidInfused = () => {
      const children = props.pageLayoutItem.children;
      let priorToSampling: Record<string, string> = {};
      let volumeRetained: Record<string, string> = {};
      let title = '';
      let totalCalcText = '';
      let percentages: number[] = [];
      let totalCalc = 0;
      let hasUpdates = false;

      if (props.pageLayoutItem.tag?.toUpperCase() === 'CRYSTALLOID') {
         title = 'Crystalloid infused';
         totalCalcText = 'Total crystalloid retained (ml)';
         percentages = [0, 25, 50, 75];
      } else if (props.pageLayoutItem.tag?.toUpperCase() === 'BLOOD_COLLOID') {
         title = 'Blood/Colloid infused';
         totalCalcText = 'Total blood/colloid retained (ml)';
         percentages = [100, 50, 100];
      }

      if (children) {
         const dataChildren = children.filter(
            child => child.type === PageItemType.ReviewField && child.field?.toUpperCase().includes('PLASMADILUTIONS')
         );
         const totalCalcValue = referralStore.getField(
            children.find(child => child.field?.toUpperCase().includes('TOTAL'))?.field as string
         )?.toValue;
         totalCalc = totalCalcValue !== null && totalCalcValue !== undefined ? +totalCalcValue : 0;
         dataChildren.forEach((child, index) => {
            const field = referralStore.getField(child.field as string);
            if(field?.updated){
               hasUpdates = true;
            }
            const dataValue = field?.toValue;
            priorToSampling[child.text as string] = dataValue !== null && dataValue !== undefined ? dataValue : '';
            volumeRetained[child.text as string] =
               dataValue !== null && dataValue !== undefined ? Math.round(+dataValue * 0.01 * percentages[index]) + '' : '';
         });
      }

      return (
         <HaemodilutionTable
            priorToSampling={priorToSampling}
            percentages={percentages}
            volumeRetained={volumeRetained}
            title={title}
            totalCalcText={totalCalcText}
            totalCalc={totalCalc}
            hasUpdates={hasUpdates}
         />
      );
   };

   const renderDrugs = () => {
      let fieldName = props.pageLayoutItem.tag!;

      let data;
      if (!fieldName) {
         return <></>;
      }

      var fieldValue = referralStore.getField(fieldName);
      if (fieldValue?.toValue) {
         data = JSON.parse(fieldValue?.toValue!);
      }

      const labelType = props.pageLayoutItem.labelType as string;
      if (!data) {
         // Render blank boxes (not answered)
         switch (labelType) {
            case DrugTypes.Type4: 
               return <OtherDrug fieldName={props.pageLayoutItem.text || ''}  hasUpdates={fieldValue?.updated} />;
            case DrugTypes.Type3:
            case DrugTypes.Type5:
               return <Drug name={props.pageLayoutItem.text} 
                            drugType={labelType || ''} 
                            dateCommenced={'-'} 
                            maxRateInfusion={'-'} 
                            dateOfMaxRate={'-'} 
                            currentRateInfusion={'-'}
                            diluent={'-'}
                            dose={'-'}
                            drug={'-'}
                            dateOfMaxDosage={'-'}
                            hasUpdates={fieldValue?.updated} 
                            />;
            default:               
               return <Drug name={props.pageLayoutItem.text} drugType={labelType || ''} hasUpdates={fieldValue?.updated} />
         }
      }

      if (data.administered !== '2') {
         // Render 'No' line (not administered)
         return <DrugNegative name={props.pageLayoutItem.text || ''} hasUpdates={fieldValue?.updated} />;
      }

      switch (labelType) {
         case DrugTypes.Type1:
            return (
               <Drug
                  name={props.pageLayoutItem.text || ''}
                  administered={data.administered === '2'}
                  dateCommenced={ChangeFieldHelper.getDisplayValue(fieldNames.OTHER_USER_SPECIFIED_DRUG_COMMENCED_DATE, data.commencedDate) || ''}
                  dateOfMaxDosage={ChangeFieldHelper.getDisplayValue(fieldNames.OTHER_USER_SPECIFIED_DRUG_CEASED_DATE, data.maxDosageDate) || ''}
                  maxRateInfusion={data.maxDosageUgKgMinCalc || ''}
                  currentRateInfusion={data.currentDosageUgKgMinCalc || ''}
                  drugType={(props.pageLayoutItem.labelType as string) || ''}
                  quantity={data.quantity || ''}
                  dateOfMaxRate={ChangeFieldHelper.getDisplayValue(fieldNames.OTHER_USER_SPECIFIED_DRUG_CEASED_DATE, data.maxRateInfusionDate) || ''}
                  hasUpdates={fieldValue?.updated}
               />
            );
         case DrugTypes.Type2:
            return (
               <Drug
                  name={props.pageLayoutItem.text || ''}
                  administered={data.administered === '2'}
                  dateCommenced={ChangeFieldHelper.getDisplayValue(fieldNames.OTHER_USER_SPECIFIED_DRUG_COMMENCED_DATE, data.commencedDate) || ''}
                  dateOfMaxDosage={ChangeFieldHelper.getDisplayValue(fieldNames.OTHER_USER_SPECIFIED_DRUG_CEASED_DATE, data.maxDosageDate) || ''}
                  maxRateInfusion={data.maxRateInfusion || ''}
                  currentRateInfusion={data.currentRateInfusion || ''}
                  drugType={(props.pageLayoutItem.labelType as string) || ''}
                  quantity={data.quantity || data.infusionDoseMg || ''}
                  diluent={data.diluent || ''}
                  dateOfMaxRate={ChangeFieldHelper.getDisplayValue(fieldNames.OTHER_USER_SPECIFIED_DRUG_CEASED_DATE, data.maxRateInfusionDate) || ''}
                  currentDosageUgKgMinCalc={data.currentDosageUgKgMinCalc}
                  maxDosageUgKgMinCalc={data.maxDosageUgKgMinCalc}
                  detail={data.detail}
                  hasUpdates={fieldValue?.updated}                  
               />
            );
         case DrugTypes.Type3:
         case DrugTypes.Type5:
            return (
               <Drug
                  name={props.pageLayoutItem.text || ''}
                  administered={data.administered === '2'}
                  dateCommenced={data.infused !== '2' ? '-' : ChangeFieldHelper.getDisplayValue(fieldNames.OTHER_USER_SPECIFIED_DRUG_COMMENCED_DATE, data.commencedDate) || ''}
                  dateOfMaxDosage={ChangeFieldHelper.getDisplayValue(fieldNames.OTHER_USER_SPECIFIED_DRUG_CEASED_DATE, data.maxDosageDate) || ''}
                  maxRateInfusion={data.infused !== '2' ? '-' : data.maxRateInfusion || ''}
                  currentRateInfusion={data.infused !== '2' ? '-' : data.currentRateInfusion || ''}
                  drugType={(props.pageLayoutItem.labelType as string) || ''}
                  quantity={data.quantity || ''}
                  diluent={data.infused !== '2' ? '-' : data.diluent || ''}
                  infusionAdministered={data.infused ? data.infused === '2' : undefined}
                  currentBloodGlucoseLevel={data.currentBloodGlucose || ''}
                  loadingDoseAdministered={data.loadingDose ? data.loadingDose === '2' : undefined}
                  dose={data.loadingDose !== '2' ? '-' : data.loadingDoseUnits || data.loadingDoseUg || ''}
                  drug={data.infused !== '2' ? '-' : data.infusionDoseUnits || data.infusionDoseUg}
                  dateOfMaxRate={data.infused !== '2' ? '-' : ChangeFieldHelper.getDisplayValue(fieldNames.OTHER_USER_SPECIFIED_DRUG_CEASED_DATE, data.maxRateInfusionDate) || ''}
                  hasUpdates={fieldValue?.updated}                  
               />
            );

         case DrugTypes.Type4:
            return (
               <OtherDrug
                  name={
                     ChangeFieldHelper.getDisplayValue(fieldNames.OTHER_USER_SPECIFIED_DRUG_NAME, data.name) ||
                     ChangeFieldHelper.getDisplayValue(fieldNames.OTHER_PREDEFINED_DRUG_TYPE, data.type) ||
                     ''
                  }
                  frequency={ChangeFieldHelper.getDisplayValue(fieldNames.OTHER_USER_SPECIFIED_DRUG_FREQUENCY, data.frequency) || ''}
                  administered={data.administered === '2'}
                  route={ChangeFieldHelper.getDisplayValue(fieldNames.OTHER_USER_SPECIFIED_DRUG_ROUTE, data.route) || ''}
                  dateCommenced={ChangeFieldHelper.getDisplayValue(fieldNames.OTHER_USER_SPECIFIED_DRUG_COMMENCED_DATE, data.commencedDate) || ''}
                  dateCeased={ChangeFieldHelper.getDisplayValue(fieldNames.OTHER_USER_SPECIFIED_DRUG_CEASED_DATE, data.ceasedDate) || ''}
                  dose={data.dose || ''}
                  fieldName={props.pageLayoutItem.text || ''}
                  hasUpdates={fieldValue?.updated}   
               />
            );

         default:
            return <></>;
      }
   };

   const renderAttachments = () => {
      if (isItemHiddenOrDisabled(PageItemValidationType.Show)) {
         return <></>;
      }

      var attachments = Object.values(referralStore.fields)
                              .filter(f => f.fieldName.startsWith(fieldNames.RESOURCEMETADATAS) && f.toValue);

      return <AttachmentsList attachments={attachments} />;
   }

   /**
    * Retrieves all the field names for a unbound collection eg FieldName|{0000-000--0000-0-000000}
    * @param partFieldName - The static part of the field name before the pipe.
    * @returns {Array} - List of fieldNames including the guid.
    **/
   const getUnboundFieldCollection = (partFieldName: string, sortBy?: string, sortOrderAsc?: boolean | null) => {
      var unboundFields = referralStore.getFieldCollection(partFieldName);

      var fieldInfo = referenceDataStore.getFieldInfo(sortBy);
      if (fieldInfo && fieldInfo.subFieldName) {
         var sortOptions = {
            sortField: fieldInfo.subFieldName,
            isAscending: sortOrderAsc === undefined || sortOrderAsc === null ? true : sortOrderAsc,
            codeSetLookup: referenceDataStore.getCodeSetsByFieldName(sortBy!),
            isDateTime: [ControlTypes.Date, ControlTypes.DateTime, ControlTypes.PartDateTime].includes(fieldInfo.controlType)
         };

         unboundFields = complexCollectionSortUtil.sortUnboundedSlotsByField(unboundFields, sortOptions);
      }

      return unboundFields;
   };

   const isItemHiddenOrDisabled = (type: PageItemValidationType): boolean => {
      let isDisabledForConditions: any = [];

      var ifValidation = (props.pageLayoutItem.validation || []).filter(i => i.type === type);
      if (!ifValidation) {
         return false;
      }

      ifValidation.forEach(validation => {
         var condition = PageLayoutToFieldInfoConverter.ConvertPageItemValidation(validation, false);
         isDisabledForConditions.push(!condition(undefined));
      });

      return isDisabledForConditions.some((condition: boolean) => condition === true);
   };

   switch (props.pageLayoutItem.type) {
      case PageItemType.Label:
         return renderLabel();
      case PageItemType.Group:
         return renderGroupControl();
      case PageItemType.DisclosurePanel:
         return renderDisclosurePanelControl();
      case PageItemType.Row:
         return renderRowControl();
      case PageItemType.InfoIcon:
         return renderInfoIcon();
      case PageItemType.ReviewField:
         return renderReviewField();
      case PageItemType.ReviewBlockField:
         return renderReviewBlockField();
      case PageItemType.ReviewBlockGroup:
         return renderReviewBlockGroup();
      case PageItemType.CalculatedReviewField:
         return renderCalculatedReviewField();
      case PageItemType.EmptyState:
         return renderEmptyState();
      case PageItemType.ComplexControlVertTable:
         return ComplexControlVertTable();
      case PageItemType.HiddenSection:
         return <HiddenSection key={props.pageLayoutItem.orderIndex} pageItem={props.pageLayoutItem} />;
      case PageItemType.MicrobiologyTable:
         return renderMicrobiologyTable();
      case PageItemType.HaemodilutionTable:
         return renderCrystalloidInfused();
      case PageItemType.Drug:
         return renderDrugs();
      case PageItemType.InfoBlock:
         return renderInfoBlock();
      case PageItemType.Attachments:
         return renderAttachments();
      default:
         return <div />;
   }
};
