import clsx from 'clsx';
import { ReactNode } from 'react';

interface Props {
   text: string;
   handler: Function;
   icon: ReactNode;
   mobileOnly?: boolean;
   active?: boolean;
   tab?: boolean;
}

export const HeaderButton = ({ text, icon, mobileOnly, handler, active, tab }: Props) => {
   const className = clsx('header-button', active ? 'active' : 'inactive', mobileOnly && 'mobileOnly', tab && 'tab');
   const iconClassName = clsx('icon', active ? 'active' : 'inactive');
   const buttonClassName = clsx('env-bg-colour', process.env.REACT_APP_ENV?.toLowerCase());
   return (
      <div className={className}>
         <button onClick={e => handler(e)} className={buttonClassName}>
            <div className="button-content">
               <span className={iconClassName}>{icon}</span>
               <label>{text}</label>
            </div>
         </button>
      </div>
   );
};
